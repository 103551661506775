import { capitalise } from './stringUtils';

/**
 * Shuffles the elements of a given array using
 * the Durstenfeld shuffle algorithm
 *
 * @param {array} arr array to be shuffled
 *
 * @returns {array} arr
 */
export const shuffle = (arr: Array<any>): Array<any> => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }

  return arr;
};

export default { shuffle };

/**
 * Finds the id of the topic in the menu items array using provided topic name
 *
 * @param {array} menuItems list of menu items to search for topic
 * @param {string} subject the subject that contains the topic being searched for
 * @param {string} topic name of topic to be searched for
 *
 * @returns {string} the id of the provided topic
 */
export const findTopicId = (
  menuItems: Array<any>,
  subject: string,
  topic: string,
) => {
  if (subject === undefined || topic === undefined) return 0;
  return menuItems
    .find((subjectArr: any) => {
      return subjectArr.name === capitalise(subject);
    })
    .topics.find((topicArr: any) => topicArr.name === capitalise(topic)).id;
};
