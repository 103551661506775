import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box, Image, LinkProps, Flex } from '@chakra-ui/react';
import { darken } from '@chakra-ui/theme-tools';
// import { useLazyGetSubjectQuery, useLazyGetTopicQuery } from 'app/services/api';

import { lowerCase } from 'utils/stringUtils';

interface Props extends LinkProps {
  icon: string;
  to: string;
  label: string;
}

const ButtonCurriculum: React.FC<Props> = ({ icon, to, label, ...rest }) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      display={'flex'}
      flex={[
        '1 1 100%',
        null,
        '0 0 calc(50% - 1.125rem)',
        '0 0 calc(50% - 1.5rem)',
      ]}
      flexFlow={[null, null, 'row wrap']}
      justifyContent={[null, null, 'center']}
      alignItems={'center'}
      alignContent={'center'}
      maxW={['15.375rem', null, 'none']}
      margin={['0.5625rem auto', null, null, '0.75rem auto']}
      padding={'1.75rem 2.25rem'}
      fontSize={'1rem'}
      fontWeight={700}
      color={'#FFF'}
      border={'none'}
      bgColor={'button.dark-blue'}
      _hover={{
        bgColor: darken('button.dark-blue', 5),
      }}
      borderRadius={'10px'}
      letterSpacing={'0.0625rem'}
      textTransform={'uppercase'}
      textDecoration={'none'}
      _after={{
        content: [null, null, '""'],
        display: [null, null, 'block'],
        pb: [null, null, '45%'],
      }}
      {...rest}
    >
      <Flex
        flexFlow={[null, null, 'row wrap']}
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
      >
        <Image
          src={`https://cdn.valearnis.com/icons/${lowerCase(icon)}.svg`}
          flex={[null, null, '1 0 100%']}
          width='40px'
          height='40px'
          maxH={'40px'}
        />
        <Box
          as={'span'}
          display={'block'}
          mt={[null, null, '1rem']}
          ml={['2rem', null, 0]}
        >
          {label}
        </Box>
      </Flex>
    </Link>
  );
};

export default ButtonCurriculum;
