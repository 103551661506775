import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, Button, Flex } from '@chakra-ui/react';

import { addNotification } from 'app/notificationSlice';
import ButtonCurriculum from 'features/curriculum/ButtonCurriculum';
import { RootState } from 'app/store';
import CopyToolTip from 'features/instructions/CopyToolTip';

const ParentDashboard: React.FC = () => {
  // const auth = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const [referralCode, setReferralCode] = useState('');
  const [showReferralCode, setShowReferralCode] = useState(false);
  const token = useSelector((state: RootState) => state.auth.user.token);

  useEffect(() => {
    const paymentSuccess = new URLSearchParams(location.search).get(
      'payment_success',
    );

    if (paymentSuccess) {
      dispatch(addNotification('Payment successful, welcome to Valearnis!'));
    }
  }, []);

  const handleShowReferralCode = () => {
    fetch('http://localhost:8000/api/v2/account/referral-code/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setReferralCode(data.referral_code);
        setShowReferralCode(true);
      })
      .catch((error) => {
        console.error(error);
        dispatch(addNotification('Error getting referral code'));
      });
  };

  const handleMouseLeave = () => {
    setShowReferralCode(false);
  };

  return (
    <Flex
      flexFlow={'row wrap'}
      maxW={['15.375rem', null, '720px']}
      w={[null, null, '70vw']}
      m={'0 auto'}
      pt={['5vh', null, '10vh']}
      justifyContent='center'
    >
      <ButtonCurriculum
        icon={'manage-student'}
        to={'manage-students'}
        label={'Manage Students'}
        mr={2}
      />
      <ButtonCurriculum
        icon={'student-reports'}
        to={'update-billing'}
        label={'Billing'}
        mr={2}
      />
      <Box style={{ position: 'relative' }}>
        <Button
          onMouseEnter={handleShowReferralCode}
          onMouseLeave={handleMouseLeave}
          onClick={handleShowReferralCode}
          aria-label='Copy referral code'
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {showReferralCode ? (
            <>
              <span>{referralCode}</span>
              <Box ml={3}>
                <CopyToolTip
                  copy={referralCode}
                  copied={
                    'Code copied to clipboard!\n\nBe sure to share it with your friends before they sign up to enjoy exclusive benefits.'
                  }
                  content={
                    'Click to copy!\n\nUnlock two extra months of free service by sharing your referral code with new users!'
                  }
                  placement='bottom'
                  defaultIsOpen={false}
                />
              </Box>
            </>
          ) : (
            <span>Referral Code</span>
          )}
        </Button>
      </Box>
    </Flex>
  );
};

export default ParentDashboard;
