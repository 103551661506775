import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const Cross = createIcon({
  displayName: 'Cross',
  viewBox: '0 0 20.958 21.802',
  path: (
    <g id='Exit' transform='translate(-322.854 -29.899)'>
      <path
        id='Path_38'
        data-name='Path 38'
        d='M499.333-979.157l17.424-18.268'
        transform='translate(-174.712 1029.091)'
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeWidth='2.5'
      />
      <path
        id='Path_39'
        data-name='Path 39'
        d='M516.757-979.157l-17.424-18.268'
        transform='translate(-174.712 1029.091)'
        fill='none'
        stroke='#fff'
        strokeLinecap='round'
        strokeWidth='2.5'
      />
    </g>
  ),
});

export default Cross;
