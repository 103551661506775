import { Box, Flex, Image, Heading, Button } from '@chakra-ui/react';
import { Cross } from 'components/icons';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import ScrollBox from './ScrollBox';
import { useUpdateStudentSettingsMutation } from 'app/services/api';

const ThemeMenu: React.FC = () => {
  const [updateStudentSettings] = useUpdateStudentSettingsMutation();
  const { urls, tempCharacterChoice, tempSkinChoice, backgrounds, tempBg } =
    useAppSelector((state) => state.user);
  const {
    setTempSkin,
    setTempBg,
    setAvatar,
    toggleSettingTheme,
    toggleSelectingAvatar,
  } = useActions();

  const getCharacterImage = () => {
    if (tempSkinChoice.character !== tempCharacterChoice.id) {
      return tempCharacterChoice.skins[0].filename;
    } else {
      return tempSkinChoice.filename;
    }
  };

  return (
    <Flex
      position={'absolute'}
      flexDir={['column', 'row']}
      top={'0'}
      left={'0'}
      w={['100%', '100%']}
      h={['40%', '100vh']}
    >
      <Box
        position={'absolute'}
        top={'15px'}
        right={'20px'}
        onClick={() => toggleSettingTheme()}
        _hover={{ cursor: 'pointer' }}
      >
        <Cross />
      </Box>
      <Box
        h={['initial', '100%']}
        position={'relative'}
        m={['120px 30px 30px', 'initial']}
        borderRadius={['15px', 'initial']}
      >
        <Image
          src={urls.backgrounds + tempBg}
          h={'100%'}
          borderRadius={['15px', 'initial']}
        />
        <Image
          position={'absolute'}
          left={'50%'}
          top={'50%'}
          h={'60%'}
          transform='translate(-50%, -50%)'
          src={urls.characters + getCharacterImage()}
          m={'0 auto'}
        />
      </Box>

      <Flex
        flexDir={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        w={['100%', '47%']}
        h={['initial', '100vh']}
      >
        <Box mb={'30px'}>
          <Heading fontSize={'1.1rem'} mb={['10px', '30px']}>
            Select Skin
          </Heading>
          <Flex alignItems={'center'}>
            <ScrollBox childId={'charList'}>
              <Flex
                w={'300px'}
                overflowX={'hidden'}
                id={'charList'}
                userSelect={'none'}
              >
                {tempCharacterChoice.skins.map((skin) => {
                  return (
                    <Image
                      cursor={'pointer'}
                      borderRadius={'5px'}
                      w={'80px'}
                      key={skin.id}
                      src={urls.thumbnail + skin.thumbnail}
                      margin={'0 10px'}
                      onClick={() => setTempSkin(skin)}
                    />
                  );
                })}
              </Flex>
            </ScrollBox>
          </Flex>
        </Box>
        <Box mt={['10px', '30px']}>
          <Heading fontSize={'1.1rem'} mb={['10px', '30px']}>
            Select Background
          </Heading>
          <Flex alignItems={'center'}>
            <ScrollBox childId={'bgList'}>
              <Flex
                w={'300px'}
                overflowX={'hidden'}
                id={'bgList'}
                userSelect={'none'}
              >
                {backgrounds.map((background) => {
                  return (
                    <Image
                      cursor={'pointer'}
                      borderRadius={'5px'}
                      w={'80px'}
                      key={background.id}
                      src={urls.backgrounds + background.filename}
                      margin={'0 10px'}
                      onClick={() => setTempBg(background.filename)}
                    />
                  );
                })}
              </Flex>
            </ScrollBox>
          </Flex>
        </Box>
        <Button
          mt={['40px', '90px']}
          onClick={() => {
            setAvatar();
            const bg = backgrounds.find(
              (bg) => tempBg.split('/').pop() === bg.filename,
            );
            updateStudentSettings({
              app_user: {},
              character_skin: tempSkinChoice,
              background: bg,
              character: tempCharacterChoice,
            });
            toggleSelectingAvatar();
          }}
        >
          Confirm
        </Button>
      </Flex>
    </Flex>
  );
};

export default ThemeMenu;
