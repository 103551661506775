import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuizQuestions } from 'features/houseFaction/QuizQuestions';
import { api } from 'app/services/api';

interface QuizQuestionState {
  question: string;
  answers: {
    answer: string;
    faction: string;
  }[];
}

const houses = new Set(['Heartleaf', 'Moonspark', 'Flamespire']);

interface InitialState {
  questions: QuizQuestionState[];
  house: string | null;
  quizInProgress: boolean;
  currentQuestionIndex: number;
  currentQuestion: string;
  selectedAnswer: string;
  tally: Record<string, number>;
  recommendedFaction: string;
  selectedFaction: string;
  populationsData: Record<string, number>;
  sumPopulations: number;
  levelMediansData: Record<string, number>;
  sumLevels: number;
}

const initialState: InitialState = {
  questions: QuizQuestions,
  house: null,
  quizInProgress: false,
  currentQuestionIndex: 0,
  currentQuestion: '',
  selectedAnswer: '',
  tally: {
    Flamespire: 0,
    Moonspark: 0,
    Heartleaf: 0,
  },
  recommendedFaction: '',
  selectedFaction: '',
  populationsData: {},
  sumPopulations: 0,
  levelMediansData: {},
  sumLevels: 0,
};

const factionQuizSlice = createSlice({
  name: 'faction',
  initialState,
  reducers: {
    setQuizProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.quizInProgress = payload;
    },
    setSelectedAnswer: (state, { payload }: PayloadAction<string>) => {
      state.selectedAnswer = payload;
    },
    submitSelectedAnswer: (state) => {
      const currentQuestion = state.questions[state.currentQuestionIndex];
      const selectedAnswer = state.selectedAnswer;

      if (selectedAnswer) {
        // Find the faction associated with the selected answer
        const selectedFaction = currentQuestion.answers.find(
          (answer) => answer.answer === selectedAnswer,
        )?.faction;

        if (selectedFaction) {
          // Increment the tally for the selected faction
          state.tally[selectedFaction] += 1;
        }

        // Move to the next question
        state.currentQuestionIndex += 1;
        state.selectedAnswer = '';

        if (state.currentQuestionIndex >= state.questions.length) {
          // Handle end of the quiz
          // Check if the quiz is completed

          let maxTally = 0;
          let resultFaction = '';
          const tiedFactions: string[] = [];
          // Determine the highest tally and corresponding house faction
          Object.entries(state.tally).forEach(([faction, tally]) => {
            if (tally > maxTally) {
              maxTally = tally;
              resultFaction = faction;
            } else if (tally === maxTally) {
              tiedFactions.push(faction);
            }
          });

          // If there is a tie, randomly select a faction from the tied factions
          if (tiedFactions.length > 0) {
            const randomIndex = Math.floor(Math.random() * tiedFactions.length);
            resultFaction = tiedFactions[randomIndex];
          }
          // Log the house faction
          state.recommendedFaction = resultFaction;
          // state.selectedFaction = resultFaction;
        }
      }
    },
    setChosenFaction: (state, { payload }: PayloadAction<string>) => {
      state.selectedFaction = payload;
    },

    joinHouseFaction: (state, { payload }: PayloadAction<string>) => {
      state.house = payload;
      if (payload in state.populationsData) {
        state.populationsData[payload] += 1;
      } else {
        state.populationsData[payload] = 1;
      }
      state.sumPopulations += 1;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        api.endpoints.getUserProfile.matchFulfilled,
        (state, { payload }) => {
          if (payload.status === 200) {
            state.house = payload.payload.house;
          }
        },
      )
      .addMatcher(
        api.endpoints.getPopulations.matchFulfilled,
        (state, { payload }) => {
          const populations: Record<string, number> = {};
          let sum = 0;
          payload.payload[0].forEach(
            (houseData: { house: string; count: number }) => {
              if (houses.has(houseData.house)) {
                sum += houseData.count;
                populations[houseData.house] = houseData.count;
              }
            },
          );
          state.populationsData = populations;
          state.sumPopulations = sum;
        },
      )
      .addMatcher(
        api.endpoints.getLevelMedians.matchFulfilled,
        (state, { payload }) => {
          let sum = 0;
          state.levelMediansData = payload.payload;
          Object.keys(payload.payload).forEach((key) => {
            const value = payload.payload[key];
            sum += value;
          });
          state.sumLevels = sum;
        },
      ),
});

export const factionQuizActions = factionQuizSlice.actions;

export const {
  setSelectedAnswer,
  submitSelectedAnswer,
  setChosenFaction,
  setQuizProgress,
  joinHouseFaction,
} = factionQuizSlice.actions;

export default factionQuizSlice.reducer;
