import React from 'react';
import { Outlet } from 'react-router';
import { Box, SlideFade } from '@chakra-ui/react';

import Main from 'layout/Main';
import Header from 'components/nav/Header';
import { useBackground } from 'hooks/useBackground';

interface Props {
  showHeader?: boolean;
  gradientBackground?: boolean;
}

const DashboardLayout: React.FC<Props> = ({
  showHeader = true,
  gradientBackground = false,
  children,
}) => {
  const background = useBackground();
  const backgroundStyle =
    background && !gradientBackground
      ? { backgroundImage: `url(${background})` }
      : {};

  return (
    <>
      {showHeader && <Header />}
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'brand.800'}
        bgGradient={'linear(brand.700, brand.800)'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
        style={backgroundStyle}
      ></Box>
      <Main textAlign={'center'}>
        <Box w={'100%'} mb={'0'} mt={'2rem'}>
          <SlideFade
            in={true}
            offsetY={'500px'}
            style={{ transition: 'linear all 0.05s', height: '100vh' }}
          >
            {children}
            <Outlet />
          </SlideFade>
        </Box>
      </Main>
    </>
  );
};

export default DashboardLayout;
