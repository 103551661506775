import React from 'react';
import { Box } from '@chakra-ui/layout';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';

interface Props {
  word: string;
  lastWord: boolean;
  position: number;
}

const SelectableWord: React.FC<Props> = ({ word, lastWord, position }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();

  const wordNoHtml = word.replace(/(<([^>]+)>)/gi, '');

  const active = userAnswer?.includes(position);
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const answer = Object.assign([], userAnswer);
    const newEntry = position;
    if (answer !== undefined) {
      if (answer.includes(newEntry)) {
        answer.splice(answer.indexOf(newEntry), 1);
      } else {
        answer.push(newEntry);
      }
    }
    selectAnswer(answer);
  };
  const hasPuncFront = !isAlphaNumeric(wordNoHtml.charAt(0));
  const hasPuncEnd = !isAlphaNumeric(wordNoHtml.charAt(wordNoHtml.length - 1));
  const trimmedWord = trimWord(hasPuncFront, hasPuncEnd, wordNoHtml);
  const textColor = active ? 'red' : '';
  return (
    <Box as={'span'}>
      {hasPuncFront && <Box as={'span'}>{wordNoHtml.charAt(0)}</Box>}
      <Box
        role={'selectableWord'}
        as={'span'}
        color={textColor}
        onClick={handleClick}
      >
        {trimmedWord}
      </Box>
      {hasPuncEnd && (
        <Box as={'span'}>{wordNoHtml.charAt(wordNoHtml.length - 1)}</Box>
      )}
      {!lastWord && <Box as={'span'}> </Box>}
    </Box>
  );
};

export default SelectableWord;

const isAlphaNumeric = (char: string) => {
  return /[a-zA-Z0-9]/.test(char);
};

const trimWord = (
  hasPuncFront: boolean,
  hasPuncEnd: boolean,
  word: string,
): string => {
  if (hasPuncFront && hasPuncEnd) {
    word = word.slice(1, word.length - 1);
  } else if (hasPuncFront) {
    word = word.slice(1);
  } else if (hasPuncEnd) {
    word = word.slice(0, word.length - 1);
  }
  return word;
};
