import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Link,
  ListItem,
  MenuDivider,
  Slide,
  UnorderedList,
} from '@chakra-ui/react';
import { useNavLogic } from 'hooks/useNavLogic';
import { UserProfile } from 'features/userProfile/userSlice';
import { INavItem } from 'components/nav/Header';
// import {
//   useLazyGetStudentQuestItemsQuery,
//   useTagStudentQuestItemMutation,
// } from 'app/services/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { FaExclamation } from 'react-icons/fa';
import { getThumbnailFilename } from 'features/userProfile/characterSelection/utils';

interface Props {
  navItems: INavItem[];
  userDetails: UserProfile;
  isOpen: boolean;
  onToggle: () => void;
}

const MobileNav: React.FC<Props> = ({ navItems, isOpen, onToggle }) => {
  const { urls, skinChoice } = useAppSelector((state) => state.user);
  const { checkIsComplete, handleClick } = useNavLogic();

  return (
    <>
      <Button
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        aria-label={'Toggle Menu'}
        onClick={() => {
          onToggle();
          handleClick('profile_pic');
        }}
        zIndex={10}
      >
        {!checkIsComplete('profile_pic') && <FaExclamation color='gold' />}
        <Avatar
          size={'lg'}
          src={
            urls.thumbnail +
            getThumbnailFilename(skinChoice.filename.split('/').pop() as string)
          }
          border={'2px solid #fff'}
        />
      </Button>
      <Slide
        direction={'right'}
        transition={{ enter: { duration: 0.15 }, exit: { duration: 0.1 } }}
        in={isOpen}
        style={{
          zIndex: 9,
        }}
      >
        <Flex
          flexDirection={'column'}
          h={'100vh'}
          bgColor={'brand.800'}
          bgGradient={'linear(#22B7A3, #1B49B6)'}
        >
          <Box as={'nav'} mt={'8rem'} px={'2rem'} overflowY={'auto'}>
            <UnorderedList
              display={'flex'}
              flexDirection={'column'}
              m={'0'}
              p={'0'}
              styleType={'none'}
            >
              {navItems.map((navItem, index) => {
                const isExternalUrl =
                  navItem.href.toString().indexOf('http') !== -1;

                return (
                  <React.Fragment key={navItem.label}>
                    {index !== 0 && navItem.topDivider && (
                      <MenuDivider my={5} color={'#fff'} />
                    )}
                    <Flex onClick={() => handleClick(navItem.label)}>
                      <ListItem p={'0.4375rem 0'}>
                        <Link
                          as={isExternalUrl ? undefined : RouterLink}
                          to={isExternalUrl ? '' : navItem.href}
                          href={isExternalUrl ? navItem.href.toString() : ''}
                          isExternal={isExternalUrl}
                          fontSize={'1.125rem'}
                          color={'#fff'}
                        >
                          {navItem.label}
                        </Link>
                      </ListItem>
                      {!checkIsComplete(navItem.label) && (
                        <FaExclamation color='gold' />
                      )}
                    </Flex>
                  </React.Fragment>
                );
              })}
            </UnorderedList>
          </Box>
        </Flex>
      </Slide>
    </>
  );
};

export default MobileNav;
