import React from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

const Main: React.FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <Flex
      as={'main'}
      flexFlow={'row'}
      minH={'100vh'}
      pt={['5rem', null, '8rem']}
      color={'#FFF'}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default Main;
