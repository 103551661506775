import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { api, IAnswers } from 'app/services/api';

export interface SelectedAnswer {
  id: number;
  correct_answer: string;
}

export interface TransformedTheory {
  description: string;
  id: number;
  navbarNum: string;
  questionNum: string;
  questionType: string;
}

export interface TransformedQuestion {
  content: string;
  id: number;
  image: string | null;
  navbarNum: string;
  options: TransformedOption[];
  questionNum: string;
  questionType: string;
  instruction?: string;
}

export interface TransformedOption {
  id: number;
  content: string;
  image: string | null;
  order: number;
}

interface QuestionsState {
  lessonName: string;
  section: number;
  currentQuestion: number;
  currentQuestionId: number;
  currentAnswer: any;
  difficulty: string;
  theory: any;
  questions: (TransformedTheory | TransformedQuestion)[];
  score: number;
  error: string | null;
  lessonData: any;
  answers: IAnswers;
  lessonLoading: boolean;
  lessonReady: boolean;
  lessonId: string;
}

const initialState: QuestionsState = {
  lessonName: '',
  section: 1,
  currentQuestion: 0,
  currentQuestionId: 1,
  currentAnswer: '',
  difficulty: '',
  theory: {},
  questions: [],
  score: 12,
  error: null,
  lessonData: {},
  answers: { module: 0, question: [] },
  lessonLoading: true,
  lessonReady: false,
  lessonId: '',
};

const questions = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setDifficulty: (state, { payload }: PayloadAction<string>) => {
      state.difficulty = payload;
    },
    setSection: (state) => {
      state.section = state.questions[state.currentQuestion].id;
    },
    setCurrentQuestionId: (state) => {
      state.currentQuestionId = state.questions[state.currentQuestion].id;
    },
    setCurrentQuestionIdSpecific: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.currentQuestion = payload;
      state.currentQuestionId = state.questions[state.currentQuestion].id;
    },
    selectAnswer(state, { payload }: PayloadAction<any>) {
      const answer = state.answers.question.findIndex(
        ({ id }) => id === state.currentQuestionId,
      );
      if (answer === -1) {
        state.answers.question.push({
          id: state.currentQuestionId,
          answered: payload,
        });
      } else {
        state.answers.question[answer].answered = payload;
      }
    },
    resetCurrentQuestion: (state) => {
      state.currentQuestion = 0;
    },
    prevQuestion: (state) => {
      if (state.currentQuestion !== 0) {
        state.currentQuestion -= 1;
      }
    },
    nextQuestion: (state) => {
      const numOfQuestions = state.questions.length;

      if (state.currentQuestion !== numOfQuestions - 1) {
        state.currentQuestion += 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getAllQuestions.matchPending, (state) => {
        state.questions = [];
        state.answers = { module: 0, question: [] };
        state.lessonLoading = true;
      })
      .addMatcher(api.endpoints.getPreviewLessons.matchPending, (state) => {
        state.questions = [];
        state.answers = { module: 0, question: [] };
        state.lessonLoading = true;
      })
      .addMatcher(
        api.endpoints.getAllQuestions.matchFulfilled,
        (state, { payload }) => {
          state.lessonData = payload;
          state.lessonId = payload.lessonData.id;
          state.answers = payload.answers;
          state.lessonName = payload.lessonName;
          state.questions = payload.questions;
          state.lessonLoading = false;
          state.lessonReady = payload.isReady;
        },
      )
      .addMatcher(
        api.endpoints.getPreviewLessons.matchFulfilled,
        (state, { payload }) => {
          state.lessonData = payload;
          state.lessonId = payload.lessonData.id;
          state.answers = payload.answers;
          state.lessonName = payload.lessonName;
          state.questions = payload.questions;
          state.lessonLoading = false;
          state.lessonReady = payload.isReady;
        },
      )
      .addMatcher(api.endpoints.getPreviewLessons.matchFulfilled, (state) => {
        state.lessonReady = true;
      })
      .addMatcher(api.endpoints.tagLessonReady.matchFulfilled, (state) => {
        state.lessonReady = true;
      });
  },
});

export const questionActions = questions.actions;

export const {
  setSection,
  setCurrentQuestionId,
  setCurrentQuestionIdSpecific,
  setDifficulty,
  selectAnswer,
  resetCurrentQuestion,
  prevQuestion,
  nextQuestion,
} = questions.actions;

export default questions.reducer;
