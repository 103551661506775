import React from 'react';
import { Box, Heading, Text, Image, Progress, VStack } from '@chakra-ui/react';
import { VictoryPie } from 'victory';
import HouseFactions from './HouseFactions';
import { useAppSelector } from 'hooks/useAppSelector';

interface Faction {
  name: string;
  color: string;
  pie_color: string;
}

const factions: Faction[] = [
  {
    name: 'Moonspark',
    color: 'blue',
    pie_color: '#5CA7FF',
  }, //#5CA7FF
  {
    name: 'Heartleaf',
    color: 'green',
    pie_color: '#88EF99',
  }, //#88EF99
  {
    name: 'Flamespire',
    color: 'red',
    pie_color: '#FF6163',
  }, //#FF6163
];
const HouseFactionInfo: React.FC = () => {
  const {
    house,
    sumLevels,
    levelMediansData,
    populationsData,
    sumPopulations,
  } = useAppSelector((state) => {
    return state.faction;
  });

  const emblemImg = HouseFactions.find(
    (faction) => faction.name === house,
  )?.emblemImg;
  const description = HouseFactions.find(
    (faction) => faction.name === house,
  )?.description;
  const color = factions.find((faction) => faction.name === house)?.pie_color;

  return (
    <>
      <Box m={['7rem auto', '7rem auto', '0 auto', '0 aut0']} p={'1.5rem'}>
        <Box
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Image
            width={'220px'}
            height={'220px'}
            src={emblemImg}
            alt='card-emblem'
            border={'4px'}
            borderColor={'#022FA1'}
            borderRadius={'50%'}
          />

          <Heading
            mt={'0.8rem'}
            fontSize={['1.8rem']}
            fontWeight={'bolder'}
          >{`${house} Faction`}</Heading>
        </Box>

        <Box
          m={'1.5rem'}
          display={'flex'}
          flexDir={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'0 1rem'}
          bg={'rgba(36, 97, 115, 0.9)'}
          borderRadius={'4rem'}
        >
          <Box w={'25%'} bg={''} m={'auto'}>
            <Heading
              fontSize={'2rem'}
              textAlign={'center'}
              pt={4}
              width={'70%'}
              m={'auto'}
              mt={4}
              style={{ overflowWrap: 'break-word' }}
            >
              House Faction Population
            </Heading>
            <Box w={'80%'} h={'80%'} m={'auto'}>
              <VictoryPie
                data={factions.map((faction) => ({
                  x: faction.name,
                  y: (populationsData[faction.name] / sumPopulations) * 100,
                }))}
                colorScale={factions.map((faction) => faction.pie_color)}
                labels={() => ''}
                radius={140}
                innerRadius={70}
              />
            </Box>
          </Box>

          <Box
            w={'50%'}
            bg={''}
            borderRadius={'2rem'}
            p={4}
            marginLeft={'0'}
            marginRight={'0'}
          >
            <Heading fontSize={'2rem'} textAlign={'start'} p={4}>
              House Experience Levels
            </Heading>
            <VStack spacing={6} align='flex-start' p={4} mb={'1rem'}>
              {factions.map((faction) => (
                <Box key={faction.name} w='100%'>
                  <Box
                    textAlign={'left'}
                    fontSize={'1.6rem'}
                    fontWeight='bold'
                    m={1}
                  >
                    {faction.name}
                  </Box>
                  <Progress
                    colorScheme={faction.color}
                    value={(levelMediansData[faction.name] / sumLevels) * 100}
                    size='md'
                    borderRadius='md'
                    isAnimated
                  />
                </Box>
              ))}
            </VStack>
          </Box>
          <Box
            w={'30%'}
            bg={''}
            borderRadius={'2rem'}
            p={4}
            marginLeft={'1rem'}
          >
            <Text
              fontSize={'1.4rem'}
              fontWeight={'bold'}
              style={{ lineHeight: '1.2' }}
            >
              {description !== undefined && (
                <>
                  <span style={{ color: color }}>
                    {description.split('. ')[0]}.
                  </span>
                  <br />
                  <br />
                  {description.split('. ').slice(1).join('. ')}
                </>
              )}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HouseFactionInfo;
