import he from 'he';

export interface Theories {
  [key: number]: Theory;
}

export interface Theory {
  id: number;
  lesson_id: number;
  description: string;
  order: number;
  questions: Question[];
}

export interface Question {
  id: number;
  question_type: string;
  content: string;
  order: number;
  image: string | null;
  fib_order: boolean;
  instruction?: string;
  options: Option[];
}

export interface Option {
  id: number;
  content: string;
  order: number;
  image: string | null;
}

export const transformData = (theories: Theories, module: number) => {
  const questions: any = [];
  const answers: any = { module: module, question: [] };

  sortByOrder(theories).forEach((theory: Theory, index: number) => {
    questions.push(transformTheory(theory, index));
    const currentQuestions = sortByOrder(theory.questions);
    currentQuestions.forEach((question: Question, index: number) => {
      answers.question.push(
        configureAnswers(question.id, question.question_type, question.options),
      );
      questions.push(transformQuestion(question, index));
    });
  });
  return { questions, answers };
};

export const configureAnswers = (
  questionId: number,
  questionType: string,
  options: Option[],
) => {
  if (questionType === 'FIB') {
    const answers: any = [];
    options.forEach((option: Option) => {
      answers.push({ fill_index: option.order, response: '' });
    });
    return { id: questionId, answered: answers };
  }
  return { id: questionId, answered: [] };
};

export const transformTheory = (theory: Theory, index: number) => {
  const { id, description } = theory;
  return {
    id,
    description: he.decode(description),
    questionType: 'theory',
    questionNum: 'Theory ' + String(index + 1),
    navbarNum: `T${String(index + 1)}`,
  };
};

export const transformQuestion = (question: Question, index: number) => {
  const { id, question_type, options, image, instruction } = question;

  const content = he.decode(question.content);
  const transformedOptions = sortByOrder(options).map((option: Option) => {
    return transformOption(option);
  });
  return {
    id,
    questionType: question_type,
    options: transformedOptions,
    image,
    content,
    instruction,
    questionNum: 'Question ' + String(index + 1),
    navbarNum: String(index + 1),
  };
};

export const transformOption = (option: Option) => {
  const { id, order } = option;
  const content = option.content ? he.decode(option.content) : null;
  const image = option.image && null;
  return { id, content, image, order };
};

const sortByOrder = (items: any) => {
  items.sort((a: any, b: any) => {
    return a.order - b.order;
  });
  return items;
};
