import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Heading, Text } from '@chakra-ui/react';
import ButtonLink from 'components/buttons/ButtonLink';
import { useUrlParts } from 'hooks/useUrlParts';
import { introContent } from './TopicIntroData';

const LessonIntro: React.FC = () => {
  {
    /* TODO: dynamically handle background images */
  }
  const location = useLocation();
  const lessonsPath = location.pathname.replace('intro', 'lessons');
  const urlParts = useUrlParts();
  const clickedSubject = urlParts[urlParts.length - 2];
  const clickedModule = urlParts[urlParts.length - 3];

  return (
    <Fragment>
      {introContent.map((subject) => {
        if (subject.id === clickedSubject)
          return (
            <Box
              key={subject.id}
              textAlign={'left'}
              position={'relative'}
              maxW={['90%', null, '60%']}
              minH={['90%', null, '80vh']}
              m={['5rem auto', null, '5rem auto']}
            >
              <Heading
                as={'h1'}
                fontSize={['1.2rem', null, '1.6rem']}
                textAlign={'left'}
                letterSpacing={'1.5px'}
                textTransform={'none'}
                bgImage={`linear-gradient(
                  rgba(34, 34, 34, 0.45),
                  rgba(34, 34, 34, 0.45)
                ),url('https://cdn.valearnis.com/banners%2FBanner_Design_${clickedModule}.png')`}
                bgPos={'center'}
                bgSize={'cover'}
                bgRepeat={'no-repeat'}
                w={'100%'}
                h={'120px'}
                borderTopRadius={'20px'}
                pt={'55px'}
                pl={'23px'}
              >
                {subject.title}
              </Heading>
              <Box
                padding={['10px 30px', null, null, '30px']}
                bg={'#013a63'}
                borderBottomRadius={'20px'}
              >
                <Text
                  fontSize={['0.8rem', null, '1.1rem']}
                  fontWeight={'600'}
                  lineHeight={'1.5'}
                >
                  {subject.content}
                </Text>
                <ButtonLink
                  to={lessonsPath}
                  m={['1.5rem auto', null, null, '2.5rem auto']}
                  w={['50%', null, null, '20%']}
                  fontSize={['1rem', null, '1.1rem']}
                  bgColor={'#ff8c00'}
                >
                  Lessons
                </ButtonLink>
              </Box>
            </Box>
          );
      })}
    </Fragment>
  );
};

export default LessonIntro;
