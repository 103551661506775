import { Box, Image } from '@chakra-ui/react';
import { Character } from 'app/services/api';
import { useActions } from 'hooks/useActions';
// import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import { getDefaultThumbnail } from 'utils/objectUtils';
// import { getThumbnailFilename } from './utils';

interface Props {
  character: Character;
}

const CharacterChoice: React.FC<Props> = ({ character }) => {
  const { urls, tempSkinChoice } = useAppSelector((state) => state.user);
  const { setTempCharacterChoice } = useActions();
  // const { setTempAvatar } = useActions();
  return (
    <Box
      m={'10px 10px 0 0'}
      role={'selectableAvatar'}
      onClick={() => {
        setTempCharacterChoice(character);
      }}
      _hover={{ cursor: 'pointer' }}
      w={['calc(33% - 10px)', null, null, 'calc(25% - 10px)']}
      overflow={'hidden'}
      borderRadius={'5px'}
      borderColor={''}
      borderWidth={'0'}
      objectPosition={'0 10% 0 0'}
    >
      <Box w={'125%'} transform={'translateX(-12.5%)'}>
        <Image
          filter={!character.is_unlocked ? 'grayscale(1)' : ''}
          src={urls.thumbnail + getDefaultThumbnail(character, tempSkinChoice)}
        />
      </Box>
    </Box>
  );
};

export default CharacterChoice;
