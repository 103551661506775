import React from 'react';
import { Box, Heading, Text, Image, Progress, HStack } from '@chakra-ui/react';
import { VictoryPie } from 'victory';
import HouseFactions from './HouseFactions';
import { useAppSelector } from 'hooks/useAppSelector';

interface Faction {
  name: string;
  color: string;
  pie_color: string;
  short: string;
}

const factions: Faction[] = [
  {
    name: 'Moonspark',
    color: 'blue',
    pie_color: '#5CA7FF',
    short: 'Moon.',
  }, //#5CA7FF
  {
    name: 'Heartleaf',
    color: 'green',
    pie_color: '#88EF99',
    short: 'Heart.',
  }, //#88EF99
  {
    name: 'Flamespire',
    color: 'red',
    pie_color: '#FF6163',
    short: 'Flame.',
  }, //#FF6163
];
const HouseFactionInfoMobile: React.FC = () => {
  const {
    house,
    sumLevels,
    levelMediansData,
    populationsData,
    sumPopulations,
  } = useAppSelector((state) => {
    // console.log(state.faction);
    return state.faction;
  });

  const emblemImg = HouseFactions.find(
    (faction) => faction.name === house,
  )?.emblemImg;
  const description = HouseFactions.find(
    (faction) => faction.name === house,
  )?.description;
  const color = factions.find((faction) => faction.name === house)?.pie_color;

  return (
    <>
      <Box m={['7rem auto', '7rem auto', '0 auto', '0 aut0']} p={'1.5rem'}>
        <Box
          display={'flex'}
          flexDir={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Image
            width={'220px'}
            height={'220px'}
            src={emblemImg}
            alt='card-emblem'
            border={'4px'}
            borderColor={'#022FA1'}
            borderRadius={'50%'}
          />

          <Heading
            mt={'0.8rem'}
            fontSize={['1.8rem']}
            fontWeight={'bolder'}
          >{`${house} Faction`}</Heading>
        </Box>

        <Box
          m={'1.5rem'}
          display={'flex'}
          flexDir={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'0 1rem'}
          bg={'rgba(36, 97, 115, 0.9)'}
          borderRadius={'4rem'}
        >
          <Box w={'90%'} bg={''} p={4} marginLeft={['0', '0', '1rem', '1rem']}>
            <Text fontSize={'1rem'} fontWeight={'bold'}>
              {description !== undefined && (
                <>
                  <span style={{ color: color }}>
                    {description.split('. ')[0]}.
                  </span>
                  <br />
                  <br />
                  {description.split('. ').slice(1).join('. ')}
                </>
              )}
            </Text>
          </Box>
          <Box
            w={'90%'}
            bg={''}
            // borderRadius={'2rem'}
            ml={'auto'}
            mr={'auto'}
          >
            <Heading
              m={'auto'}
              w={'70%'}
              marginTop={'1rem'}
              fontSize={['1.5rem']}
              fontWeight={'bolder'}
            >
              House Faction Population
            </Heading>
            <Box w={'70%'} h={'70%'} m={'auto'}>
              <VictoryPie
                data={factions.map((faction) => ({
                  x: faction.name,
                  y: (populationsData[faction.name] / sumPopulations) * 100,
                }))}
                colorScale={factions.map((faction) => faction.pie_color)}
                labels={() => ''}
                radius={140}
                innerRadius={70}
              />
            </Box>
          </Box>

          <Box w={'80%'} bg={''} m={'auto'}>
            <Heading fontSize={['1.5rem']} textAlign={'center'} p={4}>
              House Exp.
            </Heading>
            <HStack spacing={1} align='center' p={4} mb={'1rem'} mt={100}>
              {factions.map((faction) => (
                <Box
                  key={faction.name}
                  position='relative'
                  w='100%'
                  h='32vh'
                  display={'flex'}
                  flexDir={'column'}
                >
                  <Box
                    position='absolute'
                    left='50%'
                    top='0'
                    transform='translateX(-50%)'
                  >
                    <Progress
                      colorScheme={faction.color}
                      value={(levelMediansData[faction.name] / sumLevels) * 100}
                      size='lg'
                      borderRadius='lg'
                      isAnimated
                      style={{
                        width: '30vh',
                        transform: 'rotate(-90deg) translateX(-20%)',
                        transformOrigin: 'center',
                      }}
                    />
                    <Box
                      textAlign={'center'}
                      fontSize={'1.2rem'}
                      fontWeight='bold'
                      mt={'25vh'}
                    >
                      {faction.short}
                    </Box>
                  </Box>
                </Box>
              ))}
            </HStack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HouseFactionInfoMobile;
