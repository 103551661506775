import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button, ButtonProps } from '@chakra-ui/react';

type Props = ButtonProps & LinkProps;

const ButtonLink: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Button as={Link} textAlign={'center'} {...rest}>
      {children}
    </Button>
  );
};

export default ButtonLink;
