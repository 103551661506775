import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const PaddedContainer: React.FC<BoxProps> = ({
  children,
  // minHeight = '75vh' as string,
  ...rest
}) => {
  return (
    <Box
      id={'paddedContainer'}
      position={'relative'}
      // w={'100%'}
      maxW={['100%', null, '80%']}
      minH={['100%', null, '80vh']}
      m={'0 auto'}
      bgColor={'brand.600'}
      bgGradient={'linear(#5A99F7, #4560C3, #243D9D)'}
      borderRadius={[null, null, '10px']}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default PaddedContainer;
