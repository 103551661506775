import React from 'react';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';

import { useAppSelector } from 'hooks/useAppSelector';
import { AuthUserType } from 'app/services/api';

import Homepage from 'layout/Homepage/Homepage';
import { useActions } from 'hooks/useActions';
import './App.css';

const App: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const { removeToken } = useActions();
  const location = useLocation();
  const userAuthorised = () => {
    if (!auth.isAuth) {
      removeToken();
    }
    if (
      auth.isAuth &&
      (location.pathname.indexOf('/login') !== -1 ||
        location.pathname.indexOf('/register') !== -1 ||
        location.pathname === '/')
    ) {
      if (auth.user.username) {
        LogRocket.identify(auth.user.username, {
          name: auth.user.name ? auth.user.name : 'UNKNOWN',
          email: auth.user.username,
        });
      }

      if (auth.user.type === AuthUserType.Guardian) {
        if (!auth.user.isVerified) {
          return <Navigate to='/account/verification' />;
        }

        // ---------------------------------------------------------------------
        // todo - re-enable this when we want to activate the subscription flow
        // ---------------------------------------------------------------------

        // if (!auth.user.subscriptionActive && !auth.user.unpaid) {
        //   const url = `/account/setup-students?students=${auth.user.studentsToCreate}`;
        //   return <Navigate to={url} />;
        // }

        // if (!auth.user.subscriptionActive) {
        //   return <Navigate to='/account/plan' />;
        // }

        return <Navigate to='/account/choose-user' />;
      } else {
        if (!auth.user.subscriptionActive) {
          return <Navigate to='/logout' />;
        }
        return <Navigate to='/dashboard' />;
      }
    } else {
      return <Homepage />;
    }
  };
  return <>{userAuthorised()}</>;
};

export default App;
