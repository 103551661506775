import React from 'react';

import { useGetPreviewLessonScoreMutation } from 'app/services/api';
import { useAppSelector } from 'hooks/useAppSelector';
import { Text, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useActions } from 'hooks/useActions';
import { useUrlParts } from 'hooks/useUrlParts';
import useStudentId from 'hooks/useStudentId';

const PreviewLessonSubmitQuizButton: React.FC = () => {
  const { answers } = useAppSelector((state) => state.questions);
  const [getPreviewLessonScore] = useGetPreviewLessonScoreMutation();
  const { addNotification } = useActions();
  const url = useUrlParts();
  const subject = url[url.length - 1];
  const studentId = useStudentId();
  const navigate = useNavigate();

  const allQuestionsAnswered = answers.question.every(
    (answer) => answer.answered.length > 0,
  );

  const attemptSubmit = async () => {
    if (allQuestionsAnswered) {
      const result = await getPreviewLessonScore({
        selectedAnswers: answers,
        studentId: studentId,
      }).unwrap();
      if (result.level) {
        navigate('/preview-lesson-results/' + subject);
      }
    } else {
      addNotification('Please answer all questions!');
    }
  };

  return (
    <Box
      color={'button.100'}
      left={['0', null, 'auto']}
      onClick={attemptSubmit}
      right={['0', null, '10px']}
      cursor={'pointer'}
      bg={allQuestionsAnswered ? '#5a99f7' : '#939191'}
      borderRadius={'5px'}
    >
      <Text
        color={'white'}
        fontWeight={'bold'}
        m={'10px 30px 10px'}
        userSelect={'none'}
      >
        Submit
      </Text>
    </Box>
  );
};

export default PreviewLessonSubmitQuizButton;
