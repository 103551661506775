import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Text } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';
import PaddedContainer from 'components/containers/PaddedContainer';
import ButtonLink from 'components/buttons/ButtonLink';
import LoadingCircle from 'components/icons/LoadingCircle';
import { useGetUserProfileQuery } from 'app/services/api';
import { useUrlParts } from 'hooks/useUrlParts';
import useStudentId from 'hooks/useStudentId';

const btnStyle = {
  width: ['100%', '80%', '60%', '40%'],
  height: '40px',
  borderRadius: '12px',
  backgroundColor: '#2871D1',
  lineHeight: '12px',
};

const ResultsPreviewPage: React.FC = () => {
  const studentId = useStudentId();
  const { isLoading } = useGetUserProfileQuery({ studentId });
  const { totalQuestions, answeredCorrectly } = useAppSelector(
    (state) => state.results,
  );
  const url = useUrlParts();
  const subject = url[url.length - 1];
  const { module, quizInProgress } = useAppSelector((state) => state.progress);
  const navigate = useNavigate();
  const highScore = answeredCorrectly >= totalQuestions / 2;

  useEffect(() => {
    if (quizInProgress === 0) {
      navigate('/curriculum');
    }
  }, []);

  return (
    <PaddedContainer padding={'28px 58px'} textAlign={'center'} height={'auto'}>
      <Flex flexDir={'column'} justify={'center'}>
        {isLoading ? (
          <LoadingCircle text={'Calculating Results'} />
        ) : (
          <>
            <Heading
              m={'25px 0 15px'}
              as={'h1'}
              fontSize={'22px'}
              fontWeight={'500'}
            >
              {highScore ? 'NICELY DONE!' : 'Better luck next time!'}
            </Heading>
            <Text
              fontSize={'16px'}
              letterSpacing={'1.28px'}
              m={'20px 0 0'}
            >{`Out of ${totalQuestions} questions, you got ${answeredCorrectly} right!`}</Text>
            <Text m={'37.8px 20px 20px'}>{'Quiz Complete!'}</Text>
            <Text mb={'45px'}>
              {highScore
                ? `You'll be a ${module} master in no time!`
                : 'Study some more and try again!'}
            </Text>
            <ButtonLink to={'/preview-lesson-answers/' + subject} {...btnStyle}>
              View Answers
            </ButtonLink>
          </>
        )}
      </Flex>
    </PaddedContainer>
  );
};

export default ResultsPreviewPage;
