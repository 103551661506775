import { Flex } from '@chakra-ui/react';
import React from 'react';

interface Props {
  correct: boolean;
}

const CorrectnessIndicator: React.FC<Props> = ({ correct }) => {
  return (
    <Flex
      w={['100%', null, null, '200px']}
      m={['15px 0 20px', null, null, '15px 0 20px 30px']}
      flexDir={'column'}
      wrap={'wrap'}
      alignSelf={'flex-start'}
    >
      <Flex
        h={'28px'}
        fontSize={'12px'}
        borderRadius={'10px'}
        mb={'5px'}
        // minW={'95px'}
        justify={'center'}
        alignItems={'center'}
        bgColor={correct ? '#69B57D' : '#5A696D'}
      >
        {correct ? 'Correct!' : 'Try Again :)'}
      </Flex>
    </Flex>
  );
};

export default CorrectnessIndicator;
