import React from 'react';
import { Box, Flex } from '@chakra-ui/layout';

import DOMPurify from 'dompurify';
import { Styles } from 'utils/customTypes';
import InputSection from './InputSection';

interface Props {
  question: string;
  options: any;
}

const outerBoxStyles: Styles = {
  role: 'blankSection',
  as: 'span',
  position: 'relative',
  display: 'inline-block',
};

const BlankQuestion: React.FC<Props> = ({ question, options }) => {
  const purifyElement = (html: string, index: number) => {
    return (
      <span
        key={`fib-${index}`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(html),
        }}
      />
    );
  };

  const questionParts = question.split(' ');
  const htmlTags = question.match(/(<([^>]+)>)/gi);
  let lastTag: string;
  let firstTag: string;
  if (htmlTags !== null) {
    lastTag = htmlTags[htmlTags.length - 1];
    firstTag = htmlTags[0];
  }

  const elements: any[] = [];
  let i = 0;
  questionParts.forEach((part, index) => {
    if (part.includes('_')) {
      elements.push(<InputSection key={i} optionOrder={options[i].order} />);
      i++;
    } else if (index === 0) {
      elements.push(purifyElement(`${part}&nbsp;${lastTag}`, index));
    } else if (index === questionParts.length - 1) {
      elements.push(purifyElement(`${firstTag}${part}`, index));
    } else {
      elements.push(purifyElement(`${firstTag}${part}&nbsp;${lastTag}`, index));
    }
  });
  return (
    <Box {...outerBoxStyles}>
      <Flex wrap={'wrap'} alignItems={'center'}>
        {elements}
      </Flex>
    </Box>
  );
};

export default BlankQuestion;
