import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const navSlice = createSlice({
  name: 'nav',
  initialState: {
    maxWidth: 200,
    isMoving: false,
    sliderPos: -35,
    slideBallPos: -10,
  },
  reducers: {
    setMaxWidth: (state, { payload }: PayloadAction<number>) => {
      state.maxWidth = payload;
    },
    setIsMoving: (state, { payload }: PayloadAction<boolean>) => {
      state.isMoving = payload;
    },
    setSliderPos: (state, { payload }: PayloadAction<number>) => {
      const pxPerMovement = (state.maxWidth - 15) / 209;
      //   const prevPos = state.sliderPos;

      state.sliderPos = payload * pxPerMovement;
    },
    snapToNumber: (state, { payload }: PayloadAction<number>) => {
      state.sliderPos = payload;
    },
    setBallPos: (state, { payload }: PayloadAction<number>) => {
      state.slideBallPos = payload;
    },
    snapBallToPos: (state) => {
      const relativePos = (state.maxWidth - 15) / 209;
      const newPos = state.sliderPos / relativePos;
      if (newPos === 0) {
        state.slideBallPos = -10;
      } else {
        if (state.sliderPos / relativePos > 210) {
          state.slideBallPos = 210;
        } else {
          state.slideBallPos = state.sliderPos / relativePos;
        }
      }
    },
  },
});

export const navActions = navSlice.actions;

export const {
  setMaxWidth,
  setIsMoving,
  setSliderPos,
  snapToNumber,
  setBallPos,
  snapBallToPos,
} = navSlice.actions;

export default navSlice.reducer;
