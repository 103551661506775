import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  attribute: string;
  value: string;
}

const AttributeBox: React.FC<Props> = ({ attribute, value }) => {
  return (
    <Box p={'5px 5.5px'} w={'50%'} role={'attribute'}>
      <Flex
        alignItems={'center'}
        justify={'center'}
        borderRadius={'10px'}
        bgColor={'brand.600'}
        height={'71px'}
        flexDir={'column'}
      >
        <Heading m={'0'} p={'0'} fontSize={'18px'}>
          {value}
        </Heading>
        <Text m={'0'} p={'0'} fontSize={'12px'}>
          {attribute}
        </Text>
      </Flex>
    </Box>
  );
};

export default AttributeBox;
