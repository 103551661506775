import React from 'react';
import { FormHelperText, Input, InputProps } from '@chakra-ui/react';
import { useField } from 'formik';

import FormLabel from 'components/forms/FormLabel';
import FormError from 'components/forms/FormError';

export interface Props extends InputProps {
  name: string;
  label?: string;
  helperText?: string;
}

const FormInput: React.FC<Props> = ({
  name,
  type = 'text',
  label,
  helperText,
  ...props
}) => {
  const [field] = useField(name);

  return (
    <>
      <FormLabel htmlFor={props.id || field.name}>{label}</FormLabel>
      <Input
        id={props.id}
        type={type}
        w={'100%'}
        p={'1rem'}
        border={'none'}
        borderRadius={'5px'}
        bgColor={'rgba(255, 255, 255, 0.65)'}
        color={'#243D9D'}
        letterSpacing={'.5px'}
        {...field}
      />
      {helperText && (
        <FormHelperText color={'#FFF'}>{helperText}</FormHelperText>
      )}
      <FormError name={field.name} />
    </>
  );
};

export default FormInput;
