/**
 * Limit chars of a string and add ellipses to the end
 *
 * @param text
 * @param maxLength
 *
 * @returns string
 */
export function ellipsize(text = '', maxLength = 60): string {
  // Normalize newlines
  const cleanText = text.replace(/\\r\\n/g, '\n');

  // Return if short enough already
  if (cleanText.length <= maxLength) {
    return cleanText;
  }

  const ellipsis = '...';

  // Return the [maxLength] chars as-is if they end in a non-word char
  const oneTooLarge = cleanText.substr(0, maxLength + 1);
  if (/\W$/.test(oneTooLarge)) {
    return oneTooLarge.substr(0, maxLength) + ellipsis;
  }

  // Walk backwards to the nearest non-word character
  let i = oneTooLarge.length;
  while (--i) {
    if (/\W/.test(oneTooLarge[i])) {
      return oneTooLarge.substr(0, i) + ellipsis;
    }
  }

  return oneTooLarge.substr(0, maxLength) + ellipsis;
}

export const capitalise = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const lowerCase = (word: string): string => {
  return word.toLowerCase();
};
