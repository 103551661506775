import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';

const CloseButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Image
      onClick={() => navigate(-1)}
      position={'absolute'}
      top={'3.5rem'}
      right={['3rem', null, '5rem', '7rem', '9rem']}
      w={'21px'}
      h={'22px'}
      src='/assets/icons/cross.svg'
      alt='close-page'
      _hover={{ cursor: 'pointer' }}
    />
  );
};

export default CloseButton;
