import React from 'react';
import { Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import {
  useUpdateStudentSettingsMutation,
  useUpdateGuardianSettingsMutation,
} from 'app/services/api';
import { Formik, Form } from 'formik';
import ButtonLink from 'components/buttons/ButtonLink';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import FormInput from 'components/forms/FormInput';
import FormSelect from 'components/forms/FormSelect';
import * as Yup from 'yup';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';
import FixPositionBackButton from 'components/buttons/FixPositionBackButton';

const SettingsPage: React.FC = () => {
  const { userName } = useAppSelector((state) => state.user);
  const { user } = useAppSelector((state) => state.auth);
  const isStudent = user.type === 'STUDENT';
  const { addNotification } = useActions();
  const navigate = useNavigate();
  const initialFormValues = {
    username: userName,
    email: userName,
    password: '',
    year: 7,
  };

  const UpdateStudentSchema = Yup.object().shape({
    password: Yup.string()
      .required('This field is required')
      .min(8, 'Must be at least 8 characters')
      .max(255, 'Must be at most 255 characters'),
    username: Yup.string()
      .min(2, 'Must be at least 2 characters')
      .max(150, 'Must be at most 150 characters')
      .required('This field is required'),
  });

  const UpdateGuardianSchema = Yup.object().shape({
    password: Yup.string()
      .required('This field is required')
      .min(8, 'Must be at least 8 characters')
      .max(255, 'Must be at most 255 characters'),
    email: Yup.string()
      .required('This field is required')
      .email('Please enter a valid email')
      .max(255, 'Must be at most 255 characters'),
  });
  const [updateStudentSettings] = useUpdateStudentSettingsMutation();
  const [updateGuardianSettings] = useUpdateGuardianSettingsMutation();
  const schoolYears = [7, 8, 9];
  return (
    <Flex
      pos={'relative'}
      align={'center'}
      justify={'center'}
      flexDir={'column'}
      wrap={'wrap'}
      w={'100vw'}
      minH={'100vh'}
      color={'#FFF'}
      bgColor={'brand.800'}
      bgGradient={'linear(brand.700, brand.800)'}
      bgPos={'bottom'}
      bgSize={'cover'}
    >
      <FixPositionBackButton />
      <Heading as={'h1'} fontSize={'22px'} m={'120px 0 30px'}>
        Settings
      </Heading>
      <Formik
        initialValues={initialFormValues}
        validationSchema={
          isStudent ? UpdateStudentSchema : UpdateGuardianSchema
        }
        onSubmit={async (values, actions) => {
          try {
            const updatedStudent = {
              app_user: {
                username: values.username,
              },
              year: Number(values.year),
              password: values.password,
            };

            const updatedGuardian = {
              app_user: {},
              email: values.email,
              password: values.password,
            };
            let result = null;
            if (isStudent === false) {
              result = await updateGuardianSettings(updatedGuardian).unwrap();
            }
            if (isStudent === true) {
              result = await updateStudentSettings(updatedStudent).unwrap();
            }
            // TODO: Check username is available first
            if (result.status !== 200) {
              throw new Error('Something went wrong');
            }
            // TODO: Handle errors properly
            // TODO: Improve type checking
            addNotification('account details updated');
            navigate('/account', {
              replace: true,
              state: {
                message: 'User updated',
              },
            });
          } catch (err: any) {
            addNotification('Password is incorrect/Username is taken');
            console.log('Something went wrong', err);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => (
          <Flex as={Form} flexDir={'column'} justify={'center'}>
            <FormFieldContainer flex={['1 1 100%', '0 1 47%']}>
              {isStudent ? (
                <FormInput
                  name={'username'}
                  label={'Username'}
                  value={props.values.username}
                />
              ) : (
                <FormInput
                  name={'email'}
                  label={'Email'}
                  value={props.values.email}
                />
              )}
            </FormFieldContainer>
            {isStudent ? (
              <FormFieldContainer flex={['1 1 100%', '0 1 47%']}>
                <FormSelect
                  name={'year'}
                  label={'Year Level'}
                  value={props.values.year}
                  bgColor={'rgba(255, 255, 255, 0.65)'}
                >
                  {schoolYears.map((year) => {
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </FormSelect>
              </FormFieldContainer>
            ) : null}
            <FormFieldContainer isRequired>
              <FormInput
                label='Enter Password'
                name='password'
                type='password'
                value={props.values.password}
                data-private
              />
            </FormFieldContainer>
            <Button
              type='submit'
              // m={'10px auto 50px'}
              disabled={props.isSubmitting}
              size='lg'
            >
              Save Changes
            </Button>
            <ButtonLink to='/settings/change-password' size='lg'>
              Change Password
            </ButtonLink>
          </Flex>
        )}
      </Formik>
    </Flex>
  );
};

export default SettingsPage;
