import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ChevronUp = createIcon({
  displayName: 'ChevronUp',
  viewBox: '0 0 12 8',
  path: (
    <path
      fill='none'
      stroke='#fff'
      strokeWidth='1.5'
      strokeLinecap='round'
      d='M.757 6.752l4.9-5.609 4.85 5.61'
    />
  ),
});

export default ChevronUp;
