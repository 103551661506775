import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Center } from '@chakra-ui/react';

import { useLogoutMutation } from 'app/services/api';
import { logoutSuccess } from 'features/auth/logoutSuccess';
import { useAppSelector } from 'hooks/useAppSelector';

const Logout: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout().unwrap();

      dispatch(logoutSuccess());
    } catch (e) {
      console.log(e);
    } finally {
      redirectToLogin('You have been logged out');
    }
  };

  const redirectToLogin = (message: string) => {
    navigate('/login', {
      replace: true,
      state: {
        message,
      },
    });
  };

  useEffect(() => {
    if (auth.isAuth) {
      handleLogout();
    } else {
      redirectToLogin('Please log in to continue');
    }
  }, []);

  return <Center>Loading...</Center>;
};

export default Logout;
