import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Icon from 'components/data/Icon';
import { Heading } from '@chakra-ui/react';
import { lowerCase } from 'utils/stringUtils';
import LoadingCircle from 'components/icons/LoadingCircle';
import { useGetEmbedCodesQuery } from 'app/services/api';

interface VideoState {
  embedCodes: string[];
  thumbnails: string[];
  titles: string[];
  descriptions: string[];
  error: string;
  currentIndex: number;
}

const Videos = () => {
  const { subject } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const subChoices = pathSegments[pathSegments.length - 2];

  const [state, setState] = useState<VideoState>({
    embedCodes: [],
    thumbnails: [],
    titles: [],
    descriptions: [],
    error: '',
    currentIndex: 0,
  });

  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const { data, error } = useGetEmbedCodesQuery({
    subject: subject ? subject : '',
    subChoices: subChoices ? subChoices : '',
  });

  useEffect(() => {
    if (data) {
      const { embedCodes, data: videoData } = data;
      setState((prevState) => ({
        ...prevState,
        embedCodes: embedCodes,
        titles: videoData?.titles ?? [],
        descriptions: videoData?.descriptions ?? [],
        thumbnails: videoData?.thumbnails ?? [],
      }));
    }

    if (error) {
      console.error(error);
      setState((prevState) => ({
        ...prevState,
        error: 'Failed to fetch embed codes',
      }));
    }
  }, [data, error]);

  const handlePrevious = () => {
    const newIndex = state.currentIndex - 8;
    const previousIndex = newIndex >= 0 ? newIndex : 0;
    setState((prevState) => ({ ...prevState, currentIndex: previousIndex }));
  };

  const handleNext = () => {
    const newIndex = state.currentIndex + 8;
    const nextIndex =
      newIndex < state.embedCodes.length ? newIndex : state.currentIndex;
    setState((prevState) => ({ ...prevState, currentIndex: nextIndex }));
  };

  const playVideo = (currentIndex: number | null) => {
    setClickedIndex(currentIndex);
  };

  const VideoItem = ({
    videoIndex,
    code,
  }: {
    videoIndex: any;
    code: string;
  }) => {
    const isSelected = clickedIndex === videoIndex;

    return (
      <div
        key={videoIndex}
        style={isSelected ? borderStyles : { padding: '20px' }}
      >
        {isSelected ? (
          <div>
            <iframe
              srcDoc={code}
              title={`Embedded Video ${videoIndex + 1}`}
              width='210%'
              height='410'
              scrolling='no'
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
            />
            <div>
              <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {state.titles?.[videoIndex] ?? ''}
              </p>
              <p style={{ textAlign: 'center', paddingBottom: '20px' }}>
                {state.descriptions?.[videoIndex] ?? ''}
              </p>
            </div>
          </div>
        ) : (
          <div style={borderStyles}>
            <img
              src={state.thumbnails?.[videoIndex] ?? ''}
              alt={`Thumbnail ${videoIndex + 1}`}
              style={thumbnailStyles}
              onClick={() => playVideo(videoIndex)}
            />
            <div>
              <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {state.titles?.[videoIndex] ?? ''}
              </p>
              <p style={{ textAlign: 'center', paddingBottom: '20px' }}>
                {state.descriptions?.[videoIndex] ?? ''}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const { embedCodes, currentIndex } = state;
  const displayedVideos = embedCodes.slice(currentIndex, currentIndex + 8);
  const hasVideos = embedCodes.length > 0;
  const showPreviousButton = currentIndex > 0;
  const showNextButton = currentIndex < embedCodes.length - 8;

  return (
    <>
      <div style={{ position: 'relative', left: '90px', top: '50px' }}>
        <Icon
          icon={`https://cdn.valearnis.com/icons/${lowerCase(subChoices)}.svg`}
        />
        <Heading
          as='h1'
          fontSize='1.5rem'
          letterSpacing='2px'
          style={{
            position: 'absolute',
            left: '80px',
            marginTop: '-190px',
            paddingBottom: '5px',
          }}
        >
          {subChoices} Videos
        </Heading>
      </div>
      <div style={containerStyles}>
        {state.error && <div>{state.error}</div>}
        {hasVideos ? (
          displayedVideos.map((code, index) => (
            <VideoItem
              key={currentIndex + index}
              videoIndex={currentIndex + index}
              code={code}
            />
          ))
        ) : (
          <div
            style={{
              position: 'relative',
              margin: '0 auto',
              width: 'fit-content',
            }}
          >
            <LoadingCircle text='Loading your videos' />
          </div>
        )}
        {showPreviousButton && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bottom: '10px',
            }}
          >
            <FaChevronLeft
              onClick={handlePrevious}
              style={{
                cursor: 'pointer',
                color: '#fff',
                fontSize: '30px',
                marginRight: '50px',
              }}
            />
          </div>
        )}
        {showNextButton && (
          <div
            style={{
              position: 'absolute',
              right: '50%',
              transform: 'translate(50%, -50%)',
              bottom: '10px',
            }}
          >
            <FaChevronRight
              onClick={handleNext}
              style={{
                cursor: 'pointer',
                color: '#fff',
                fontSize: '30px',
                marginLeft: '50px',
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const containerStyles = {
  position: 'relative' as const,
  padding: '20px',
  backdropFilter: 'blur(5px)',
  borderRadius: '20px',
  border: '1px solid #fff',
  marginLeft: '40px',
  marginRight: '40px',
  display: 'flex',
  flexWrap: 'wrap' as const,
  paddingBottom: '80px',
  paddingTop: '30px',
  marginBottom: '120px',
  marginTop: '-80px',
};

const borderStyles = {
  border: '1px solid #243D9D',
  padding: '10px',
  borderRadius: '20px',
  backgroundColor: 'rgba(36, 61, 157, 0.3)',
};

const thumbnailStyles = {
  width: '320px',
  height: '282',
  cursor: 'pointer',
  borderRadius: '20px',
  border: '1px solid #fff',
};

export default Videos;
