import React, { useState } from 'react';
import {
  Box,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa';
// import { string } from 'yup';

interface Props {
  copy: string;
  placement: 'bottom';
  content: string;
  copied: string;
  defaultIsOpen: boolean;
}

const CopyToolTip: React.FC<Props> = ({
  defaultIsOpen,
  copy,
  content,
  copied,
}) => {
  //   const [isCopied, setIsCopied] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copy).then(() => {
      //   setIsCopied(true);
      setShowContent(false);
    });
  };

  const handleMouseEnter = () => {
    setShowContent(true);
  };

  const handleMouseLeave = () => {
    setShowContent(false);
  };

  //   const copyTooltip = isCopied ? 'Copied to clipboard!' : 'Copy to clipboard';

  return (
    <Popover defaultIsOpen={defaultIsOpen} placement='bottom' trigger='hover'>
      <PopoverTrigger>
        <Box
          position='relative'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <FaCopy size={22} fontSize={'6xl'} onClick={copyToClipboard} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        bg='white'
        marginBlockEnd={3}
        marginBlockStart={4}
        placement='bottom'
      >
        <PopoverArrow
          fontSize='12px'
          position='absolute'
          top='-10px'
          left='calc(50% - 6px)'
          borderColor='white'
          color='white'
          bg='white'
          borderBottomColor='white'
          borderWidth='5px'
          borderStyle='solid'
        />
        <PopoverCloseButton bg='#1C50B4' />
        <PopoverBody>
          <Box>
            {showContent && (
              <Text
                mb={0}
                textAlign={'left'}
                m={['6', '1', '1', '6']}
                color='#1C50B4'
                fontWeight='normal'
                fontSize={15}
                whiteSpace='pre-line'
                wordWrap='break-word'
              >
                {content}
              </Text>
            )}
            {!showContent && (
              <Text
                mb={0}
                textAlign={'left'}
                m={['6', '1', '1', '6']}
                color='#1C50B4'
                fontWeight='normal'
                fontSize={15}
                whiteSpace='pre-line'
                wordWrap='break-word'
              >
                {copied}
              </Text>
            )}
            <Text textAlign={'center'}>{CopyToolTip}</Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CopyToolTip;
