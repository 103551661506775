import React from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';

import LessonInfoBox from 'features/lessons/LessonInfoBox';
import ProgressBar from 'features/lessons/ProgressBar';

import LessonButtons from './LessonButtons';
import { useAppSelector } from 'hooks/useAppSelector';
import { useKatex } from 'hooks/useKatex';
import { AuthUserType } from 'app/services/api';

// same as in progressSlice - read up about best ways to reuse code in typescript
// interface Quiz {
//   // score out of 100 or boolean for completion?
//   [key: number]: { regular: number; challenging: number };
// }

interface Props {
  lesson: any;
  mobile: boolean;
}

const LessonListItem: React.FC<Props> = ({ lesson, mobile }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const ele = React.createRef<HTMLDivElement>();

  useKatex(ele);

  const onClickHandler = () => {
    if (mobile) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <Box role={'lessonInfo'} w='100%'>
      <Flex
        role={'showLessonInfo'}
        pos={'relative'}
        w={['80%', '350px', null, '640px']}
        m={'1rem auto'}
        pt={'0.5rem'}
        pb={'0.5rem'}
        bgColor={'brand.900'}
        borderRadius={'0.6rem'}
        onClick={onClickHandler}
        textAlign={'left'}
        flexDir={'row'}
        wrap={'wrap'}
        align={'center'}
      >
        {user.type === AuthUserType.Employee && (
          <Box
            pos={'absolute'}
            bottom={'4px'}
            left={'2rem'}
            color={lesson.is_ready === true ? '#66ff00' : 'red'}
          >
            Ready
          </Box>
        )}
        <Flex
          p={'1rem 2rem'}
          flexDir={'column'}
          w={['100%', null, null, '50%']}
          wrap={'wrap'}
        >
          <Flex flexDir={'column'}>
            <Heading ref={ele} as={'h3'} fontSize={'1.1rem'}>
              {lesson.name}
            </Heading>
            <Text m={'0.4rem 0'}>Overall score:</Text>
          </Flex>
          <Box w={'100%'}>
            <ProgressBar />
          </Box>
        </Flex>
        {!mobile && (
          <LessonInfoBox
            mobile={mobile}
            quizName={lesson.name}
            quizNumber={lesson.id}
          />
        )}
        {!mobile && (
          <LessonButtons quizName={lesson.name} quizNumber={lesson.id} />
        )}
      </Flex>
      {isOpen ? (
        <LessonInfoBox
          mobile={mobile}
          quizName={lesson.name}
          quizNumber={lesson.id}
        />
      ) : (
        ''
      )}
    </Box>
  );
};

export default LessonListItem;
