import { Character, Skin } from 'app/services/api';

interface IIndexObject {
  [key: string]: any;
}

/**
 * Flatten an object to a depth of one level
 *
 * @param object
 * @returns object
 */
export function flattenObject(object: IIndexObject): IIndexObject {
  const toReturn: IIndexObject = {};

  for (const i in object) {
    if (!Object.prototype.hasOwnProperty.call(object, i)) continue;

    if (typeof object[i] === 'object' && object[i] !== null) {
      const flatObject = flattenObject(object[i]);

      for (const j in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, j)) continue;

        toReturn[i + '.' + j] = flatObject[j];
      }
    } else {
      toReturn[i] = object[i];
    }
  }

  return toReturn;
}

export const convertKeysCamelCase = (obj: { [key: string]: any }) => {
  const newObj: { [key: string]: any } = {};
  for (const key in obj) {
    newObj[camelCaseKey(key)] = obj[key];
  }
};

const camelCaseKey = (key: string) => {
  return key.replace(/_([a-z])/gi, (_, p1) => {
    return p1.toUpperCase();
  });
};

export const getDefaultThumbnail = (
  character: Character,
  selectedSkin: Skin,
) => {
  let defaultSkin;
  character.skins.forEach((skin) => {
    if (skin.filename === selectedSkin.filename) {
      defaultSkin = skin.thumbnail;
    } else if (skin.filename.includes('01')) {
      defaultSkin = skin.thumbnail;
    }
  });
  return defaultSkin;
};
