import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  notifications: string[];
}

const initialState: InitialState = {
  notifications: [],
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, { payload }: PayloadAction<string>) => {
      state.notifications.push(payload);
    },
    removeNotification: (state) => {
      state.notifications.pop();
    },
  },
});

export const notificationActions = notificationSlice.actions;

export const { addNotification, removeNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
