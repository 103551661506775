import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';
import { useGetCharactersQuery } from 'app/services/api';
import DashboardLayout from 'layout/DashboardLayout';
import AttributesSection from 'features/userProfile/AttributesSection';
import AvatarMenu from 'features/userProfile/characterSelection/AvatarMenu';
import ClassInfoBox from 'features/userProfile/ClassInfoBox';
import ProfilePicture from 'features/userProfile/ProfilePicture';
import { isDesktop } from 'utils/customHooks/windowDimensionsUtil';
import Separator from './Separator';
import useStudentId from 'hooks/useStudentId';

const ProfileSummary: React.FC = () => {
  const { userName, selectingAvatar } = useAppSelector((state) => state.user);
  const studentId = useStudentId();
  const { isLoading } = useGetCharactersQuery({ studentId });
  const isOnDesktop = isDesktop();
  return (
    <DashboardLayout>
      {selectingAvatar ? (
        <AvatarMenu isLoading={isLoading} />
      ) : (
        <Flex
          align={'center'}
          justify={'center'}
          pos={'relative'}
          flexDir={'column'}
          mt={['30px', null, '0']}
        >
          <Heading fontSize={'22px'} mb={'25px'}>
            {userName}
          </Heading>
          <Flex
            flexDir={['column', null, null, 'row']}
            justify={'center'}
            mb={'20px'}
          >
            <Box width={['90%', null, null, '400px']} m={'0 auto'}>
              <ProfilePicture />
            </Box>
            {isOnDesktop && <Separator />}
            <Flex
              w={['90%', null, null, '400px']}
              justify={['center', null, null, 'flex-start']}
              align={'center'}
              m={'0 auto'}
              flexDir={'column'}
            >
              <ClassInfoBox />
              {!isOnDesktop && <Separator />}
              <AttributesSection />
            </Flex>
          </Flex>
        </Flex>
      )}
    </DashboardLayout>
  );
};

export default ProfileSummary;
