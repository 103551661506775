import React from 'react';
import { Box, Button, Flex, Text, Link } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import {
  api,
  AuthUserType,
  ILoginRequest,
  ILoginResponse,
  useLoginMutation,
} from 'app/services/api';
import FormInput from 'components/forms/FormInput';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import ButtonLink from 'components/buttons/ButtonLink';
import { useActions } from 'hooks/useActions';
import { useDispatch } from 'react-redux';
import useStudentId from 'hooks/useStudentId';

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { loginRequest, addNotification, loginSuccess } = useActions();
  const [login, { isLoading }] = useLoginMutation();
  const studentId = useStudentId();

  return (
    <>
      <Helmet>
        <title>Login - Valearnis</title>
      </Helmet>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values: ILoginRequest, actions) => {
          try {
            loginRequest(values.username.toLowerCase());
            const result: ILoginResponse = await login(values).unwrap();
            if (!result || result.status !== 200) {
              throw new Error(result.message);
            } else {
              sessionStorage.setItem('user_type', result.user_type);
              loginSuccess(result);
              if (
                result.user_type === AuthUserType.Student &&
                result.subscription_active === false
              ) {
                addNotification(
                  'Account inactive, please ask your parent/guardian to resubscribe',
                );
              } else if (result.user_type !== AuthUserType.Guardian) {
                dispatch(api.util.prefetch('getMenuItems', { studentId }, {}));
                dispatch(
                  api.util.prefetch('getUserProfile', { studentId }, {}),
                );
                dispatch(api.util.prefetch('getPopulations', undefined, {}));
                dispatch(api.util.prefetch('getLevelMedians', undefined, {}));
              }
            }
          } catch (err: any) {
            if (err.data.message === 'User Login Failed') {
              addNotification('Username or password is incorrect');
            } else {
              addNotification(err.data.message);
            }
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => (
          <Flex
            as={Form}
            alignItems={'flex-start'}
            justifyContent={'center'}
            flexFlow={'row wrap'}
            w={'100%'}
            maxW={'20.25rem'}
            m={'0 auto'}
            p={'0 2rem'}
            textAlign={'center'}
          >
            <FormFieldContainer>
              <FormInput
                label='Username / Email'
                id='username'
                name='username'
                value={props.values.username.toLowerCase()}
              />
            </FormFieldContainer>
            <FormFieldContainer>
              <FormInput
                label='Password'
                id='password'
                type='password'
                name='password'
                value={props.values.password}
                data-private
              />
            </FormFieldContainer>
            <Box>
              <Text
                m={'5px auto 5px'}
                fontSize={'1rem'}
                fontWeight={'600'}
                letterSpacing={'.5px'}
                justifyContent={'left'}
              >
                <Link href='/change-password'>Forgot your password?</Link>
              </Text>
            </Box>
            <Box>
              <Button
                id='login-button'
                type='submit'
                disabled={props.isSubmitting}
              >
                Login
              </Button>
              <Box>{props.status && props.status}</Box>
              <Text
                m={'50px auto 28px'}
                fontSize={'1rem'}
                fontWeight={'600'}
                letterSpacing={'.5px'}
              >
                Are you new here?<br></br>Sign up to join us
              </Text>
              <ButtonLink to='/register'>
                {isLoading ? 'Loading' : 'Sign Up'}
              </ButtonLink>
              <ButtonLink to='/preview'>View curriculum</ButtonLink>
            </Box>
          </Flex>
        )}
      </Formik>
    </>
  );
};

export default Login;
