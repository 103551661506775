import { Flex, Image } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';

interface Props {
  w: string;
}

export const PortraitWithBg: React.FC<Props> = ({ w }) => {
  const { urls, skinChoice, tempBg } = useAppSelector((state) => state.user);

  return (
    <Flex position={'relative'} justifyContent={'center'} alignItems={'center'}>
      <Image
        src={urls.backgrounds + tempBg}
        w={['100%', w]}
        borderRadius={'15px'}
      />
      <Image
        // height={avatarHeight}
        position={'absolute'}
        height={'65%'}
        l={'75px'}
        src={urls.characters + skinChoice.filename}
        m={'0 auto'}
      />
    </Flex>
  );
};
