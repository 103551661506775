import React from 'react';
import { Select, SelectProps, FormHelperText } from '@chakra-ui/react';
import { useField } from 'formik';

import FormLabel from 'components/forms/FormLabel';
import FormError from 'components/forms/FormError';

import { ChevronDown } from 'components/icons';

interface FormSelectProps extends SelectProps {
  name: string;
  label?: string;
  value: string | number;
  helperText?: string;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  label,
  helperText,
  children,
  ...rest
}) => {
  const [field] = useField(name);

  return (
    <>
      <FormLabel htmlFor={rest.id || field.name}>{label}</FormLabel>
      <Select
        icon={<ChevronDown />}
        iconSize={'12px'}
        border={'none'}
        borderRadius={'5px'}
        bgColor={'rgba(255, 255, 255, 0.65)'}
        color={'#243D9D'}
        letterSpacing={'.5px'}
        {...field}
      >
        {children}
      </Select>
      {helperText && (
        <FormHelperText color={'#FFF'}>{helperText}</FormHelperText>
      )}
      <FormError name={field.name} />
    </>
  );
};

export default FormSelect;
