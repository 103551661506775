import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { To } from 'history';
import {
  Box,
  Flex,
  HStack,
  Link,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import BackButton from '../buttons/BackButton';
import { useAppSelector } from 'hooks/useAppSelector';
import MobileNav from 'components/nav/MobileNav';
import DesktopNav from 'components/nav/DesktopNav';
import logo from 'app/logo.svg';
import logoSolid from 'app/logoSolid.svg';
import FixPositionBackButton from 'components/buttons/FixPositionBackButton';
export interface INavItem {
  href: To;
  label: string;
  topDivider?: boolean;
}

export interface QuestItem {
  id: string;
  label: string;
  is_complete?: boolean;
}

const GUARDIAN_NAV_ITEMS: INavItem[] = [
  {
    href: '/',
    label: 'Choose User',
  },
  {
    href: '/account',
    label: 'My Account',
    topDivider: true,
  },
  {
    href: '/settings',
    label: 'Settings',
  },
  {
    href: 'https://valearnis.com/contact-us',
    label: 'Help and feedback',
  },
  {
    href: 'https://cdn.valearnis.com/docs/privacypolicy.pdf',
    label: 'Privacy Policy',
  },
  {
    href: '/logout',
    label: 'Log out',
  },
];

const STUDENT_NAV_ITEMS: INavItem[] = [
  {
    href: '/',
    label: 'Choose User',
  },
  {
    href: '/dashboard',
    label: 'Dashboard',
  },
  {
    href: '/curriculum',
    label: 'Lessons',
    topDivider: true,
  },
  {
    href: '/profile',
    label: 'Profile',
  },
  {
    href: '/house-faction',
    label: 'House Faction',
  },
  {
    href: '/reports',
    label: 'Reports',
  },
  {
    href: '/ai-teacher',
    label: 'AI-Teacher',
  },
  {
    href: '/settings',
    label: 'Settings',
    topDivider: true,
  },
  {
    href: 'https://valearnis.com/contact-us',
    label: 'Help and feedback',
  },
  {
    href: 'https://cdn.valearnis.com/docs/privacypolicy.pdf',
    label: 'Privacy',
  },
  {
    href: '/logout',
    label: 'Log out',
  },
];

const Header: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);
  const baseUrl = window.location.origin;

  useEffect(() => {
    const curLocation = sessionStorage.getItem('curLocation');
    if (curLocation !== null) {
      sessionStorage.setItem('prevLocation', curLocation);
    }
    if (window.location.href !== null) {
      sessionStorage.setItem('curLocation', window.location.href);
    }
  }, [location]);

  return (
    <>
      <Box
        as={'header'}
        pos={'absolute'}
        w={'100%'}
        // minH={'60px'}
        px={auth.isAuth ? ['2rem', null, '4rem', '6rem', '8rem'] : '1rem'}
        pt={'2rem'}
        color={'#fff'}
        role={'header'}
      >
        <Flex
          h={16}
          alignItems={'center'}
          justifyContent={auth.isAuth ? 'space-between' : 'center'}
        >
          <HStack spacing={8} alignItems={'center'} zIndex={10}>
            {auth.isAuth ? (
              <Link as={RouterLink} to='/dashboard'>
                <LogoImage />
              </Link>
            ) : (
              <Link as={RouterLink} to='/'>
                <LogoImage />
              </Link>
            )}
          </HStack>
          {auth.isAuth && (
            <>
              <Box display={['block', 'none']}>
                <MobileNav
                  navItems={
                    user.studentId !== ''
                      ? STUDENT_NAV_ITEMS
                      : GUARDIAN_NAV_ITEMS
                  }
                  userDetails={user}
                  isOpen={isOpen}
                  onToggle={onToggle}
                />
              </Box>
              <Box display={['none', 'block']}>
                <DesktopNav
                  navItems={
                    user.studentId !== ''
                      ? STUDENT_NAV_ITEMS
                      : GUARDIAN_NAV_ITEMS
                  }
                  userDetails={user}
                />
              </Box>
            </>
          )}
        </Flex>
        {window.location.href !== baseUrl + '/dashboard' &&
          window.location.href !== baseUrl + '/account' &&
          auth.isAuth && <BackButton />}
        {!auth.isAuth && <FixPositionBackButton />}
      </Box>
    </>
  );
};

const LogoImage: React.FC = () => {
  const container = document.getElementById('paddedContainer');
  const logoSrc = container ? logo : logoSolid;
  return (
    <Image
      src={logoSrc}
      width={['172px', null, '190px', '210px']}
      htmlWidth={'172px'}
      alt='Valearnis logo'
      ignoreFallback
    />
  );
};

export default Header;
