export const getThumbnailFilename = (filename: string) => {
  const fileParts = filename.split('-');
  const ending = '-thumbnail-' + fileParts.pop();
  return fileParts.join('-') + ending;
};

export const removeCharNumber = (filename: string) => {
  const parts = filename.split('-');
  parts.pop();
  return parts.join('-');
};
