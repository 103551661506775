import React, { useState, useEffect } from 'react';
import { Heading, Flex, Box, Text } from '@chakra-ui/react';
import FactionCard from './FactionCard';
import HouseFactions from './HouseFactions';
import { useAppSelector } from 'hooks/useAppSelector';
import Notification from 'app/Notification';

const ChooseFaction: React.FC = () => {
  const [error, setError] = useState(false);
  const { recommendedFaction, selectedFaction } = useAppSelector(
    (state) => state.faction,
  );

  const updateHouseHandler = (result: boolean) => {
    if (result) {
      setError(false);
    } else setError(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 5000);
  }, [error]);

  return (
    <>
      {error && (
        <Notification
          message={'Unable to join faction. Come back and try again later.'}
        />
      )}

      <Box
        display={'flex'}
        flexDir={'column'}
        justifyContent={'center'}
        alignContent={'center'}
        m={['7rem auto', '7rem auto', '7rem auto', '0 auto', '0 aut0']}
        p={['1rem 0', '2rem 0', '2rem 0 1rem']}
        borderRadius={'10px'}
      >
        {selectedFaction === '' && (
          <React.Fragment>
            <Heading pb={'0.5rem'}>Choose your faction</Heading>
            <Text
              fontSize={['0.9rem', '1rem', '1.1rem', '1.2rem']}
              fontWeight={'bold'}
              p={'0 2rem'}
              mb={['1rem', '1rem', '2rem', '4rem']}
            >
              {`You are recommended to join ${recommendedFaction}! You may choose another if you feel you belong there.`}
            </Text>
          </React.Fragment>
        )}

        <Flex
          flexDir={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
          justify={'space-evenly'}
          align={'center'}
          mb={['1rem', '1rem', '1rem', '2rem']}
        >
          {HouseFactions.map(
            ({ name, description, borderImg, emblemImg, color }) => (
              <FactionCard
                key={name}
                id={name}
                name={name}
                description={description}
                borderImg={borderImg}
                emblemImg={emblemImg}
                color={color}
                updateHouseHandler={updateHouseHandler}
              />
            ),
          )}
        </Flex>
      </Box>
    </>
  );
};

export default ChooseFaction;
