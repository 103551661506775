import ButtonLink from 'components/buttons/ButtonLink';
import { useActions } from 'hooks/useActions';
import React from 'react';

const btnStyle = {
  width: ['100%', '80%', '60%', '40%'],
  height: '40px',
  borderRadius: '12px',
  backgroundColor: '#2871D1',
  lineHeight: '12px',
};

const BackToSubJectSelectButton: React.FC = () => {
  const { resetSelections, setModule, setSubject } = useActions();
  return (
    <ButtonLink
      to={'/curriculum'}
      {...btnStyle}
      onClick={() => {
        setModule('');
        setSubject('');
        resetSelections();
      }}
    >
      Continue
    </ButtonLink>
  );
};

export default BackToSubJectSelectButton;
