import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, AuthUserType, IAuthUser, ILoginResponse } from 'app/services/api';
import { logoutSuccess } from 'features/auth/logoutSuccess';
import { refreshToken } from 'features/auth/refreshToken';
import { getDatePlusSeconds } from 'utils/dateUtils';
// import { fetchInitialData } from './fetchInitialData';
import { QuestItem } from 'components/nav/Header';

interface AuthState {
  errors: string[] | null;
  isAuth: boolean;
  isLoading: boolean;
  user: IAuthUser;
  questItems: QuestItem[];
  isFirstStudent: boolean;
}

const nullUser = {
  username: null,
  name: null,
  token: null,
  tokenExpiry: null,
  type: AuthUserType.Student,
  subscriptionActive: false,
  isVerified: false,
  unpaid: true,
  year: [7],
};

const initialState: AuthState = {
  errors: null,
  isAuth: false,
  isLoading: false,
  user: nullUser,
  questItems: [],
  isFirstStudent: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest(state, { payload }: PayloadAction<string>) {
      state.isLoading = true;
      state.user.username = payload;
    },
    loginSuccess(state, { payload }: PayloadAction<ILoginResponse>) {
      state.errors = null;
      state.isAuth = true;
      state.isLoading = false;
      const tokenExpiry = getDatePlusSeconds(
        payload.access_expiry ? payload.access_expiry : 0,
      );
      // TODO: replace with real data when
      // it is being sent from back-end
      const authUser: IAuthUser = {
        username: payload.username,
        name: payload.name,
        token: payload.access,
        tokenExpiry,
        // TODO: Use different interfaces for guardians/students?
        year: payload.year ? payload.year : [],
        type:
          payload.user_type === 'GUARDIAN'
            ? AuthUserType.Guardian
            : payload.user_type === 'STUDENT'
            ? AuthUserType.Student
            : AuthUserType.Employee,
        subscriptionActive:
          payload.user_type === 'EMPLOYEE' || payload.subscription_active,
        isVerified: payload.is_verified,
        unpaid: payload.unpaid,
        studentsToCreate: payload.students_to_create,
      };
      state.user = authUser;
    },
    loginFailure(state, { payload }: PayloadAction<string[]>) {
      state.errors = payload;
      state.isAuth = false;
      state.isLoading = false;
      state.user = nullUser;
    },
    refreshAccessToken(state, { payload }: PayloadAction<IAuthUser>) {
      state.errors = null;
      state.user = { ...payload };
    },
    registerFailure(state, { payload }: PayloadAction<string[]>) {
      state.errors = payload;
      state.isAuth = false;
      state.isLoading = false;
    },
    firstStudentRegister(state, { payload }: PayloadAction<boolean>) {
      state.isFirstStudent = payload;
    },
    acknowledgeParentModal(state) {
      state.isFirstStudent = false;
    },
    clearFailures(state) {
      state.errors = null;
    },
    verifyEmail(state) {
      state.user.isVerified = true;
    },
    setToken(
      state,
      { payload }: PayloadAction<{ access: string; tokenExpiry: string }>,
    ) {
      state.user.token = payload.access;
      state.user.tokenExpiry = payload.tokenExpiry;
    },
    removeToken(state) {
      state.user.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutSuccess, (state) => {
        state.errors = null;
        state.isAuth = false;
        state.user = nullUser;
      })
      .addCase(refreshToken, (state, { payload }) => {
        // TODO: error handling?

        state.user.token = payload.token;
        state.user.tokenExpiry = payload.tokenExpiry;
      })
      .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
        if (payload.status !== 200 || !payload.access_expiry) {
          state.errors = [payload.message];
        } else {
          const tokenExpiry = getDatePlusSeconds(payload.access_expiry);
          // TODO: replace with real data when
          // it is being sent from back-end
          const authUser: IAuthUser = {
            username: payload.username,
            name: payload.name,
            token: payload.access,
            tokenExpiry,
            // TODO: Use different interfaces for guardians/students?
            year: payload.year ? payload.year : [],
            type:
              payload.user_type === 'GUARDIAN'
                ? AuthUserType.Guardian
                : payload.user_type === 'STUDENT'
                ? AuthUserType.Student
                : AuthUserType.Employee,
            subscriptionActive:
              payload.user_type === 'EMPLOYEE' || payload.subscription_active,
            isVerified: payload.is_verified,
            unpaid: payload.unpaid,
            studentsToCreate: payload.students_to_create,
          };
          state.errors = null;
          state.isAuth = true;
          state.isLoading = false;
          state.user = authUser;
        }
      })
      .addMatcher(
        api.endpoints.refreshToken.matchFulfilled,
        (state, { payload }) => {
          const tokenExpiry = getDatePlusSeconds(payload.access_expiry);
          state.user.token = payload.access;
          state.user.tokenExpiry = tokenExpiry;
        },
      )
      .addMatcher(
        api.endpoints.getStudentQuestItems.matchFulfilled,
        (state, { payload }) => {
          state.questItems = payload.payload;
        },
      )

      .addMatcher(
        api.endpoints.tagStudentQuestItem.matchFulfilled,
        (state, { payload }) => {
          state.questItems = payload.payload;
        },
      )
      .addMatcher(api.endpoints.refreshToken.matchRejected, (state) => {
        state.isAuth = false;
      });
  },
});

export const authActions = authSlice.actions;

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerFailure,
  clearFailures,
  verifyEmail,
  setToken,
  removeToken,
  firstStudentRegister,
  acknowledgeParentModal,
} = authSlice.actions;

export default authSlice.reducer;
