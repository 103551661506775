import React from 'react';
import {
  Box,
  Button,
  Heading,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import {
  setQuizProgress,
  setSelectedAnswer,
  submitSelectedAnswer,
} from './FactionQuizSlice';

const HouseFactionQuiz: React.FC = () => {
  const { questions, currentQuestionIndex, selectedAnswer } = useAppSelector(
    (state) => state.faction,
  );
  const currentQuestion = questions[currentQuestionIndex];
  const options = currentQuestion.answers.map((answer) => answer.answer);
  const dispatch = useDispatch();

  const handleAnswerChange = (value: string) => {
    dispatch(setSelectedAnswer(value));
  };

  const handleAnswerSubmit = () => {
    dispatch(submitSelectedAnswer());
    if (currentQuestionIndex >= questions.length - 1) {
      dispatch(setQuizProgress(false));
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, translateY: '-20px' }}
      animate={{ opacity: 1, translateY: '0' }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <Box
        position={'relative'}
        display={'flex'}
        flexDir={'column'}
        bgColor={'brand.600'}
        bgGradient={'linear(#5A99F7, #4560C3, #243D9D)'}
        justifyContent={'space-evenly'}
        w={['90%', '80%', '750px', '800px', '950px']}
        minH={['60vh', null, '60vh']}
        m={'3rem auto'}
        p={'1.5rem 0'}
        borderRadius={'30px'}
      >
        <Box m={'0 auto'} w={'85%'}>
          <Heading
            as='h1'
            fontSize={['1.1rem', null, '1.4rem']}
            mb={'1rem'}
            fontWeight={'medium'}
          >
            Question {currentQuestionIndex + 1}
          </Heading>

          <Heading
            as='h2'
            fontSize={['1rem', '1.1rem', null, '1.2rem', '1.4rem']}
            pb={'1rem'}
            borderBottom={'1px solid white'}
          >
            {currentQuestion.question}
          </Heading>
        </Box>
        <RadioGroup
          m={['1rem auto', '1rem auto', '2rem auto']}
          p={['0rem 1rem', '0rem 2rem', '0rem 4rem']}
          size={'md'}
          value={selectedAnswer}
          onChange={handleAnswerChange}
        >
          <Stack spacing={[6, 6, 8]}>
            {options.map((option) => {
              return (
                <Radio
                  key={option}
                  value={option}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleAnswerChange(event.target.value)
                  }
                >
                  <Box
                    display='flex'
                    textAlign={'left'}
                    ml={['0.5rem', '0.5rem', '1rem']}
                    fontSize={['0.9rem', '1rem', '1rem', '1.1rem']}
                  >
                    {option}
                  </Box>
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>

        <Button disabled={!selectedAnswer} onClick={handleAnswerSubmit}>
          Submit Answer
        </Button>
      </Box>
    </motion.div>
  );
};

export default HouseFactionQuiz;
