import { Avatar } from '@chakra-ui/avatar';
import React from 'react';

type ProfileAvatarProps = {
  src: string;
  onClick?: () => void;
};

export default function ProfileAvatar({ src, onClick }: ProfileAvatarProps) {
  return (
    <Avatar
      size={'2xl'}
      src={src}
      cursor='pointer'
      onClick={onClick}
      _hover={{
        transform: 'scale(1.05, 1.05)',
        _after: {
          border: '5px solid rgba(255,255,255, 0.8)',
        },
      }}
      _after={{
        content: '""',
        position: 'absolute',
        inset: 0,
        border: '4px solid rgba(255,255,255,0)',
        borderRadius: '50%',
        transition:
          'border 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955)',
      }}
      transition='transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955)'
    />
  );
}
