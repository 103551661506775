import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { css } from '@emotion/react';

import CircularProgressBar from 'components/data/CircularProgressBar';
import ButtonLink from 'components/buttons/ButtonLink';
import { lowerCase } from 'utils/stringUtils';
import { useUrlParts } from 'hooks/useUrlParts';
import { useEffect } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { findTopicId } from 'utils/arrayUtils';
import { useActions } from 'hooks/useActions';

const wrapperStyle = css({
  flexFlow: 'row wrap',
  maxWidth: '246px',
  margin: '0 auto',

  '> *': {
    flex: '1 1 100%',
  },
});

const ModuleOverview: React.FC = () => {
  const urlParts = useUrlParts();
  const subject = urlParts[urlParts.length - 2];
  const module = urlParts[urlParts.length - 1];

  const { menuItems } = useAppSelector((state) => state.menu);
  const { setModule } = useActions();
  useEffect(() => {
    const code = findTopicId(menuItems, subject, module);
    setModule({ to: module, code });
  }, []);

  return (
    <Flex css={wrapperStyle} pt={['5vh', null, '10vh']}>
      <CircularProgressBar
        percentage={0}
        icon={`https://cdn.valearnis.com/icons/${lowerCase(module)}.svg`}
      />
      <Heading
        as={'h1'}
        m={'38px auto'}
        textAlign={'center'}
        textTransform={'uppercase'}
        fontSize={'1.5rem'}
        letterSpacing={'2px'}
      >
        {module}
      </Heading>
      <ButtonLink to='intro' width={'100%'} textAlign={'left'} mb={'0'}>
        Intro
      </ButtonLink>
      <ButtonLink to='lessons' width={'100%'} textAlign={'left'} mb={'0'}>
        Lessons
      </ButtonLink>
      <ButtonLink to='videos' width={'100%'} textAlign={'left'} mb={'0'}>
        Videos
      </ButtonLink>
    </Flex>
  );
};

export default ModuleOverview;
