import { Flex, Icon } from '@chakra-ui/react';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';

interface Props {
  direction: 'left' | 'right';
  navToQuestion: number;
}

const Arrow: React.FC<Props> = ({ direction, navToQuestion }) => {
  const {
    nextQuestion,
    prevQuestion,
    setCurrentQuestionId,
    snapBallToPos,
    snapToNumber,
  } = useActions();
  const { questions } = useAppSelector((state) => state.questions);

  const snapToQuestion = (number: number) => {
    const numOfQuestions = questions.length;
    let newPos;
    if (number < 3) {
      newPos = 0;
    } else {
      newPos = (number - 2) * 70;
    }
    if (number - 1 !== numOfQuestions) {
      snapToNumber(newPos);
      snapBallToPos();
    }
  };
  return (
    <Flex
      justify={direction === 'right' ? 'left' : 'right'}
      align={'center'}
      w={'40px'}
      h={'40px'}
      cursor={'pointer'}
      onClick={() => {
        if (direction === 'left') {
          prevQuestion();
        } else {
          nextQuestion();
        }
        setCurrentQuestionId();
        snapToQuestion(navToQuestion);
      }}
    >
      <Icon
        as={direction === 'left' ? ChevronLeft : ChevronRight}
        fontSize={'16px'}
      />
    </Flex>
  );
};

export default Arrow;
