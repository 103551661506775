import { Flex, Button } from '@chakra-ui/react';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
// import { useAppSelector } from 'hooks/useAppSelector';
// import { useUpdateStudentSettingsMutation } from 'app/services/api';
import React from 'react';

const SelectionButton: React.FC = () => {
  // const [updateStudentSettings] = useUpdateStudentSettingsMutation();
  const { tempCharacterChoice } = useAppSelector((state) => state.user);
  const { toggleSettingTheme, toggleSelectingAvatar } = useActions();

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }} // Changes layout based on screen size
      justifyContent={{ base: 'center', md: 'space-between' }} // Adjusts spacing
      alignItems='center' // Centers items vertically in the container
      w='full' // Container takes full width
      mb={{ base: 4, md: 0 }} // Margin bottom on base, none on md screens
    >
      <Button
        disabled={!tempCharacterChoice.is_unlocked}
        onClick={() => toggleSettingTheme()}
        bgColor='button.300'
      >
        Customise
      </Button>
      <Button
        bgColor='button.300'
        onClick={() => toggleSelectingAvatar()}
        ml={{ md: 4 }}
      >
        Summary
      </Button>
    </Flex>
  );
};

export default SelectionButton;
