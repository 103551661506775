import React from 'react';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { Icon } from '@chakra-ui/react';

interface Props {
  childId: string;
}

const ScrollBox: React.FC<Props> = ({ children, childId }) => {
  const moveChild = (amount: number) => {
    const target = document.getElementById(childId);
    if (target) {
      target.scrollLeft += amount;
    }
  };
  return (
    <>
      <Icon
        cursor={'pointer'}
        as={ChevronLeft}
        fontSize={'16px'}
        mr={'20px'}
        onClick={() => moveChild(-100)}
      ></Icon>
      {children}
      <Icon
        cursor={'pointer'}
        as={ChevronRight}
        fontSize={'16px'}
        ml={'20px'}
        onClick={() => moveChild(100)}
      ></Icon>
    </>
  );
};

export default ScrollBox;
