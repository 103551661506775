const HouseFactions = [
  {
    name: 'Flamespire',
    description:
      'Flamespire is for students who value ambition, those who aspire to be greater. They are driven, determined, and are not afraid to aim high in all their endeavors, no matter the cost. Those who lead are often from House Flamespire. Their symbol,a phoenix, represents the relentless pursuit of their dreams and aspirations.',
    borderImg:
      'https://cdn.valearnis.com/house-factions/flamespire/Flamespire%20Border.png',
    emblemImg:
      'https://cdn.valearnis.com/house-factions/flamespire/Valearnis%20Flamespire%20Background%20LABEL.png',
    backgroundImg:
      'https://cdn.valearnis.com/house-factions/flamespire/Flamespire%20Background.png',
    color: 'red',
  },
  {
    name: 'Moonspark',
    description:
      "The students of Moonspark value logic, curiosity, and critical thinking. They are the fact-checkers, knowledge seekers, and the skeptics who aren't afraid to question and seek the truth. They are driven by a strong sense of curiosity for the world around them, striving to learn more. The great thinkers and philosophers tend to come from House Moonspark. Their emblem, an eclipse on an open book, represents their curiosity and thirst for knowledge.",
    borderImg:
      'https://cdn.valearnis.com/house-factions/moonspark/Moonspark%20Border.png',
    emblemImg:
      'https://cdn.valearnis.com/house-factions/moonspark/Valearnis%20Moonspark%20Background%20LABEL.png',
    backgroundImg:
      'https://cdn.valearnis.com/house-factions/moonspark/Moonspark%20Background.png',
    color: 'blue',
  },
  {
    name: 'Heartleaf',
    description:
      'Heartleaf is for students who value loyalty and friendship above all. They are the mediators, the team players, the nurturers who believe in creating a strong, reliable community. They focus on friendships, having fun, and supporting those around them. Without House Heartleaf, the world wouldn`t be as bright. Their symbol, an intertwined trio of leaves, represents the strength and stability of their bonds. ',
    borderImg:
      'https://cdn.valearnis.com/house-factions/heartleaf/Heartleaf%20Border.png',
    emblemImg:
      'https://cdn.valearnis.com/house-factions/heartleaf/Valearnis%20Heartleaf%20Background%20LABEL.png',
    backgroundImg:
      'https://cdn.valearnis.com/house-factions/heartleaf/Heartleaf%20Background.png',
    color: 'green',
  },
];

export default HouseFactions;
