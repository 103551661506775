import React, { useEffect, useState } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import {
  useCancelSubscriptionMutation,
  useLazyGetUpdateCardUrlQuery,
} from 'app/services/api';
import { useActions } from 'hooks/useActions';

const UpdateBilling: React.FC = () => {
  const [cancelClicked, setCancelClicked] = useState(false);
  const [cancelSubscription] = useCancelSubscriptionMutation();
  const { addNotification } = useActions();
  const [getCheckout, response] = useLazyGetUpdateCardUrlQuery();

  useEffect(() => {
    if (response.isSuccess) {
      window.location.href = response.data.url;
    } else if (response.isError) {
      addNotification(
        'Something went wrong, please try again later or contact customer support.',
      );
    }
  });

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('update_failed') === '1') {
      addNotification(
        'Something went wrong, please try again later or contact customer support.',
      );
    }
  }, []);
  return (
    <Flex
      flexFlow={'row wrap'}
      maxW={['15.375rem', null, '720px']}
      w={['70vw']}
      m={'0 auto'}
      pt={['5vh', null, '10vh']}
      position={'relative'}
      justifyContent={'center'}
    >
      {cancelClicked ? (
        <Flex
          position={'absolute'}
          bgColor={'brand.600'}
          flexDir={'column'}
          p={'20px'}
          borderRadius={'15px'}
          width={['90%']}
          zIndex={'3'}
        >
          <Text>
            Are you sure you want to cancel your subscription? Your student(s)
            will lose access to Valearnis. However, if you decide to
            re-subscribe later, your student will be able to pick up where they
            left off.
          </Text>
          <Button
            onClick={() => {
              setCancelClicked(false);
            }}
          >
            I want to stay
          </Button>
          <Button
            onClick={() => {
              cancelSubscription();
              setCancelClicked(false);
            }}
          >
            Yes, I am sure
          </Button>
        </Flex>
      ) : (
        <>
          <Button
            onClick={() => {
              getCheckout();
            }}
            label={'Update Credit Card'}
          >
            Update Credit Card
          </Button>
          <Button
            label={'Cancel Subscription'}
            onClick={() => {
              setCancelClicked(true);
            }}
          >
            Cancel Subscription
          </Button>
        </>
      )}
    </Flex>
  );
};

export default UpdateBilling;
