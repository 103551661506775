import React from 'react';
import { Input } from '@chakra-ui/input';
import { Box } from '@chakra-ui/layout';
import { useRadio, UseRadioProps } from '@chakra-ui/radio';

interface Props {
  radio: UseRadioProps;
}

const PictureChoice: React.FC<Props> = (props) => {
  const { getCheckboxProps, getInputProps } = useRadio(props.radio);
  // const userAnswer = answers[currentQuestion];
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as={'label'}>
      <Input role={'radioChoice'} {...input} />
      <Box
        id={'choice' + props.radio.value}
        {...checkbox}
        textAlign={'center'}
        position={'relative'}
        width={'100%'}
        boxShadow={props.radio.value === '' ? 'outline' : ''}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default PictureChoice;
