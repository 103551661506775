import { useLocation } from 'react-router-dom';

export const useUrlParts = () => {
  const location = useLocation();
  const urlParts = location.pathname.split('/');
  urlParts.shift();
  if (urlParts[urlParts.length - 1] === '') {
    urlParts.pop();
  }

  return urlParts;
};
