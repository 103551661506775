import React from 'react';
import { FormControlProps, FormControl } from '@chakra-ui/react';

const FormFieldContainer: React.FC<FormControlProps> = ({
  children,
  ...rest
}) => (
  <FormControl
    display={'flex'}
    flexDirection={'column'}
    alignItems={'flex-start'}
    flex={'1 1 100%'}
    mb={'1.125rem'}
    {...rest}
  >
    {children}
  </FormControl>
);

export default FormFieldContainer;
