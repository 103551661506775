import { capitalise } from 'utils/stringUtils';
import { useAppSelector } from './useAppSelector';
import { useUrlParts } from './useUrlParts';

export const useButtonData = () => {
  const urlParts = useUrlParts();
  const { menuItems } = useAppSelector((state) => {
    return state.menu;
  });
  if (urlParts.length === 1) {
    return menuItems;
  } else {
    const subjectArr = menuItems.find(
      (item: any) => item.name === capitalise(urlParts[1]),
    );
    if (subjectArr !== undefined) {
      return subjectArr.topics;
    }
  }
};
