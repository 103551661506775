import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Notification from 'app/Notification';
import { useAppSelector } from 'hooks/useAppSelector';

import App from 'app/App';
import Register from 'features/auth/Register';
import Login from 'features/auth/Login';
import ChooseUser from 'features/auth/ChooseUser';
import ChangePassword from 'features/auth/ChangePassword';
import Logout from 'features/auth/Logout';
import RequireAuth from 'features/Routing/RequireAuth';
import ModuleOverview from 'features/curriculum/ModuleOverview';
import TopicIntro from 'features/lessons/TopicIntro';
import Videos from 'features/lessons/Videos';
import LessonsList from 'features/lessons/LessonsList';
import Lesson from 'features/lessons/Lesson';
import NotFound from 'components/NotFound';
import Terms from 'features/auth/Terms';
import Privacy from 'features/auth/Privacy';
import ResultsPage from 'features/lessons/results/ResultsPage';
import ResultsPreviewPage from 'features/lessons/results/ResultsPreviewPage';
import QuizSummary from 'features/lessons/results/QuizSummary';
import LessonPreviewQuizSummary from 'features/lessons/results/LessonPreviewQuizSummary';
import DashboardLayout from 'layout/DashboardLayout';
import ChoosePlan from 'features/account/parent/ChoosePlan';
import SetupStudentAccount from 'features/account/SetupStudentAccount';
import ProfileSummary from 'features/userProfile/ProfileSummary';
import ReportsPage from 'features/Reports/ReportsPage';
import SettingsPage from 'features/settings/SettingsPage';
import ChangePasswordPage from 'features/changePassword/ChangePasswordPage';
import Dashboard from 'features/dashboard/Dashboard';
import ParentDashboard from 'features/dashboard/ParentDashboard';
import ManageStudents from 'features/dashboard/ManageStudents';
import ManageSingleStudent from 'features/dashboard/ManageSingleStudent';
import ResetStudentPassword from 'features/dashboard/ResetStudentPassword';
import ManageStudentsLayout from 'features/dashboard/ManageStudentsLayout';
import OtpInputPage from 'features/account/parent/OtpInputPage';
// import Subjects from 'features/curriculum/ ';
import Curriculum from 'features/curriculum/Curriculum';
import ManageBilling from 'features/dashboard/ManageBilling';
import Studentlogin from 'features/dashboard/Studentlogin';
import CurriculumSummary from 'features/preview/CurriculumSummary';
import LessonPreview from 'features/preview/LessonPreview';
import HouseFactionPage from 'features/houseFaction/HouseFactionPage';
import AITeacherPage from 'features/AiTeacher/index';
import AILessonPage from 'features/AiTeacher/aiLesson';
import ChooseUserPinEnter from 'features/auth/ChooseUserPinEnter';
import ForgotPin from 'features/auth/ForgotPin';
import EditProfile from 'features/auth/EditProfile';
import EditProfiles from 'features/auth/EditProfiles';
import CreateProfile from 'features/auth/CreateProfile';
import ProfilePinShow from 'features/auth/ProfilePinShow';

// import CurriculumSummary from 'features/preview/CurriculumSummary';

const Report = React.lazy(() => import('features/Reports/Report'));

const AppRouter: React.FC = () => {
  const { notifications } = useAppSelector((state) => state.notification);
  return (
    <Router>
      {notifications.map((notification: string, index: number) => {
        return (
          <Notification
            key={`${index}-${notification}`}
            message={notification}
          />
        );
      })}
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='register' element={<Register />} />
          <Route path='/login/*' element={<Login />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/terms-of-service' element={<Terms />} />
          <Route path='/privacy-agreement' element={<Privacy />} />
          <Route path='/*' element={<NotFound />} />
        </Route>
        <Route
          path='preview'
          element={
            <DashboardLayout>
              <CurriculumSummary />
            </DashboardLayout>
          }
        ></Route>
        <Route
          path='preview-lesson/:subject'
          element={
            <DashboardLayout>
              <LessonPreview />
            </DashboardLayout>
          }
        ></Route>
        <Route
          path='preview-lesson-results/:subject'
          element={
            <DashboardLayout>
              <ResultsPreviewPage />
            </DashboardLayout>
          }
        ></Route>
        <Route
          path='preview-lesson-answers/:subject'
          element={
            <DashboardLayout>
              <LessonPreviewQuizSummary />
            </DashboardLayout>
          }
        ></Route>
        <Route path='/logout/*' element={<Logout />} />
        {/* Private Routes for authenticated users */}
        <Route
          path='account/choose-user'
          element={
            <RequireAuth>
              <ChooseUser />
            </RequireAuth>
          }
        />
        <Route
          path='account/create-profile'
          element={
            <RequireAuth>
              <CreateProfile />
            </RequireAuth>
          }
        />
        <Route
          path='account/profile-pin'
          element={
            <RequireAuth>
              <ProfilePinShow />
            </RequireAuth>
          }
        />
        <Route
          path='account/edit-profiles'
          element={
            <RequireAuth>
              <EditProfiles />
            </RequireAuth>
          }
        />
        <Route
          path='account/edit-profile/:studentId'
          element={
            <RequireAuth>
              <EditProfile />
            </RequireAuth>
          }
        />
        <Route
          path='account/enter-pin/:studentId'
          element={
            <RequireAuth>
              <ChooseUserPinEnter />
            </RequireAuth>
          }
        />
        <Route
          path='account/forgot-pin/:studentId'
          element={
            <RequireAuth>
              <ForgotPin />
            </RequireAuth>
          }
        />
        <Route
          path='dashboard'
          element={
            <RequireAuth>
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            </RequireAuth>
          }
        >
          <Route path='curriculum' element={<Curriculum />} />
          <Route path='ai-teacher' element={<Curriculum />} />
        </Route>
        <Route
          path='curriculum'
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route index={true} element={<Curriculum />} />
          <Route path=':subject' element={<Curriculum />}></Route>
          <Route path=':subject/:module' element={<ModuleOverview />}></Route>

          <Route
            path=':subject/:module/lessons'
            element={<LessonsList />}
          ></Route>
          <Route path=':subject/:module/intro' element={<TopicIntro />} />
          <Route path=':subject/:module/lessons/:lesson' element={<Lesson />} />
          <Route path=':subject/:module/videos' element={<Videos />} />
          <Route
            path=':subject/:module/lessons/:lesson/results'
            element={<ResultsPage />}
          />
          <Route
            path=':subject/:module/lessons/:lesson/results/answers'
            element={<QuizSummary />}
          />
        </Route>
        <Route
          path='house-faction'
          element={
            <RequireAuth>
              <HouseFactionPage />
            </RequireAuth>
          }
        />

        <Route
          path='/ai-teacher'
          element={
            <RequireAuth>
              <AITeacherPage />
            </RequireAuth>
          }
        ></Route>
        <Route
          path='/ai-lesson'
          element={
            <RequireAuth>
              <AILessonPage />
            </RequireAuth>
          }
        ></Route>
        <Route
          path='settings'
          element={
            <RequireAuth>
              <SettingsPage />
            </RequireAuth>
          }
        />
        <Route
          path='settings/change-password'
          element={
            <RequireAuth>
              <ChangePasswordPage />
            </RequireAuth>
          }
        />
        <Route
          path='reports'
          element={
            <RequireAuth>
              <ReportsPage />
            </RequireAuth>
          }
        />
        <Route
          path='profile'
          element={
            <RequireAuth>
              <ProfileSummary />
            </RequireAuth>
          }
        />
        <Route
          path='account'
          element={
            <RequireAuth>
              <DashboardLayout />
            </RequireAuth>
          }
        >
          <Route index={true} element={<ParentDashboard />} />
          <Route path='verification' element={<OtpInputPage />} />
          <Route path='plan' element={<ChoosePlan />} />
          <Route path='setup-students' element={<SetupStudentAccount />} />
          <Route path='add-students' element={<SetupStudentAccount />} />
          <Route path='update-billing' element={<ManageBilling />} />
          <Route path='student-login' element={<Studentlogin />} />
          ``{' '}
          <Route
            path='manage-students'
            element={<ManageStudentsLayout height={'75vh'} />}
          >
            <Route index={true} element={<ManageStudents />} />
            <Route path=':username' element={<ManageSingleStudent />}>
              <Route path='reset-password' element={<ResetStudentPassword />} />
            </Route>
          </Route>
        </Route>
        <Route
          path='pdf-report'
          element={
            <RequireAuth>
              <Suspense fallback={<p>Loading...</p>}>
                <Report />
              </Suspense>
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
