import React, { useEffect } from 'react';
import { chakra } from '@chakra-ui/system';
import { useRadioGroup, useRadio, SimpleGrid, Box } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { renderMaths } from 'utils/katexUtils';

interface Props {
  options: {
    id: number;
    content: string;
  }[];
}

const SingleChoiceImageOptions: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();
  useEffect(() => {
    renderMaths(document.getElementsByTagName('div'));
  }, [options]);

  const { getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: userAnswer ? String(userAnswer) : undefined,
    onChange: (value) => selectAnswer([value]),
  });

  return (
    <SimpleGrid
      {...getRootProps()}
      columns={[1, null, 2]}
      spacingX={[null, null, '2.5rem']}
      spacingY={['1.5rem', '2.5rem', null, null, null, '4rem']}
    >
      {options.map((option) => {
        return (
          <CustomRadio
            key={option.id}
            content={option.content}
            {...getRadioProps({ value: option.id, enterKeyHint: '' })}
          ></CustomRadio>
        );
      })}
    </SimpleGrid>
  );

  function CustomRadio(props: any) {
    const { content, ...radioProps } = props;
    const { state, getInputProps, getCheckboxProps, htmlProps, getLabelProps } =
      useRadio(radioProps);

    return (
      <chakra.label
        {...htmlProps}
        maxWidth={['100%', '70%', '100%', null, null, '80%']}
        margin={'0 auto'}
        cursor='pointer'
      >
        <input {...getInputProps()} hidden />
        <Box
          {...getCheckboxProps()}
          padding={'8px'}
          position={'relative'}
          zIndex={0}
          _before={{
            content: '""',
            position: 'absolute',
            zIndex: -1,
            inset: 0,
            padding: '8px',
            borderRadius: '1.5rem',
            background: state.isChecked ? '#1de877' : '#fff',
            '-webkit-mask': `
              linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0)`,
            mask: `
              linear-gradient(#fff 0 0) content-box, 
              linear-gradient(#fff 0 0)`,
            '-webkit-mask-composite': 'xor',
            'mask-composite': 'exclude',
          }}
        >
          <Box
            {...getLabelProps()}
            sx={{
              img: { borderRadius: '0.85rem' },
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content),
            }}
          />
        </Box>
      </chakra.label>
    );
  }
};

export default SingleChoiceImageOptions;
