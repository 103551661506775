import { Flex, Icon, Image, Text } from '@chakra-ui/react';
import { ChevronLeft, ChevronRight } from 'components/icons';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

const Portrait: React.FC = () => {
  const [characterIndex, setCharacterIndex] = useState(0);
  const { urls, tempSkinChoice, tempCharacterChoice, characters } =
    useAppSelector((state) => state.user);
  const { setTempCharacterChoice } = useActions();
  const isNarrowScreen = () => {
    return window.innerWidth <= 600;
  };

  const getCharacterImage = () => {
    if (tempSkinChoice.character !== tempCharacterChoice.id) {
      return tempCharacterChoice.skins[0].filename;
    } else {
      return tempSkinChoice.filename;
    }
  };

  const getUnlockMessage = () => {
    if (tempCharacterChoice.unlock_condition.length === 1) {
      return tempCharacterChoice.unlock_condition[0];
    }
    let message = '';
    tempCharacterChoice.unlock_condition.forEach(
      (condition: string, index: number) => {
        if (index === 0) {
          message += condition.split('to')[0] + 'and ';
        } else {
          message += condition.toLowerCase();
        }
      },
    );
    return message;
  };

  return (
    <Flex
      position={'relative'}
      justifyContent={['center', 'initial']}
      alignItems={['center', 'initial']}
      h={'100%'}
      w={['100%', 'initial']}
      textAlign={'center'}
    >
      {isNarrowScreen() && characterIndex > 0 && (
        <Icon
          position={'absolute'}
          cursor={'pointer'}
          as={ChevronLeft}
          fontSize={'16px'}
          left={'-10px'}
          onClick={() => {
            if (characterIndex > 0) {
              setCharacterIndex(characterIndex - 1);
              setTempCharacterChoice(characters[characterIndex - 1]);
            }
          }}
        ></Icon>
      )}
      <Image
        height={['100%', '65%', null, '80%']}
        opacity={!tempCharacterChoice.is_unlocked ? '0.5' : '1'}
        src={urls.characters + getCharacterImage()}
        m={'0 auto'}
      />
      {!tempCharacterChoice.is_unlocked && (
        <Text
          position={'absolute'}
          w={['80%', '100%']}
          bottom={['initial', '50%']}
        >
          {getUnlockMessage()}
        </Text>
      )}
      {isNarrowScreen() && characterIndex < characters.length - 1 && (
        <Icon
          position={'absolute'}
          cursor={
            characterIndex < characters.length - 1 ? 'pointer' : 'initial'
          }
          opacity={characterIndex < characters.length - 1 ? '1' : '0.2'}
          as={ChevronRight}
          fontSize={'16px'}
          right={'0'}
          onClick={() => {
            if (characterIndex < characters.length - 1) {
              setCharacterIndex(characterIndex + 1);
              setTempCharacterChoice(characters[characterIndex + 1]);
            }
          }}
        ></Icon>
      )}
    </Flex>
  );
};

export default Portrait;
