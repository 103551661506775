import React from 'react';
import { Flex, Box, Text, Avatar } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import BigExperienceBar from 'features/userProfile/BigExperienceBar';
import { getThumbnailFilename } from './characterSelection/utils';
const ClassInfoBox: React.FC = () => {
  const { level, expToLvl, totalLevelExp, urls, skinChoice } = useAppSelector(
    (state) => state.user,
  );
  return (
    <Flex
      direction={'column'}
      wrap={'wrap'}
      m={['25px auto', null, null, '0']}
      borderRadius={'10px'}
      bgColor={'brand.600'}
      height={'200px'}
      alignItems={'center'}
      justify={'center'}
      w={'100%'}
    >
      <Flex width={'80%'} alignItems={'center'} textAlign={'left'}>
        <Avatar
          size={'md'}
          src={
            urls.thumbnail +
            getThumbnailFilename(skinChoice.filename.split('/').pop() as string)
          }
        />
        <Text ml={'15px'}>{`Level ${level}`}</Text>
      </Flex>
      <Box textAlign={'right'} width={'80%'}>
        <BigExperienceBar expToLvl={expToLvl} totalLevelExp={totalLevelExp} />
        <Box>
          <Text>{`${totalLevelExp - expToLvl}/${totalLevelExp} XP`}</Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default ClassInfoBox;
