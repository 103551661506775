import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';

import ExperienceBar from 'features/userProfile/ExperienceBar';

const SummaryBottomSection: React.FC = () => {
  const { totalQuestions, answeredCorrectly } = useAppSelector(
    (state) => state.results,
  );
  const { totalLevelExp, expToLvl } = useAppSelector((state) => state.user);
  const percentage = Math.round((answeredCorrectly / totalQuestions) * 100);
  return (
    <Box w={'100%'} lineHeight={'17px'} textAlign={'center'}>
      <Text
        lineHeight={'20px'}
        fontSize={'14px'}
        letterSpacing={'1.12'}
        m={'0 auto 10px'}
      >
        {`Out of ${totalQuestions}, you got ${answeredCorrectly} correct! Your average for this
        lesson is ${percentage}%`}
      </Text>
      {percentage !== 100 && (
        <Text
          lineHeight={'1px'}
          as={'i'}
          fontSize={'12px'}
          letterSpacing={'0.96'}
          m={'0 auto'}
        >
          Hint: try increase your score by doing the quiz again!
        </Text>
      )}
      <ExperienceBar expToLevel={expToLvl} totalLevelExp={totalLevelExp} />
    </Box>
  );
};

export default SummaryBottomSection;
