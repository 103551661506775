import { Reports, Topic } from 'features/lessons/progressSlice';

export const getTopics = (reports: Reports, subject: string): Topic[] => {
  const topics = [] as Topic[];
  Object.values(reports.topics).forEach((data: Topic) => {
    if (data.subject === subject) {
      topics.push(data);
    }
  });
  return topics;
};

export const getBestTopic = (reports: Reports, subject: string) => {
  const topics = getTopics(reports, subject);
  let top = 0;
  let topicName = 'No data';
  topics.forEach((topic) => {
    if (typeof topic.average === 'number' && topic.average > top) {
      top = topic.average;
      topicName = topic.name;
    }
  });
  return topicName;
};
