import React, { useEffect } from 'react';
import { Select } from '@chakra-ui/select';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { useKatex } from 'hooks/useKatex';
interface Props {
  options: any;
}

const DropDownQuestion: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer() as string[];
  const renderMaths = useKatex();

  useEffect(() => {
    renderMaths(document.getElementsByTagName('option'));
  }, []);

  return (
    <Select
      placeholder={'Choose an Option'}
      role={'options'}
      bgColor={'brand.900'}
      onChange={(event) => {
        selectAnswer([event.currentTarget.value]);
      }}
      value={(userAnswer && userAnswer[0]) || 1}
    >
      {options.map((option: any) => {
        const { content, id } = option;
        return (
          <option
            key={content}
            style={{ backgroundColor: '#253d9d' }}
            value={id}
          >
            {content.replace(/(<([^>]+)>)/gi, '')}
          </option>
        );
      })}
    </Select>
  );
};

export default DropDownQuestion;
