import { Box } from '@chakra-ui/react';
import React from 'react';

interface Props {
  direction?: string;
}

const Separator: React.FC<Props> = ({ direction = 'vertical' }) => {
  return (
    <Box
      width={
        direction === 'vertical'
          ? ['100%', null, null, '1px']
          : ['1px', null, null, '100%']
      }
      height={
        direction === 'vertical'
          ? ['0px', null, null, '405px']
          : ['405px', null, null, '0px']
      }
      borderTop={'1px'}
      borderRadius={'10px'}
      bgColor={'#fff'}
      m={['30px auto', null, null, '0 30px 0']}
    ></Box>
  );
};

export default Separator;
