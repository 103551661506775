import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  IUpdateStudentsRequest,
  useUpdateStudentsMutation,
  useUpdateSubscriptionMutation,
} from 'app/services/api';
import { flattenObject } from 'utils/objectUtils';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import FormInput from 'components/forms/FormInput';
import FormSelect from 'components/forms/FormSelect';
import { IAccountDetails } from 'features/account/accountSlice';
const ManageSingleStudent: React.FC = () => {
  const location = useLocation();
  const { id, is_active, name, username, year }: IAccountDetails = Object(
    location.state,
  );
  const schoolYears = [7, 8, 9];
  const navigate = useNavigate();
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(is_active);
  const [updateStudents] = useUpdateStudentsMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const toPasswordReset = () => {
    navigate('reset-password', { state: { username } });
  };
  const toggleSubscription = async () => {
    try {
      const request = {
        student_id: String(id),
        is_active: !isSubscriptionActive,
      };
      setIsSubscriptionActive(!isSubscriptionActive);
      const result = await updateSubscription(request).unwrap();
      // TODO: Check username is available first
      if (result.status !== 200) {
        throw new Error('Something went wrong');
      }
    } catch (err: any) {
      const errors = flattenObject(err.data);

      console.log('Something went wrong', errors);
    }
  };
  const initialFormValues = {
    name: name,
    username: username,
    year: year,
  };
  const UpdateStudentSchema = Yup.object().shape({
    name: Yup.string()
      .required('This field is required')
      .min(3, 'Must be at least 3 characters')
      .max(255, 'Must be at most 255 characters'),
    username: Yup.string()
      .min(2, 'Must be at least 2 characters')
      .max(150, 'Must be at most 150 characters')
      .required('This field is required'),
  });

  return (
    <>
      <Flex flexDir={'column'} textAlign={['left', null, null, 'center']}>
        <Flex
          flexDir={['column', null, null, 'row']}
          justifyContent={'center'}
          m={'auto'}
        >
          <Formik
            initialValues={initialFormValues}
            validationSchema={UpdateStudentSchema}
            onSubmit={async (values, actions) => {
              try {
                const updatedStudent: IUpdateStudentsRequest = {
                  app_user: {
                    username: values.username,
                    name: values.name,
                  },
                  year: Number(values.year),
                  student_id: String(id),
                };
                const result = await updateStudents([updatedStudent]).unwrap();
                // TODO: Check username is available first
                if (result.status !== 200) {
                  throw new Error('Something went wrong');
                }

                navigate('/account/manage-students', {
                  replace: true,
                  state: {
                    message: 'Student updated',
                  },
                });
                // TODO: Handle errors properly
                // TODO: Improve type checking
              } catch (err: any) {
                const errors = flattenObject(err.data);

                console.log('Something went wrong', errors);
              }

              actions.setSubmitting(false);
            }}
            render={({ values }) => (
              <Flex as={Form} flexDir={'column'} justify={'center'}>
                <FormFieldContainer flex={['1 1 100%', '0 1 47%']}>
                  <FormInput name={'name'} label={'Name'} value={values.name} />
                </FormFieldContainer>
                <FormFieldContainer flex={['1 1 100%', '0 1 47%']}>
                  <FormInput
                    name={'username'}
                    label={'Username'}
                    value={values.username}
                  />
                </FormFieldContainer>
                <FormFieldContainer flex={['1 1 100%', '0 1 47%']}>
                  <FormSelect
                    name={'year'}
                    label={'Year Level'}
                    value={values.year}
                    bgColor={'rgba(255, 255, 255, 0.65)'}
                  >
                    {schoolYears.map((year) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                  </FormSelect>
                </FormFieldContainer>
                <Button type='submit' bgColor={'button.100'}>
                  Save Changes
                </Button>
              </Flex>
            )}
          />
        </Flex>
        <Flex flexDir={'column'}>
          <Button
            mb={'0'}
            h={'40px'}
            w={'190px'}
            bg={'button.100'}
            fontSize={'14px'}
            onClick={toPasswordReset}
          >
            Reset Password
          </Button>
          {isSubscriptionActive !== undefined && (
            <Button
              onClick={toggleSubscription}
              h={'40px'}
              w={'190px'}
              bg={isSubscriptionActive ? '#D64815' : 'button.100'}
              fontSize={'14px'}
            >
              {isSubscriptionActive ? 'Deactivate Student' : 'Activate Student'}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ManageSingleStudent;
