import renderMathInElement from 'katex/dist/contrib/auto-render';
import React, { useEffect } from 'react';

// This hook will render the maths of any element whose reference is passed in.

// Alternatively, if you want to render maths for multiple elements in the same component, you
// can call the hook without params and use the returned 'renderMaths' function.

// The render maths function uses a generic type which extends HTMLElement to allow any collection
// of HTML elements to be passed in.

export const useKatex = (
  ele: React.RefObject<HTMLDivElement> | null = null,
) => {
  const options = {
    delimiters: [
      { left: '$$', right: '$$', display: true },
      { left: '$', right: '$', display: false },
      { left: '\\(', right: '\\)', display: false },
      { left: '\\[', right: '\\]', display: true },
    ],
    throwOnError: false,
  };

  const renderMaths = <T extends HTMLElement>(eles: HTMLCollectionOf<T>) => {
    for (let i = 0; i < eles.length; i++) {
      renderMathInElement(eles[i], options);
    }
  };

  useEffect(() => {
    if (ele) {
      renderMathInElement(ele.current as HTMLElement, options);
    }
  }, []);

  return renderMaths;
};
