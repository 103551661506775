import { Box } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import he from 'he';
import { useKatex } from 'hooks/useKatex';
import React from 'react';

interface Props {
  question: string;
  questionNum: string;
}

const Question: React.FC<Props> = ({ question, questionNum }) => {
  const ele = React.createRef<HTMLDivElement>();
  useKatex(ele);
  return (
    <Box lineHeight={'19px'} fontSize={'14px'} letterSpacing={'1.12px'}>
      <Box
        ref={ele}
        as={'span'}
        fontWeight={'600'}
        mr={'3px'}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            `Question ${questionNum}: ${he.decode(question)}`,
          ),
        }}
      />
      {/* {` ${question}`} */}
    </Box>
  );
};

export default Question;
