import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Heading,
  Box,
  Image,
  Text,
  Textarea,
  HStack,
  Button,
  CircularProgress,
  CircularProgressLabel,
} from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  MAX_USER_MESSAGE_COUNT,
  TYPE_AI_MESSAGE,
  TYPE_USER_MESSAGE,
  incrementUserMessageCount,
  setSelectedLesson,
} from './aiTeacherSlice';
import {
  useLazyCreateHistoryQuery,
  useLazyGetHistoryQuery,
} from 'app/services/api';

const one = 'https://cdn.valearnis.com/backgrounds/ai/acient-ruins.svg';
const two = 'https://cdn.valearnis.com/backgrounds/ai/mount-fuji.png';
const three = 'https://cdn.valearnis.com/backgrounds/ai/natural.svg';
const four = 'https://cdn.valearnis.com/backgrounds/ai/tokyotower.png';
const aiTeacher = 'https://cdn.valearnis.com/backgrounds/ai/robot-ai.png';
import { getRandomInt } from './utils';
import FixPositionBackButton from 'components/buttons/FixPositionBackButton';

const backgrounds = [one, two, three, four];

const AITeacherPage: React.FC = () => {
  const [background, setBackground] = useState<string | undefined>();
  const [text, setText] = React.useState<string>('');
  const bottomMsgRef = useRef<HTMLDivElement>(null);

  const aiState = useAppSelector((state) => state.aiTeacher);
  const [selectedO] = useState<string | undefined>();
  const [isSending, setIsSending] = useState<boolean>(false);

  const userMessageCount = useAppSelector(
    (state) => state.aiTeacher.userMessageCount,
  );
  const [createHistory] = useLazyCreateHistoryQuery();
  const [getHistory, { data: historyData }] = useLazyGetHistoryQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getHistory();
    console.log(historyData);
  }, []);

  useEffect(() => {
    if (bottomMsgRef.current) {
      bottomMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [aiState.messages]);

  const handleSendMessage = async () => {
    try {
      if (text !== '') {
        setIsSending(true);
        createHistory({
          history_type: 'Short',
          question: text,
        });
        setText('');
        dispatch(incrementUserMessageCount());
        setIsSending(false);
      }
    } catch (error) {
      alert('An error occurred while sending the message. Please try again.');
      setIsSending(false);
    }
  };

  useEffect(() => {
    const randomInt = getRandomInt(0, 3);
    setBackground(backgrounds[randomInt]);
  }, []);

  const AiMessageBubble = (message: string, discription: string) => {
    return (
      <Box
        height={'auto'}
        display={'flex'}
        key={message}
        alignItems={'flex-start'}
        flexDir={'column'}
        padding={2}
      >
        <Box
          marginLeft={'3.3rem'}
          bgColor={'green'}
          maxW={'sm'}
          padding={2}
          borderRadius={4}
          marginRight={4}
        >
          <Text margin={0} fontWeight={'semibold'} fontSize={'sm'}>
            {message}
          </Text>
          <Text fontWeight={'light'} fontSize={'sm'}>
            {discription}
          </Text>
        </Box>
        <Box marginTop={-2}>
          <Image src={aiTeacher} height={'3.5rem'} />
        </Box>
      </Box>
    );
  };

  const ResponseBubble = (
    message?: string,
    selected?: string,
    error?: string,
  ) => {
    return (
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        flexDir={'column'}
        marginTop={2}
        marginBottom={2}
        marginStart={3}
        key={message ?? '-1'}
      >
        <Box
          marginLeft={4}
          bgColor={'brand.800'}
          maxW={'xs'}
          padding={2}
          borderRadius={4}
        >
          <Text
            margin={0}
            fontSize={'sm'}
            fontWeight={'semibold'}
            size='sm'
            lineHeight={1}
          >
            {message}
          </Text>
          {selected && selected.length > 1 ? (
            <Text marginTop={1} fontSize={'sm'} size='sm'>
              Selected : {selected}
            </Text>
          ) : null}
          <div ref={bottomMsgRef} />
        </Box>

        {error ? <Text colorScheme='error'>{error}</Text> : null}
      </Box>
    );
  };

  const ResponseInputBubble = (options: string[]) => {
    return (
      <Box
        display={'flex'}
        alignItems={'flex-end'}
        flexDir={'column'}
        width={'100%'}
      >
        <HStack width={'100%'} marginBottom={1}>
          {options.map((o) => {
            return (
              <Button
                key={o}
                size='sm'
                borderRadius={3}
                paddingLeft={3}
                paddingRight={3}
                paddingTop={1}
                paddingBottom={1}
                display={'flex'}
                justifyContent={'center'}
                alignContent={'center'}
                flex={0.25}
                bgColor={selectedO === o ? 'brand.700' : 'brand.800'}
                onClick={() => {
                  dispatch(setSelectedLesson(o));
                  navigate('/ai-lesson');
                }}
                textStyle={{ fontSize: 'xs' }}
              >
                {o}
              </Button>
            );
          })}
        </HStack>
        <Box bgColor={'brand.500'} padding={2} borderRadius={4} width={'100%'}>
          <HStack>
            <CircularProgress
              value={(userMessageCount / MAX_USER_MESSAGE_COUNT) * 100}
              color='red'
              thickness='15px'
            >
              <CircularProgressLabel>
                {MAX_USER_MESSAGE_COUNT - userMessageCount}
              </CircularProgressLabel>
            </CircularProgress>
            <Textarea
              variant='flushed'
              placeholder='Please type your message here'
              resize={'none'}
              size='sm'
              flex={0.9}
              value={text}
              onChange={(e) => {
                setText(e.target.value ?? '');
              }}
              disabled={isSending}
            />
            <Button
              flex={0.1}
              size='sm'
              paddingTop={'8.1px'}
              onClick={handleSendMessage}
              isDisabled={userMessageCount >= MAX_USER_MESSAGE_COUNT}
            >
              Send
            </Button>
          </HStack>
        </Box>
      </Box>
    );
  };

  return (
    <Flex
      pos={'relative'}
      align={'center'}
      justify={'flex-start'}
      flexDir={'column'}
      wrap={'wrap'}
      w={'100vw'}
      minH={'100vh'}
      color={'#FFF'}
      bgColor={'brand.800'}
      bgGradient={'linear(brand.700, brand.800)'}
      bgPos={'inherit'}
      bgSize={'cover'}
      bgRepeat={'no-repeat'}
      style={background ? { backgroundImage: `url(${background})` } : {}}
      overflow={'hidden'}
    >
      <FixPositionBackButton />
      <Heading as={'h1'} fontSize={'22px'} m={'120px 0 30px'}>
        Ai Teacher
      </Heading>

      <Box
        maxWidth={'md'}
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        height={'74vh'}
      >
        <Box height={'51vh'} overflow={'auto'}>
          {aiState.messages?.map((m) => {
            if (m.type === TYPE_AI_MESSAGE) {
              return AiMessageBubble(m.title, m.discription);
            } else if (m.type === TYPE_USER_MESSAGE) {
              return ResponseBubble(m.question);
            }
            return null;
          })}
        </Box>
        {ResponseInputBubble(['Maths', 'Science', 'Humanities', 'English'])}
      </Box>
    </Flex>
  );
};

export default AITeacherPage;
