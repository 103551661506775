import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ChevronLeft = createIcon({
  displayName: 'ChevronLeft',
  viewBox: '0 0 7.51 11.26',
  path: (
    <path
      data-name='Previous'
      fill='none'
      stroke='#fff'
      strokeWidth='1.5'
      strokeLinecap='round'
      d='M6.75.76l-5.6 4.9 5.6 4.85'
    />
  ),
});

export default ChevronLeft;
