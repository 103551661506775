import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';

interface Props {
  totalLevelExp: number;
  expToLevel: number;
}

const ExperienceBar: React.FC<Props> = ({ totalLevelExp, expToLevel }) => {
  const { level } = useAppSelector((state) => state.user);
  return (
    <Box textAlign={'center'}>
      <Box
        w={['100%', '80%', '60%', '40%']}
        m={'30px auto 20px'}
        bgColor={'#bec5e2'}
        borderRadius={'10px'}
      >
        <Box
          role={'innerBar'}
          w={((totalLevelExp - expToLevel) / totalLevelExp) * 100 + '%'}
          color={'gold'}
          borderTop={'20px solid'}
          m={'0'}
          borderRadius={'10px'}
        />
      </Box>

      <Text fontSize={'14px'} letterSpacing={'1.12'}>
        Level {level}
      </Text>
    </Box>
  );
};

export default ExperienceBar;
