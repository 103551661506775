import React from 'react';
import { Button, Link, Text } from '@chakra-ui/react';

const RegisterConsentMessage: React.FC = () => {
  return (
    <Text>
      By registering, I agree to the{' '}
      <Button
        as={Link}
        variant={'link'}
        href='https://cdn.valearnis.com/docs/terms-of-service.pdf'
        isExternal
      >
        Terms of Service
      </Button>{' '}
      and{' '}
      <Button
        as={Link}
        variant={'link'}
        href='https://cdn.valearnis.com/docs/privacypolicy.pdf'
        isExternal
      >
        Privacy Agreement
      </Button>
      .
    </Text>
  );
};

export default RegisterConsentMessage;
