import { Box, CircularProgress, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  text?: string;
}

const LoadingCircle: React.FC<Props> = ({ text = 'Loading...' }) => {
  return (
    <Box>
      <Text fontSize={'20px'}>{text}</Text>
      <CircularProgress isIndeterminate />
    </Box>
  );
};

export default LoadingCircle;
