import { Box, Flex, Heading } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import ZoomButton from 'features/lessons/templates/zoomedImage/ZoomButton';

import React from 'react';
import { Styles } from 'utils/customTypes';

interface Props {
  option: string;
  imgPath: string;
}

const imgStyles: Styles = {
  width: '100%',
  borderRadius: '10px',
  zIndex: '0',
};

const innerBoxStyles: Styles = {
  borderRadius: '0 0 10px 10px',
  bgColor: 'brand.500',
  position: 'absolute',
  bottom: '0',
  zIndex: '1',
  w: '100%',
  h: '35px',
  alignItems: 'center',
  justify: 'center',
};

const PictureChoiceInner: React.FC<Props> = ({ option, imgPath }) => {
  return (
    <Box>
      <Image src={imgPath} {...imgStyles} />
      <ZoomButton imgPath={imgPath} />
      <Flex {...innerBoxStyles}>
        <Heading as={'h1'} fontSize={'1rem'}>
          {option}
        </Heading>
      </Flex>
    </Box>
  );
};

export default PictureChoiceInner;
