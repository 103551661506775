import { Input } from '@chakra-ui/input';
import { Box, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/react';
import { setStudentName } from 'features/userProfile/userSlice';
import React from 'react';
import { useDispatch } from 'react-redux';

type ProfileFormProps = {
  mode: string;
  isStudent?: boolean;
  name?: string;
  setName?: React.Dispatch<React.SetStateAction<string>>;
  setYear?: React.Dispatch<React.SetStateAction<number>>;
};

export default function ProfileForm({
  mode,
  isStudent,
  name,
  setName,
  setYear,
}: ProfileFormProps) {
  // const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  // const [profileName, setProfileName] = useState(
  //   mode === 'create' ? '' : isStudent ? user.studentName : user.userName,
  // );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setName) setName(e.target.value);
    dispatch(setStudentName(e.target.value));
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (setYear) setYear(parseInt(e.target.value));
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='start'
      alignItems='start'
      as='form'
    >
      <Text fontSize='lg'>Profile name:</Text>
      <Input
        height='50px'
        focusBorderColor='white'
        backgroundColor='rgba(0,0,0,0.3)'
        placeholder='Profile name'
        value={name}
        onChange={(e) => handleChange(e)}
        disabled={
          (mode === 'edit' && !isStudent) || (mode === 'create' && isStudent)
        }
      />
      {mode === 'create' && (
        <>
          <Text fontSize='lg'>Profile year level:</Text>
          <Select
            height='50px'
            data-testid='schoolYear'
            focusBorderColor='white'
            backgroundColor='rgba(0,0,0,0.3)'
            placeholder='Select year level'
            onChange={(e) => handleYearChange(e)}
          >
            <option style={{ color: 'black' }} value='7'>
              7
            </option>
            <option style={{ color: 'black' }} value='8'>
              8
            </option>
            <option style={{ color: 'black' }} value='9'>
              9
            </option>
          </Select>
        </>
      )}
    </Box>
  );
}
