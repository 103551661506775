import React from 'react';
import { Box, Flex, Heading, Text, Button, Input } from '@chakra-ui/react';

import DashboardLayout from 'layout/DashboardLayout';
import PaddedContainer from 'components/containers/PaddedContainer';
import CloseButton from 'components/nav/CloseButton';

const inputStyles = {
  bg: '#FFFFFF',
  color: '#243D9D',
  opacity: '65%',
  borderRadius: '5px',
  fontSize: '14px',
  w: '100%',
  mb: '10px',
};

const ResetStudentPassword: React.FC = () => {
  return (
    <DashboardLayout showHeader={false}>
      <CloseButton />
      <PaddedContainer
        text-align={'center'}
        height={'73vh'}
        padding={'60px 30px'}
      >
        <Flex
          h={'100%'}
          flexDir={'column'}
          justifyContent={'space-around'}
          align={'center'}
        >
          <Heading fontSize={'18px'} mb={'30px'}>
            Reset Password
          </Heading>
          <Flex flexDir={'column'} textAlign={'left'} align={'center'}>
            <Text fontSize={'14px'} mt={'0'} w={'80%'}>
              Your new password must differ from all previous passwords.
            </Text>
            <Box w={'80%'} textAlign={'left'}>
              <Text fontSize={'14px'}>Password</Text>
              <Input {...inputStyles} />
            </Box>
            <Box w={'80%'} textAlign={'left'}>
              <Text fontSize={'14px'}>Confirm Password</Text>
              <Input {...inputStyles} />
            </Box>
            <Button
              mt={'40px'}
              h={'40px'}
              fontSize={'14px'}
              w={'190px'}
              bg={'button.100'}
            >
              Reset Password
            </Button>
          </Flex>
        </Flex>
      </PaddedContainer>
    </DashboardLayout>
  );
};

export default ResetStudentPassword;
