import React from 'react';
import { Button, Flex, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import {
  useUpdateStudentSettingsMutation,
  useUpdateGuardianSettingsMutation,
} from 'app/services/api';
import { Formik, Form } from 'formik';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import FormInput from 'components/forms/FormInput';
import * as Yup from 'yup';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';

const ChangePasswordPage: React.FC = () => {
  const auth = useAppSelector((state) => state.auth);
  const isStudent = auth.user.type === 'STUDENT';
  const { addNotification } = useActions();
  const navigate = useNavigate();
  const initialFormValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const navigateBack = (): void => {
    navigate(-1);
  };
  const UpdateStudentSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required('This field is required')
      .min(8, 'Must be at least 8 characters')
      .max(255, 'Must be at most 255 characters'),
    newPassword: Yup.string()
      .required('This field is required')
      .min(8, 'Must be at least 8 characters')
      .max(255, 'Must be at most 255 characters'),
    confirmPassword: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
  });

  const [updateStudentSettings] = useUpdateStudentSettingsMutation();
  const [updateGuardianSettings] = useUpdateGuardianSettingsMutation();
  return (
    <Flex
      pos={'relative'}
      align={'center'}
      justify={'center'}
      flexDir={'column'}
      wrap={'wrap'}
      w={'100vw'}
      minH={'100vh'}
      color={'#FFF'}
      bgColor={'brand.800'}
      bgGradient={'linear(brand.700, brand.800)'}
      bgPos={'bottom'}
      bgSize={'cover'}
    >
      <div
        style={{
          position: 'fixed',
          top: '10px',
          left: '10px',
        }}
      >
        <Button
          colorScheme='teal'
          variant='solid'
          size='md'
          onClick={navigateBack}
        >
          Go Back
        </Button>
      </div>
      <Heading as={'h1'} fontSize={'22px'} m={'120px 0 30px'}>
        Change Password
      </Heading>
      <Formik
        initialValues={initialFormValues}
        validationSchema={UpdateStudentSchema}
        onSubmit={async (values, actions) => {
          try {
            const updatedStudent = {
              app_user: {
                password: values.newPassword,
              },
              password: values.oldPassword,
            };

            const updatedGuardian = {
              app_user: {
                password: values.newPassword,
              },
              password: values.oldPassword,
            };

            let result = null;
            if (isStudent === false) {
              result = await updateGuardianSettings(updatedGuardian).unwrap();
            }
            if (isStudent === true) {
              result = await updateStudentSettings(updatedStudent).unwrap();
            }
            // TODO: Check username is available first
            if (result.status !== 200) {
              throw new Error('Something went wrong');
            }
            // TODO: Handle errors properly
            // TODO: Improve type checking
            addNotification('account details updated');
            navigate('/account', {
              replace: true,
              state: {
                message: 'User updated',
              },
            });
          } catch (err: any) {
            addNotification('Password is incorrect');
            console.log('Something went wrong', err);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(props) => (
          <Flex as={Form} flexDir={'column'} justify={'center'}>
            <FormFieldContainer isRequired flex={['1 1 100%', '0 1 47%']}>
              <FormInput
                name={'oldPassword'}
                label={'Old Password'}
                value={props.values.oldPassword}
                type='password'
                data-private
              />
            </FormFieldContainer>
            <FormFieldContainer isRequired>
              <FormInput
                label='New Password'
                name='newPassword'
                type='password'
                value={props.values.newPassword}
                data-private
              />
            </FormFieldContainer>
            <FormFieldContainer isRequired>
              <FormInput
                label='Confirm Password'
                name='confirmPassword'
                type='password'
                data-private
                value={props.values.confirmPassword}
              />
            </FormFieldContainer>
            <Button
              id='reset-password-button'
              type='submit'
              m={'58px auto 101px'}
              disabled={props.isSubmitting}
            >
              Change Password
            </Button>
          </Flex>
        )}
      </Formik>
    </Flex>
  );
};

export default ChangePasswordPage;
