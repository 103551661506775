import React from 'react';
import { Button, Box, Image } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useActions } from 'hooks/useActions';

const LargeImage: React.FC = () => {
  const image = useAppSelector((state) => state.largeImage.imgPath);
  const { toggleLargeImage } = useActions();
  return (
    <Box
      position={'absolute'}
      w={'100%'}
      h={'%60'}
      textAlign={'center'}
      left={'0'}
      right={'0'}
      top={'20%'}
      bottom={'20%'}
      m={'auto'}
      zIndex={'2'}
      bgGradient={'linear(#5A99F7, #4560C3, #243D9D)'}
      borderRadius={'10px'}
    >
      <Image src={image} w={'90%'} h={'70%'} m={'5%'} />
      <Button
        onClick={() => {
          toggleLargeImage();
        }}
      >
        Close
      </Button>
    </Box>
  );
};

export default LargeImage;
