export const introContent = [
  {
    id: 'Geography',
    title: 'Geography Introduction',
    content:
      'Geography is a fascinating social science subject that explores the elements, features, inhabitants and changes of the Earth. At its core, geography is about understanding the relationship between people and the environment. With the rapid rise in globalisation and international mobility, geographical literacy is as crucial as ever. By studying geography, students can gain an understanding of the interconnectedness of the world, develop critical thinking skills and become informed global citizens.',
  },
  {
    id: 'History',
    title: 'History Introduction',
    content:
      'History is a captivating subject that takes students on a journey through time, allowing them to explore past societies, ideologies and cultures. Our History curriculum is designed to guide students through pivotal events in human history, explain how modern societies function, and ignite their curiosity and imagination. By studying history, students can understand themselves and others better, gain insights into the complex issues facing the world today, and develop a deep appreciation for the diversity of human experience.',
  },
  {
    id: 'Economics',
    title: 'Business and Economics Introduction',
    content:
      "Businesses are the backbone of Australia's economic prosperity and high living standards. The study of Economics and Business is an essential part of understanding the production, allocation and consumption of resources and goods, with reference to finance, politics and law. Our Economics and Business curriculum encourages students to become enterprising and informed global citizens, equipped with the skills and knowledge they need to navigate the complexities of the modern business world.",
  },
  {
    id: 'Civics',
    title: 'Civics and Citizenship Introduction',
    content:
      'Laws are the foundation of human civilisation - they define the rules and standards of acceptable behaviour in society. Our Civics and Citizenship curriculum seeks to inform students of the Australian legal system, values and beliefs, and enable them to become active and democratic global citizens. By studying civics and citizenship, students can gain a deeper understanding of the responsibilities and privileges of citizenship, develop critical thinking and problem-solving skills, and learn how to engage meaningfully with the world around them.',
  },
  {
    id: 'Physics',
    title: 'Physics Introduction',
    content:
      'Physics is a fascinating field of study that focuses on understanding the physical universe and the phenomena that govern it. Students who study physics will delve into topics such as energy, equations, force, gravity, electricity, magnetism, sound, light, radiation, and scientific modelling. By studying physics, students can gain a deeper understanding of the natural world, develop critical thinking and problem-solving skills, and prepare for a wide range of exciting and challenging careers.',
  },
  {
    id: 'Chemistry',
    title: 'Chemistry Introduction',
    content:
      'Chemistry is an intriguing subject that explores the nature of matter and the interactions between different substances. Topics include the study of chemical formulas and reactions, valency, ionisation, isotopes, mixtures, atoms, molecules, bonding, and periodic elements. By studying chemistry, students can gain a deeper understanding of the fundamental principles that govern the natural world, develop problem-solving skills and prepare for a wide range of fascinating careers.',
  },
  {
    id: 'E.Science',
    title: 'Earth Science Introduction',
    content:
      'Earth science is a captivating subject that explores the processes that shape our planet and the forces that drive them. Students who study earth science will attain an understanding of geological processes, weather, climate, volcanology, planetary science, astronomy, seismology, palaeontology, renewable energy, and climate change. By studying earth science, students can develop critical thinking and problem-solving skills, gain a deeper appreciation of the natural world, and prepare for a wide range of exciting and rewarding careers.',
  },
  {
    id: 'Biology',
    title: 'Biology Introduction',
    content:
      'Biology is a vast and complex field of study that seeks to understand the nature of life itself. Within the field of biological sciences, your students will encounter taxonomy, classification of life, speciation, evolution, cell analysis & structure, organs, reproduction, and the basis of human biology. By studying biology, students can gain a deeper understanding of the complex systems that govern life, develop critical thinking and problem-solving skills, and prepare for a wide range of fascinating and challenging careers.',
  },
  {
    id: 'Numbers',
    title: 'Number and Algebra Introduction',
    content:
      "Unlock the secrets of numbers and equations with our Number and Algebra course! This exciting program will teach your students the essential laws of arithmetic and the art of working with algebraic expressions. They'll explore the fascinating world of negative numbers, rational and irrational numbers, and even learn how to convert between fractions, decimals, and percentages. With our expert guidance, your students will build algebraic intuition and number sense, and learn how to model real-life situations mathematically.",
  },
  {
    id: 'Statistics',
    title: 'Statistics Introduction',
    content:
      "Get ready to crunch some numbers with our Statistics course! Your students will learn about the many types of data that exist in the world around them and how to interpret that data in useful ways. They'll dive into data sets, interpret graphs, and master key statistical concepts such as mean, median, mode, and range. And that's not all! Our course also introduces students to probability theory and the fascinating world of independent and dependent events. With our fun and interactive program, your students will become statistical wizards in no time!",
  },
  {
    id: 'Geometry',
    title: 'Geometry Introduction',
    content:
      "Take your students on a journey through the world of shapes and space with our Geometry course! They'll learn how to identify and measure angles, classify triangles and quadrilaterals, and even master the Pythagorean theorem. With our expert guidance, your students will build an understanding of the different kinds of geometric relationships that exist and learn how to model and solve problems geometrically. They'll also explore surface area, volume, and even get an introduction to trigonometry. It's a world of possibilities with our Geometry course!",
  },
  {
    id: 'Language',
    title: 'Language Introduction',
    content:
      "Unlock the power of English with our Language curriculum! At Valearnis, we're passionate about the linguistic structure and elements of Australian English, and we're committed to developing your students' confidence and accuracy in creating English communicative pieces. Our program is designed to foster a love of language and help students appreciate the intricacies of English. Your students will develop a deep understanding of the nuances of the English language, and learn how to use it with clarity and precision.",
  },
  {
    id: 'Literacy',
    title: 'Literacy Introduction',
    content:
      "Explore the richness of language with our Literacy curriculum! Our program focuses on developing your students' ability to listen to, read, write, and speak English in all its forms, whether it's oral, print, visual, or digital. We're committed to helping your students become confident and effective communicators, with the skills they need to interpret and create English communicative pieces. With our engaging and interactive curriculum, your students will develop a love of language that will last a lifetime!",
  },
  {
    id: 'Literature',
    title: 'Literature Introduction',
    content:
      'Dive into the world of literature with our Literature curriculum! Our program delves deep into the literary works of renowned authors to dissect the use of language, explore literary aesthetics, evaluate perspectives, and challenge interpretations. Your students will learn how to analyze and interpret complex texts, and develop their own unique perspectives on the world of literature. With our program, your students will become independent, informed, and creative users of English, with a deep appreciation for the power of language to shape our world.',
  },
];
