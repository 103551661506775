import { createSlice } from '@reduxjs/toolkit';

export interface IAccountDetails {
  id: number;
  is_active?: boolean;
  name: string;
  username: string;
  year: number;
}

interface InitialState {
  accounts: IAccountDetails[];
}

const initialState: InitialState = {
  accounts: [],
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
});

// export const {} = accountSlice.actions;

export default accountSlice.reducer;
