import { Box, Flex, Heading } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import Portrait from './Portrait';
import SelectionButton from './SelectionButton';

interface Props {
  isLoading: boolean;
}

const PortraitSide: React.FC<Props> = () => {
  const { tempCharacterChoice } = useAppSelector((state) => state.user);

  return (
    <>
      <Flex
        width={['90%', '65%', '75%']}
        h={['50%', '100%']}
        justifyContent={'center'}
        flexDir={['column', 'initial']}
      >
        <Portrait />
      </Flex>
      <Flex
        flexDir={'column'}
        w={['100%', '35%']}
        h={'70%'}
        textAlign={['center', 'left']}
        alignItems={'baseline'}
      >
        <Heading
          fontSize={['0.9rem', '1.1rem']}
          fontWeight={'bold'}
          letterSpacing={'0.1rem'}
          m={'20px 0'}
        >
          {tempCharacterChoice.name}
        </Heading>
        <Box
          width={'100%'}
          height={'0px'}
          borderTop={'1px'}
          borderRadius={'10px'}
          bgColor={'#fff'}
        ></Box>
        <Flex
          m={'30px 0'}
          h={'100%'}
          flexDir={'column'}
          textAlign={'left'}
          justifyContent={'space-between'}
          alignItems={'baseline'}
          pr={'10px'}
          overflowY={'auto'}
        >
          {tempCharacterChoice.description}
        </Flex>
        <SelectionButton />
      </Flex>
    </>
  );
};

export default PortraitSide;
