import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'app/services/api';
// import { api } from 'app/services/api';

// individual student progress information

export interface Reports {
  subjects: {
    [subject: string]: Subject;
  };
  topics: {
    [subject: string]: Topic;
  };
}

export interface Subject {
  id: string;
  average: number;
  name: string;
  times_studied: number;
}

export interface Topic {
  id: string;
  subject: string;
  average: number;
  name: string;
}

export interface SubjectList {
  [key: string]: Subject;
}

interface Summaries {
  [key: string]: string[];
}

const subjectsTopics: Summaries = {
  Science: ['Physics', 'Chemistry', 'E.Science', 'Biology'],
  Maths: ['Numbers', 'Geometry', 'Statistics'],
  HASS: ['Geography', 'Economics', 'History', 'Civics'],
  English: ['Literacy', 'Literature', 'Language'],
};

// mock state - real info will be loaded from backend

interface FirstState {
  isLoading: boolean;
  subject: string;
  module: string;
  moduleCode: string;
  quizInProgress: number;
  quizList: any;
  userLevel: number;
  subjectsTopics: Summaries;
  reports: Reports;
  menuItems: any;
  isModuleSet: boolean;
  fetchingLessons: boolean;
}

const initialState: FirstState = {
  isLoading: true,
  subject: '',
  module: '',
  moduleCode: '',
  quizInProgress: 0,
  quizList: {},
  userLevel: 3,
  subjectsTopics,
  reports: {} as Reports,
  menuItems: {},
  isModuleSet: true,
  fetchingLessons: true,
};

const studentProgress = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setSubject: (state, { payload }: PayloadAction<string>) => {
      state.subject = payload.split('/')[2];
    },
    setModule: (state, { payload }: PayloadAction<any>) => {
      state.module = payload.to;
      state.moduleCode = String(payload.code);
    },
    setQuizInProgress: (state, { payload }: PayloadAction<number>) => {
      state.quizInProgress = payload;
    },
    resetSelections: (state) => {
      state.quizList = {};
      state.quizInProgress = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addMatcher(api.endpoints.getSubject.matchPending, (state) => {
      //   state.isModuleSet = false;
      // })
      // .addMatcher(api.endpoints.getTopic.matchFulfilled, (state) => {
      //   state.isModuleSet = true;
      // })
      .addMatcher(
        api.endpoints.getLessonsForTopic.matchFulfilled,
        (state, { payload }) => {
          state.quizList = payload.lessons;
          state.fetchingLessons = false;
        },
      )
      .addMatcher(api.endpoints.getLessonsForTopic.matchPending, (state) => {
        state.fetchingLessons = true;
      })
      .addMatcher(
        api.endpoints.getAllReports.matchFulfilled,
        (state, { payload }) => {
          state.reports = payload;
        },
      );
  },
});
export const progressActions = studentProgress.actions;
export const { setModule, setSubject, setQuizInProgress, resetSelections } =
  studentProgress.actions;

export default studentProgress.reducer;
