import { Stack, Avatar, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router';
import plusSign from '../../assets/plus-sign.svg';

export default function ChooseUserAddProfile() {
  const navigate = useNavigate();
  const handleAddProfile = () => {
    navigate('/account/create-profile');
  };
  return (
    <>
      <Stack spacing={5} alignItems={'center'}>
        <Avatar
          size={'2xl'}
          src={plusSign}
          role='add-profile'
          name='profile-img'
          backgroundColor='rgba(255,255,255,0.1)'
          onClick={() => handleAddProfile()}
          cursor='pointer'
          _hover={{
            transform: 'scale(1.05, 1.05)',
            _after: {
              border: '5px solid rgba(255,255,255, 0.8)',
            },
          }}
          _after={{
            content: '""',
            position: 'absolute',
            inset: 0,
            border: '4px solid rgba(255,255,255,0)',
            borderRadius: '50%',
            transition:
              'border 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955)',
          }}
          transition='transform 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955)'
          css={{
            img: {
              objectFit: 'none',
            },
          }}
        />
        <Text
          fontSize='xl'
          fontWeight='semibold'
          color={'rgba(255,255,255,0.7)'}
        >
          Add profile
        </Text>
      </Stack>
    </>
  );
}
