import { Box } from '@chakra-ui/layout';
import React from 'react';
import PictureChoice from './PictureChoice';
import PictureChoiceInner from './PictureChoiceInner';
import { useRadioGroup } from '@chakra-ui/radio';
import { Styles } from 'utils/customTypes';
import { TransformedOption } from 'features/questions/questionsSlice';
import { Flex } from '@chakra-ui/react';
import { useActions } from 'hooks/useActions';

interface Props {
  images: TransformedOption[];
}

const innerBoxStyles: Styles = {
  role: 'choice',
  borderRadius: '10px',
  width: '48%',
  flexDir: 'row',
  display: 'inline-block',
  flex: 'wrap',
};

const PictureChoiceOptions: React.FC<Props> = ({ images }) => {
  const { selectAnswer } = useActions();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'picture',
    onChange: (choice) => selectAnswer(choice),
  });

  const group = getRootProps();
  const margins = ['0 2% 2% 0', '0 0 2% 2%', '2% 2% 0 0', '2% 0 0 2%'];
  // const margins = ['0 0 0 0', '0 0 0 0', '0 0 0 0', '0 0 0 0'];
  const optionChars = ['A', 'B', 'C', 'D'];

  return (
    <Box {...group}>
      {optionChars.map((value, index) => {
        const radio = getRadioProps({ value, enterKeyHint: 'done' });
        const imgPath = images[index].content;
        return (
          <Flex m={margins[index]} key={value} {...innerBoxStyles}>
            <PictureChoice radio={radio}>
              <PictureChoiceInner option={value} imgPath={imgPath} />
            </PictureChoice>
          </Flex>
        );
      })}
    </Box>
  );
};

export default PictureChoiceOptions;
