import { Box, Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import FormFieldContainer from 'components/forms/FormFieldContainer';
import FormInput from 'components/forms/FormInput';
import { Form, Formik } from 'formik';
import Main from 'layout/Main';
import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  usePasswordValidMutation,
  IPasswordValidRequest,
} from 'app/services/api';

type ForgotPinFormProps = {
  studentId: string | undefined;
};

export default function ForgotPinForm({ studentId }: ForgotPinFormProps) {
  const [error, setError] = useState('');
  const [passwordValid] = usePasswordValidMutation();

  const navigate = useNavigate();

  const forgotPinSchema = Yup.object().shape({
    password: Yup.string().required('This field is required'),
  });

  const handlePasswordValid = async (values: IPasswordValidRequest) => {
    const response = await passwordValid(values).unwrap();
    if (response.status === 200) {
      if (response.msg === 'Wrong password') {
        setError('Wrong password');
      } else {
        navigate('/account/profile-pin/', {
          state: { pin: response.msg, studentId: studentId },
          replace: true,
        });
      }
    }
  };

  function handleForgotPassword() {
    window.location.href = '/settings/change-password';
  }
  return (
    <Main
      w='100vw'
      minH='100vh'
      display='flex'
      flexFlow='column'
      alignItems='center'
      justifyContent='center'
      pt='0'
    >
      <Formik
        initialValues={{
          password: '',
          student_id: studentId === 'guardian' ? undefined : studentId,
        }}
        validationSchema={forgotPinSchema}
        onSubmit={async (values: IPasswordValidRequest) =>
          handlePasswordValid(values)
        }
      >
        {(props) => (
          <Box as={Form}>
            <Text fontSize='2xl' as='b'>
              Enter your password
            </Text>
            <Text fontSize='xs'>
              To retrieve your profile PIN, your account password is required.
            </Text>
            <FormFieldContainer>
              <FormInput
                id='password'
                type='password'
                name='password'
                value={props.values.password}
                data-private
              />
            </FormFieldContainer>
            {error && (
              <Text fontSize='xs' color='yellow.400' as='b'>
                {error}
              </Text>
            )}
            <Button
              width='100%'
              backgroundColor='brand.800'
              my='2rem'
              role='submit'
              type='submit'
            >
              CONTINUE
            </Button>
            <Text
              fontSize='sm'
              cursor='pointer'
              _hover={{ opacity: '0.8', textDecoration: 'underline' }}
              onClick={() => {
                handleForgotPassword();
              }}
            >
              Forgot Password?{' '}
            </Text>
          </Box>
        )}
      </Formik>
    </Main>
  );
}
