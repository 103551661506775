import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';

const radius = 40;
const circumference = 2 * (22 / 7) * radius; // C = 2 * pi * r

const progressStyle = css({
  position: 'relative',
  display: 'inline-block',
  textAlign: 'center',
  borderRadius: '50%',
  padding: '0 45px',
  '> svg': {
    transform: 'rotate(270deg)',
    width: '160px',
    height: '160px',
    margin: '0 auto',
    circle: {
      fill: 'none',
      strokeLinecap: 'round',
    },
    'circle:nth-of-type(1)': {
      strokeWidth: '5',
      stroke: '#b6b3cb',
    },
    'circle:nth-of-type(2)': {
      strokeWidth: '6',
      stroke: '#243D9D',
      strokeDasharray: circumference,
      strokeDashoffset: 1 * circumference,
      transition: 'stroke-dashoffset .5s linear',
    },
  },
});

const contentStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: '0',
  padding: '40px',
  backgroundColor: '#243D9D',
  borderRadius: '50%',
  img: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  '&:hover': {
    img: {
      display: 'none',
    },
    p: {
      display: 'block',
    },
  },
});

interface Props {
  percentage: number;
  icon: string;
}

/**
 * Circular Progress Bar component
 *
 * `stroke-dashoffset` should be equal to the gap that remains to be filled
 *  e.g. if progress is 75% then multiply the circumference by 0.25 (25%)
 */
const CircularProgressBar: React.FC<Props> = ({ percentage, icon }) => {
  const progress = (1 - percentage / 100) * circumference;

  return (
    <Box css={progressStyle}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 100 100'
        aria-labelledby='title'
        role='graphic'
      >
        <title id='title'>{`Progress bar (${percentage}%)`}</title>
        <circle cx='50' cy='50' r={radius}></circle>
        <circle
          cx='50'
          cy='50'
          r={radius}
          style={{ strokeDashoffset: progress }}
        ></circle>
      </svg>
      <Box css={contentStyle}>
        <img src={icon} width='42px' height='42px' />
        <Text
          pos={'absolute'}
          display={'none'}
          transform={'translate(-50%, -50%)'}
          m={'0'}
          fontSize={'1.25rem'}
          fontWeight={'600'}
          cursor={'default'}
        >
          {percentage}%
        </Text>
      </Box>
    </Box>
  );
};

export default CircularProgressBar;
