import React, { useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { Box, Heading, Text, Image, Button } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setChosenFaction, joinHouseFaction } from './FactionQuizSlice';
import { useUpdateStudentHouseMutation } from 'app/services/api';
import { useActions } from 'hooks/useActions';
import { useNavigate } from 'react-router';

interface FactionProps {
  id: string;
  name: string;
  description: string;
  borderImg: string;
  emblemImg: string;
  color: string;
  updateHouseHandler: (result: boolean) => any;
}
const FactionCard: React.FC<FactionProps> = ({
  id,
  name,
  description,
  borderImg,
  emblemImg,
  color,
  updateHouseHandler,
}) => {
  const [openedState, setOpenedState] = useState(false);
  const { recommendedFaction, selectedFaction } = useAppSelector(
    (state) => state.faction,
  );
  const { addNotification } = useActions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateStudentHouse] = useUpdateStudentHouseMutation();

  const selectFactionHandler = (faction: string) => {
    dispatch(setChosenFaction(faction));
  };

  const joinFaction = async (faction: string) => {
    const updatedStudent = {
      app_user: {},
      house: faction,
    };

    const result = await updateStudentHouse(updatedStudent).unwrap();
    if ('error' in result) {
      updateHouseHandler(false);
      setOpenedState(false);
      selectFactionHandler('');
    }
    selectFactionHandler(faction);
    dispatch(joinHouseFaction(faction));
    addNotification('Student House updated');
    navigate('/house-faction', {
      replace: true,
      state: {
        message: 'User updated',
      },
    });
  };

  return (
    <Box
      position={'relative'}
      opacity={selectedFaction === '' || selectedFaction === name ? '1' : '0'}
      id={id}
      display={
        selectedFaction === '' || selectedFaction === name ? 'flex' : 'none'
      }
      flexDir={'column'}
      justifyContent={'center'}
      alignContent={'center'}
      maxW={
        openedState
          ? ['90%', '60%', '50%', null, '40%', '40%']
          : ['80%', '70%', '50%', '25%']
      }
      m={['3rem 0', '3rem 0', '3rem 0', '0 1rem']}
      bgColor={
        selectedFaction === '' || selectedFaction === name
          ? 'rgba(26, 91, 146, 0.9)'
          : '#1a5b92'
      }
      boxShadow={`${recommendedFaction === id ? '0 0 20px yellow;' : null} `}
      borderRadius={'20px'}
      borderTop={`8px solid ${color}`}
      transition={' 0.3s'}
    >
      {openedState && (
        <Button
          position='absolute'
          top='0rem'
          left={['3%', '4%', '5%', '7%']}
          fontSize={'12px'}
          size={'sml'}
          backgroundColor={'#0096c7'}
          onClick={() => {
            setOpenedState(false);
            selectFactionHandler('');
          }}
          transition={' 0.3s '}
        >
          Back
        </Button>
      )}
      <Image
        position='absolute'
        top='-3rem'
        left={
          openedState
            ? ['26%', '31%', '33%', '34%', '35%']
            : ['20%', '20%', '25%', '25%', '25%']
        }
        width={
          openedState
            ? ['50%', '40%', '35%', '33%', '30%']
            : ['60%', '60%', '50%', '50%', '50%']
        }
        height={
          openedState
            ? ['30%', '35%', '40%', '45%', '45%', '50%']
            : ['45%', '45%', '45%', '40%']
        }
        src={emblemImg}
        alt='card-emblem'
        transition={' 0.3s'}
      />
      <Image
        position='absolute'
        top='-3rem'
        left={
          id === 'Flamespire'
            ? openedState
              ? ['-0.9rem', '-1.2rem', '-1.5rem', '-2rem', '-2.5rem']
              : ['-1rem', '-1.5rem', '-1.5rem', '-1.2rem']
            : openedState
            ? ['-1.6rem', '-1.9rem', '-2.3rem', '-2.6rem', '-2.8rem', '-4rem']
            : ['-1.5rem', '-2rem', '-2.2rem', '-1.5rem', '-2.1rem']
        }
        width='10%'
        height='70%'
        src={borderImg}
        alt='border-left'
        transition={' 0.3s '}
      />
      <Image
        position='absolute'
        top='-3rem'
        right={
          id === 'Flamespire'
            ? openedState
              ? [
                  '-0.9rem',
                  '-1.2rem',
                  '-1.5rem',
                  '-1.9rem',
                  '-2.1rem',
                  '-2.5rem',
                ]
              : ['-1rem', '-1.5rem', '-1.5rem', '-1.2rem']
            : openedState
            ? ['-1.6rem', '-1.9rem', '-2.3rem', '-2.6rem', '-2.8rem', '-4rem']
            : ['-1.5rem', '-2rem', '-2.2rem', '-1.5rem', '-2.1rem']
        }
        width='10%'
        height='70%'
        src={borderImg}
        alt='border-right'
        transform='scaleX(-1)'
        transition={' 0.3s '}
      />
      <Box
        display={'flex'}
        flexDir={'column'}
        justifyContent={'end'}
        alignContent={'center'}
        mb={'1rem'}
        p={'1.5rem 1.5rem 0 1.5rem '}
        h={['350px', '350px', '350px', '300px']}
      >
        <Heading
          fontSize={openedState ? ['1.4rem'] : ['0.9rem', '1rem', '1.1rem']}
          transition={' 0.3s '}
        >
          {name}
        </Heading>
        <Text
          fontSize={
            id === 'Flamespire'
              ? openedState
                ? ['0.8rem', '0.8rem', '0.8rem', '0.8rem', '0.8rem', '0.8rem']
                : '0.8rem'
              : openedState
              ? ['0.72rem', '0.8rem', '0.8rem', '0.78rem', '0.8rem', '0.8rem']
              : '0.8rem'
          }
          lineHeight={'1.4'}
          noOfLines={openedState ? 14 : 7}
          pr={'1rem'}
          transition={' 0.3s '}
        >
          {description}
        </Text>
      </Box>

      {openedState && (
        <button
          onClick={() => joinFaction(name)}
          style={{
            fontSize: '1.1rem',
            fontWeight: 'bolder',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            backgroundColor: '#0096c7',
            width: '100%',
            height: '50px',
            transition: '0.3s',
          }}
        >
          Join Faction
        </button>
      )}
      {!openedState && (
        <button
          onClick={() => {
            setOpenedState(true);
            selectFactionHandler(name);
          }}
          style={{
            fontSize: '1.1rem',
            fontWeight: 'bolder',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            backgroundColor: '#0096c7',
            width: '100%',
            height: '50px',
            transition: '0.3s',
          }}
        >
          {`See ${name} faction`}
        </button>
      )}
    </Box>
  );
};

export default FactionCard;
