import { Button } from '@chakra-ui/react';
import { Box } from '@chakra-ui/layout';
import React from 'react';

type ProfileFormButtonsProps = {
  mode: string;
  isStudent: boolean;
  onSubmit: () => void;
  onDelete?: () => void;
};

export default function ProfileFormButtons({
  mode,
  isStudent,
  onSubmit,
  onDelete,
}: ProfileFormButtonsProps) {
  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      style={{ gap: '5rem' }}
    >
      {mode === 'create' && (
        <Button m='16px' width='15rem' onClick={() => onSubmit()}>
          CREATE PROFILE
        </Button>
      )}

      {mode === 'edit' && (
        <>
          <Button m='16px' width='15rem' onClick={() => onSubmit()}>
            SUBMIT
          </Button>
          {isStudent && onDelete && (
            <Button
              width='10rem'
              m='16px'
              background='red.600'
              textColor='white'
              onClick={() => onDelete()}
            >
              DELETE
            </Button>
          )}
        </>
      )}
    </Box>
  );
}
