import { Box } from '@chakra-ui/layout';
import { RootState } from 'app/store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PinShowGroup from './PinShowGroup';
import ProfileDelete from './ProfileFormButtons';
import ProfileForm from './ProfileForm';
import {
  useUpdatePinMutation,
  useDeleteStudentMutation,
} from 'app/services/api';
import { resetStudentStates } from 'features/userProfile/userSlice';
import { useToast } from '@chakra-ui/react';

type ProfileEditFormProps = {
  isStudent: boolean;
  studentId: string | undefined;
};

export default function ProfileEditForm({
  isStudent,
  studentId,
}: ProfileEditFormProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const user = useSelector((state: RootState) => state.user);
  const [profileName, setProfileName] = useState(
    isStudent
      ? user.studentName
        ? user.studentName
        : ''
      : auth.user.name
      ? auth.user.name
      : '',
  );
  const [pin, setPin] = useState(
    user.profilePin !== '' ? user.profilePin.split('') : ['', '', '', ''],
  );
  const toast = useToast();

  const [updatePin] = useUpdatePinMutation();
  const [deleteStudent] = useDeleteStudentMutation();

  const handleSubmit = async () => {
    // submit profile
    console.log('submitting profile', pin, profileName);
    const pinUpdateResponse = await updatePin({
      pin: pin.join(''),
      student_id: studentId,
      name: profileName,
    }).unwrap();
    if (pinUpdateResponse.status && pinUpdateResponse.status === 200) {
      toast({
        title: profileName,
        description: "We've updated your PIN for you.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      dispatch(resetStudentStates());
      navigate('/account/choose-user', { replace: true });
    } else {
      toast({
        title: profileName,
        description: 'Error updating pin',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteProfile = async () => {
    if (!studentId) {
      toast({
        title: profileName,
        description: 'Error deleting profile',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } else {
      const deleteStudentResponse = await deleteStudent(studentId).unwrap();
      if (
        deleteStudentResponse.status &&
        deleteStudentResponse.status === 200
      ) {
        toast({
          title: isStudent ? user.studentName : user.userName,
          description: "We've deleted profile for you.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        dispatch(resetStudentStates());
        navigate('/account/choose-user', { replace: true });
      } else {
        toast({
          title: isStudent ? user.studentName : user.userName,
          description: 'Error delete profile',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box
      width='300px'
      margin='auto'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      style={{ gap: '2rem' }}
      marginTop='2rem'
    >
      <ProfileForm
        mode={'edit'}
        isStudent={isStudent}
        name={profileName ? profileName : ''}
        setName={setProfileName}
      />
      <PinShowGroup pin={pin} setPin={setPin} />
      <ProfileDelete
        mode={'edit'}
        isStudent={isStudent}
        onSubmit={handleSubmit}
        onDelete={handleDeleteProfile}
      />
    </Box>
  );
}
