import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/useAppSelector';

import LessonListItem from 'features/lessons/LessonListItem';
import { useEffect } from 'react';
import { useState } from 'react';
import LoadingCircle from 'components/icons/LoadingCircle';
import { useLazyGetLessonsForTopicQuery } from 'app/services/api';
import { findTopicId } from 'utils/arrayUtils';
import { useUrlParts } from 'hooks/useUrlParts';

const LessonsList: React.FC = () => {
  /* TODO: dynamically handle background images */
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const { quizList } = useAppSelector((state) => state.progress);
  const { user } = useAppSelector((state) => state.auth);
  const { schoolYear } = useAppSelector((state) => state.user);
  const { menuItems } = useAppSelector((state) => state.menu);

  const urlParts = useUrlParts();
  const subject = urlParts[1];
  const module = urlParts[2];

  const [getLessons, { isLoading }] = useLazyGetLessonsForTopicQuery();

  useEffect(() => {
    const topicId = findTopicId(menuItems, subject, module);
    getLessons({ code: topicId });
  }, []);

  const checkWindowSize = () => {
    setIsMobile(window.innerWidth <= 991);
  };

  useEffect(() => {
    window.addEventListener('resize', checkWindowSize);
  });
  return (
    <Flex
      m={'auto'}
      maxW={'1000px'}
      flexDirection={['column', null, null, 'row']}
      alignItems={['center', null, null, 'flex-start']}
      justify={'center'}
      wrap={'wrap'}
      pt={['5vh', null, '10vh']}
    >
      {isLoading ? (
        <LoadingCircle text={'Fetching Lessons!'} />
      ) : Object.keys(quizList).length === 0 ? (
        <Text>No quizzes available for this topic yet!</Text>
      ) : (
        Object.entries(quizList).map((item: any, index) => {
          const [quiz, content] = item;

          if (
            content.years.includes(Number(schoolYear)) ||
            user.type === 'EMPLOYEE'
          ) {
            return (
              <LessonListItem
                key={index}
                lesson={quizList[quiz]}
                mobile={isMobile}
              />
            );
          }
        })
      )}
    </Flex>
  );
};

export default LessonsList;
