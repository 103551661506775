export const QuizQuestions = [
  {
    question: 'You have a project due in a week. How do you approach it?',
    answers: [
      {
        answer:
          'Start working on it immediately, and make sure every detail is perfect to get the highest grade.',
        faction: 'Flamespire',
      },
      {
        answer:
          'Dive into research, eager to learn everything related to the project topic. You might only get started on the actual project closer to the date.',
        faction: 'Moonspark',
      },
      {
        answer:
          'Discuss the project with friends and seek their advice before getting started.',
        faction: 'Heartleaf',
      },
    ],
  },
  {
    question: 'Which activity do you prefer in your free time?',
    answers: [
      {
        answer: 'Diving into a good book or exploring an intriguing subject.',
        faction: 'Moonspark',
      },
      {
        answer: 'Setting personal challenges and goals to overcome.',
        faction: 'Flamespire',
      },
      {
        answer: 'Spending time with friends and family.',
        faction: 'Heartleaf',
      },
    ],
  },
  {
    question:
      'A friend has a different opinion on an important issue. What do you do?',
    answers: [
      {
        answer:
          "Investigate their point of view; it's a chance to learn something new.",
        faction: 'Moonspark',
      },
      {
        answer:
          'Try to understand their perspective in order to preserve your friendship.',
        faction: 'Heartleaf',
      },
      {
        answer:
          'As long as their difference of opinion doesn’t harm you, you aren’t very worried about it.',
        faction: 'Flamespire',
      },
    ],
  },
  {
    question: 'What is most important to you in a team?',
    answers: [
      {
        answer: 'A shared desire to learn as much as possible',
        faction: 'Moonspark',
      },
      {
        answer:
          'Striving for excellence and pushing each other to achieve more.',
        faction: 'Flamespire',
      },
      {
        answer: 'Trustworthiness and a strong sense of community.',
        faction: 'Heartleaf',
      },
    ],
  },
  {
    question: 'How do you react when you face a problem?',
    answers: [
      {
        answer: 'You see it as an opportunity to learn something new.',
        faction: 'Moonspark',
      },
      {
        answer:
          'You face it head-on, even if you fail you still gain personal growth.',
        faction: 'Flamespire',
      },
      {
        answer: 'You ask your friends for help and try to solve it together.',
        faction: 'Heartleaf',
      },
    ],
  },
  {
    question: 'What is your preferred way of learning new things?',
    answers: [
      {
        answer:
          'By researching, asking questions, and satisfying your intellectual curiosity.',
        faction: 'Moonspark',
      },
      {
        answer: 'Setting challenging goals and pushing yourself to meet them.',
        faction: 'Flamespire',
      },
      {
        answer: 'Collaborating and learning with others.',
        faction: 'Heartleaf',
      },
    ],
  },
  {
    question: "When you succeed in something, what's your first thought?",
    answers: [
      {
        answer: "You're eager to dissect the factors that led to your success.",
        faction: 'Moonspark',
      },
      {
        answer:
          "You're glad that your hard work and dedication paid off, but you can achieve more.",
        faction: 'Flamespire',
      },
      {
        answer:
          "You can't wait to share the news with your friends and celebrate together.",
        faction: 'Heartleaf',
      },
    ],
  },
  {
    question: 'What would you do if you see a friend making a mistake?',
    answers: [
      {
        answer:
          'See it as a teaching moment and help them understand the correct way.',
        faction: 'Moonspark',
      },
      {
        answer: "Support them and assure them it's okay to make mistakes.",
        faction: 'Heartleaf',
      },
      {
        answer: 'Encourage them to try harder, learn from their mistakes.',
        faction: 'Flamespire',
      },
    ],
  },
  {
    question: 'What kind of role do you usually take in a group project?',
    answers: [
      {
        answer:
          'The one who eagerly delves into the details and shares interesting findings with the group.',
        faction: 'Moonspark',
      },
      {
        answer:
          'The one who sets high standards and encourages the team to meet them.',
        faction: 'Flamespire',
      },
      {
        answer:
          'The one who ensures everyone gets along and works together effectively.',
        faction: 'Heartleaf',
      },
    ],
  },
  {
    question: 'If you could have any superpower, what would it be?',
    answers: [
      {
        answer: 'The power to know and understand anything you want.',
        faction: 'Moonspark',
      },
      {
        answer: 'The power to bring people together and create harmony.',
        faction: 'Heartleaf',
      },
      {
        answer: 'The power to achieve any goal you set your mind to.',
        faction: 'Flamespire',
      },
    ],
  },
];
