import React, { useEffect, useState } from 'react';
import PaddedContainer from 'components/containers/PaddedContainer';
import { Button, Input, Text, Flex } from '@chakra-ui/react';
import {
  useLazyResendOtpQuery,
  useVerifyGuardianMutation,
} from 'app/services/api';
import { useNavigate } from 'react-router-dom';
import { useActions } from 'hooks/useActions';
import InstructionsTooltip from 'features/instructions/InstructionsTooltip';

const OtpInputPage: React.FC = () => {
  const [otp, setOtp] = useState(0);
  const [verifyGuardian] = useVerifyGuardianMutation();
  const navigate = useNavigate();
  const { verifyEmail, addNotification } = useActions();
  const [resendOtp, { isSuccess }] = useLazyResendOtpQuery();

  useEffect(() => {
    if (isSuccess) {
      addNotification('Code resent to your registered email address.');
    }
  }, [isSuccess]);

  return (
    <PaddedContainer
      maxW={'35rem'}
      minH={'auto'}
      padding={['1.75rem 1.875rem', '1.75rem 3.625rem']}
      text-align={'center'}
    >
      <Flex
        justifyContent={'right'}
        w={'100%'}
        maxW={'auto'}
        textAlign={'right'}
      >
        <InstructionsTooltip
          defaultIsOpen={true}
          content={
            'You should receive an email with a code at the email address you signed up with.'
          }
          footer={
            'It can sometimes take a few minutes for the email to appear in your mailbox.'
          }
          align={'right'}
        />
      </Flex>
      <Text>Please enter the 6-digit number you received via email</Text>
      <Input
        w={'80%'}
        m={'10px'}
        bg={'rgba(255, 255, 255, 0.65)'}
        color={'#243D9D'}
        onChange={(e) => setOtp(Number(e.currentTarget.value))}
      />
      <Button
        onClick={async (e) => {
          e.preventDefault();
          if (otp.toString().length !== 6) {
            addNotification('Please enter a 6-digit number.');
          } else {
            await verifyGuardian({
              otp,
            })
              .unwrap()
              .then(() => {
                verifyEmail();
                // navigate('/account/plan');
                navigate('/account/choose-user');
              })
              .catch(() => {
                addNotification(
                  'Code incorrect. Please try again or contact support if the problem persists.',
                );
              });
          }
        }}
      >
        Verify
      </Button>
      <Text>
        Didn&apos;t get the email? Make sure to check your spam folder, or click
        here to receive it again.
      </Text>
      <Button
        onClick={() => {
          resendOtp();
        }}
      >
        Send email again
      </Button>
    </PaddedContainer>
  );
};

export default OtpInputPage;
