import { createAction } from '@reduxjs/toolkit';

import { getDatePlusSeconds } from 'utils/dateUtils';

export const refreshToken = createAction(
  'auth/refreshToken',
  function prepare(access: string, access_expiry: number) {
    const tokenExpiry = getDatePlusSeconds(access_expiry);

    return {
      payload: {
        token: access,
        tokenExpiry,
      },
    };
  },
);
