import { ThemeOverride } from '@chakra-ui/react';

type GlobalStyles = Pick<ThemeOverride, 'styles'>;

export default {
  styles: {
    global: {
      'h1, h2, h3, h4, h5, h6, p': {
        letterSpacing: '.5px',
      },
      body: {
        // maxH: '100vh',
        margin: 0,
        fontFamily:
          'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      code: {
        fontFamily:
          'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
      },
      p: {
        my: 3,
        fontSize: '1rem',
        lineHeight: '1.375rem',
      },
      a: {
        textDecoration: 'none',
      },
      img: {
        maxWidth: '100%',
        height: 'auto',
      },
      'ol, ul': {
        paddingLeft: '2rem',
      },
      td: {
        border: '1px solid #fff',
        padding: '1px 4px',
      },
      // 'h1, h2, h3, h4, h5, h6': {
      //   fontWeight: 600,
      //   letterSpacing: '0.5px',
      // },
      // h1: {
      //   marginBottom: ['0.5rem', '0.75rem', '1rem'],
      //   letterSpacing: '2.4px',
      // },
      // h3: {
      //   fontSize: ['lg', 'lg', 'xl'],
      //   marginBottom: '0.5rem',
      // },
      // p: {
      //   fontSize: ['md', 'md', 'lg'],
      //   marginBottom: '1rem',
      //   letterSpacing: '0.5px',
      // },
      /* Disable focus shadow on non-keyboard interactions */
      ':focus:not(:focus-visible):not([role="dialog"]):not([role="menu"])': {
        boxShadow: 'none !important',
      },
    },
  },
} as GlobalStyles;
