import React from 'react';
import { Box } from '@chakra-ui/react';
import { ErrorMessage } from 'formik';

interface Props {
  name: string;
}

const FormError: React.FC<Props> = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {(message) => (
        <Box
          w={'100%'}
          p={'.15rem'}
          mt={'-7px'}
          fontSize={'0.825rem'}
          bgColor={'#b00'}
          borderRadius={'0 0 5px 5px'}
          zIndex={1}
        >
          {message}
        </Box>
      )}
    </ErrorMessage>
  );
};

export default FormError;
