import React from 'react';
import { Outlet } from 'react-router';
import { Flex, Heading } from '@chakra-ui/react';

import PaddedContainer from 'components/containers/PaddedContainer';
import CloseButton from 'components/nav/CloseButton';

interface Props {
  height: string;
}

const ManageStudentsLayout: React.FC<Props> = ({ children, height }) => {
  return (
    <PaddedContainer
      text-align={'center'}
      padding={'30px 20px'}
      height={height}
    >
      <CloseButton />
      <Flex
        h={'100%'}
        flexDir={'column'}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Heading fontSize={'18px'} m={'0 0 50px'}>
          Manage Students
        </Heading>
        {children}
        <Outlet />
      </Flex>
    </PaddedContainer>
  );
};

export default ManageStudentsLayout;
