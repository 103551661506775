import { renderToString } from 'katex';
import renderMathInElement from 'katex/dist/contrib/auto-render';
import React from 'react';

// When render the maths for a single element, a reference to that element can be passed to the
// renderMathsSingle function.

// For a collection of elements, renderMaths can be used.

const options = {
  delimiters: [
    { left: '$$', right: '$$', display: true },
    { left: '$', right: '$', display: false },
    { left: '\\(', right: '\\)', display: false },
    { left: '\\[', right: '\\]', display: true },
  ],
  throwOnError: false,
};

export const renderMaths = <T extends HTMLElement>(
  eles: HTMLCollectionOf<T>,
) => {
  for (let i = 0; i < eles.length; i++) {
    renderMathInElement(eles[i], options);
  }
};

export const renderMathsSingle = <T extends HTMLElement>(
  ele: React.RefObject<T>,
) => {
  if (ele.current) {
    renderMathInElement(ele.current, options);
  }
};

export const replacePartInline = (match: string) => {
  const noMarkers = match.replace(/\$|\\\(|\\\)/gi, '');
  const maths = renderToString(noMarkers);
  return maths;
};

export const replacePartDisplay = (match: string) => {
  const noMarkers = match.replace(/\$\$|\\\[|\\\]/gi, '');
  const maths = renderToString(noMarkers, { displayMode: true });
  return maths;
};

export const createMathsString = (content: string) => {
  // const inlineMaths = content.replace(
  //   /(\$([^.]+)\$)|(\\\(([^.]+)\\\))/gi,
  //   replacePartInline,
  // );
  const displayMaths = content.replace(
    /(\$\$([^.]+)\$\$)|(\\\\\[([^.]+)\\\])|/gi,
    replacePartDisplay,
  );
  return displayMaths;
};
