import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Flex } from '@chakra-ui/react';
import { KatexOptions, render as katexRender } from 'katex';

import { useAppSelector } from 'hooks/useAppSelector';

import PaddedContainer from 'components/containers/PaddedContainer';
import QuizTitle from 'features/lessons/QuizTitle';
import AnswerInfo from 'features/lessons/results/AnswerInfo';
import SummaryBottomSection from 'features/lessons/results/SummaryBottomSection';

import 'katex/dist/katex.min.css';
import styles from 'features/lessons/results/QuizSummary.module.scss';
import {
  TransformedOption,
  TransformedQuestion,
  TransformedTheory,
} from 'features/questions/questionsSlice';
import { DefaultAnswer, FibAnswer, Result } from './resultsSlice';

const summaryInside = true;

const LessonPreviewQuizSummary: React.FC = () => {
  const results = useAppSelector((state) => state.results.answers);
  const { questions, answers } = useAppSelector((state) => state.questions);
  const { quizInProgress } = useAppSelector((state) => state.progress);
  const navigate = useNavigate();
  // const btnStyle = {
  //   width: ['100%', '80%', '60%', '40%'],
  //   height: '40px',
  //   borderRadius: '12px',
  //   backgroundColor: '#2871D1',
  //   lineHeight: '12px',
  // };
  const justQuestions = questions.filter(
    (question: TransformedTheory | TransformedQuestion) => {
      return Object.keys(question).includes('content');
    },
  ) as TransformedQuestion[];
  useEffect(() => {
    if (quizInProgress === 0) {
      navigate('/curriculum');
    }
    const mathElements = document.getElementsByClassName('math');
    const macros: KatexOptions['macros'] = [];
    for (let i = 0; i < mathElements.length; i++) {
      const texText = mathElements[i].firstChild;

      if (texText?.nodeValue && mathElements[i].tagName === 'SPAN') {
        katexRender(texText.nodeValue, mathElements[i] as HTMLElement, {
          displayMode: mathElements[i].classList.contains('display'),
          throwOnError: false,
          macros: macros,
          fleqn: false,
        });
      }
    }
  });
  const userAnswers = answers.question;

  const getAnswerInfo = (
    answer: FibAnswer | DefaultAnswer,
    userAnswer: string[],
  ) => {
    if ('result' in answer) {
      return {
        isCorrect: !answer.result.some((res: Result) => !res.isCorrect),
        answers: answer.result.map((res: Result) => res.user_response),
      };
    } else {
      return { answers: userAnswer, isCorrect: answer.isCorrect };
    }
  };

  return (
    <PaddedContainer padding={'28px'} textAlign={'left'} height={'75vh'}>
      <QuizTitle />
      <Text fontSize={'14px'} letterSpacing={'1.12px'} m={'10px 0 28px'}>
        Answers Review
      </Text>
      <Box h={'70%'}>
        <Flex
          flexDir={'column'}
          h={'350px'}
          pr={'30px'}
          className={styles.scroll}
        >
          {results.map((answer, index) => {
            const userAnswerIndexes = userAnswers.find(
              (ele: any) => ele.id === answer.question_id,
            );
            const question = justQuestions.find(
              (ele: TransformedQuestion) => ele.id === answer.question_id,
            ) as TransformedQuestion;
            const { content, options } = question as TransformedQuestion;
            const userAnswer: string[] = [];
            if (userAnswerIndexes !== undefined) {
              userAnswerIndexes.answered.forEach((element) => {
                if (options !== undefined) {
                  options.forEach((option: TransformedOption) => {
                    if (option.id === element) {
                      userAnswer.push(option.content);
                    }
                  });
                }
              });
            }

            // const isCorrect = question.questionType === 'FIB' ? answer.result.isCorrect : answer.isCorrect;
            // const question = questions.find(
            //   (ele: any) =>
            //     ele.id === answer.id && ele.questionType !== 'theory',
            // );

            const { isCorrect, answers } = getAnswerInfo(answer, userAnswer);

            return (
              <AnswerInfo
                key={index}
                question={content}
                userAnswers={answers}
                isCorrect={isCorrect}
                index={String(index + 1)}
              />
            );
          })}
          {summaryInside && (
            <Box>
              <SummaryBottomSection />
            </Box>
          )}
        </Flex>
        {!summaryInside && (
          <Box>
            <SummaryBottomSection />
          </Box>
        )}
      </Box>
    </PaddedContainer>
  );
};

export default LessonPreviewQuizSummary;
