import { isDesktop } from 'utils/customHooks/windowDimensionsUtil';
import { lowerCase } from 'utils/stringUtils';
import { useUrlParts } from './useUrlParts';

export const useBackground = () => {
  const urlParts = useUrlParts();
  if (urlParts[0] === 'profile' || urlParts.length === 0) return null;

  const path =
    urlParts.length === 1 || urlParts[0] === 'account'
      ? 'dashboard'
      : urlParts[1];
  const isOnDesktop = isDesktop();
  const background = isOnDesktop
    ? `${lowerCase(path)}-desktop`
    : `${lowerCase(path)}`;
  return `https://cdn.valearnis.com/backgrounds/${background}.png`;
};
