import { createSlice } from '@reduxjs/toolkit';
import { api } from 'app/services/api';

interface Answer {
  question_id: number;
}

export interface DefaultAnswer extends Answer {
  isCorrect: boolean;
}

export interface Result {
  user_response: string;
  isCorrect: boolean;
}

export interface FibAnswer extends Answer {
  result: Result[];
}

interface InitialValues {
  isLoading: boolean;
  answers: (DefaultAnswer | FibAnswer)[];
  totalQuestions: number;
  answeredCorrectly: number;
}

const initialState: InitialValues = {
  isLoading: true,
  answers: [],
  totalQuestions: 0,
  answeredCorrectly: 0,
};

const results = createSlice({
  name: 'results',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.getScore.matchFulfilled,
        (state, { payload }) => {
          state.answers = payload.question;
          state.totalQuestions = payload.total_questions;
          state.answeredCorrectly = payload.correct_questions;
          state.isLoading = false;
        },
      )
      .addMatcher(api.endpoints.getScore.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(
        api.endpoints.getPreviewLessonScore.matchFulfilled,
        (state, { payload }) => {
          state.answers = payload.question;
          state.totalQuestions = payload.total_questions;
          state.answeredCorrectly = payload.correct_questions;
          state.isLoading = false;
        },
      )
      .addMatcher(api.endpoints.getPreviewLessonScore.matchPending, (state) => {
        state.isLoading = true;
      });
  },
});

export default results.reducer;
