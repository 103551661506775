import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router';
import Header from 'components/nav/Header';
import FactionQuizPopup from 'features/houseFaction/FactionQuizPopup';
import HouseFactionInfo from 'features/houseFaction/HouseFactionInfo';
import HouseFactionInfoMobile from './HouseFactionInfoMobile';
import { useAppSelector } from 'hooks/useAppSelector';

const houses = new Set(['Heartleaf', 'Moonspark', 'Flamespire']);

const HouseFactionPage: React.FC = () => {
  const { house } = useAppSelector((state) => state.faction);
  // console.log(house);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      pos={'absolute'}
      w={'100vw'}
      minH={'100vh'}
      color={'white'}
      // bgGradient={house === '' ? 'linear(#00a896,#1565c0)' : undefined}
      bgImg={
        house === '' || house === null
          ? undefined
          : `linear-gradient(
            rgba(34, 34, 34, 0.35),
            rgba(34, 34, 34, 0.35)
          ), url('https://cdn.valearnis.com/house-factions/${house.toLowerCase()}/${house}%20Background.png')`
      }
      backgroundSize='cover'
      bgPos={'60% 70%'}
      bgSize={'cover'}
      bgRepeat={'no-repeat'}
      textAlign={'center'}
      display='flex'
      alignItems='center'
      justifyContent='center'
      zIndex={'-1'}
    >
      <Box pos='fixed' top={0} left={0} w='100%' zIndex={1}>
        <Header />
      </Box>

      {house !== null &&
        houses.has(house) &&
        (isMobile ? <HouseFactionInfoMobile /> : <HouseFactionInfo />)}
      {(house === null || !houses.has(house)) && <FactionQuizPopup />}
      <Outlet />
    </Box>
  );
};

export default HouseFactionPage;
