import React from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import ChooseFaction from './ChooseFaction';
import HouseFactionQuiz from './HouseFactionQuiz';
import StartQuiz from './StartQuiz';
const FactionQuizPopup: React.FC = () => {
  const { quizInProgress, recommendedFaction } = useAppSelector(
    (state) => state.faction,
  );

  return (
    <React.Fragment>
      <div
        style={{
          background: 'black',
          borderRadius: '10px',
          padding: '10px',
          margin: '10px',
        }}
      >
        {/* Not attempted quiz */}
        {!quizInProgress && !recommendedFaction && <StartQuiz />}

        {/* Started Quiz */}
        {quizInProgress && <HouseFactionQuiz />}

        {/* Finished quiz but not joined faction */}
        {!quizInProgress && recommendedFaction && <ChooseFaction />}
      </div>
    </React.Fragment>
  );
};

export default FactionQuizPopup;
