import { extendTheme } from '@chakra-ui/react';

import fonts from './foundations/fonts';
import colors from './foundations/colors';
import fontSizes from './foundations/fontSizes';

import styles from './styles';
import components from './components';
import breakpoints from './foundations/breakpoints';

/**
 * This file is generated for providing a custom theme to Chakra UI
 *
 * To learn more about custom themes
 * please visit https://chakra-ui.com/docs/getting-started#add-custom-theme-optional
 */

const overrides = {
  ...styles,
  ...components,
  breakpoints,
  fonts,
  colors,
  fontSizes,
};

const theme = extendTheme(overrides);

export default theme;
