import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'app/services/api';
import { lowerCase } from 'utils/stringUtils';

interface InitialState {
  currentMenu: string;
  menuItems: any;
  buttonData: any;
  background: string;
}

interface MenuSettings {
  name: string;
  isDesktop: boolean;
}

const initialState: InitialState = {
  currentMenu: 'Dashboard',
  menuItems: [],
  buttonData: [],
  background: '',
};
const menuSlice = createSlice({
  name: 'menu',
  initialState,

  reducers: {
    setCurrentMenu: (state, { payload }: PayloadAction<MenuSettings>) => {
      const imgUrl = payload.isDesktop
        ? `https://cdn.valearnis.com/backgrounds/${lowerCase(
            payload.name,
          )}-desktop.png`
        : `https://cdn.valearnis.com/backgrounds/${lowerCase(
            payload.name,
          )}.png`;
      state.background = imgUrl;

      if (payload.name === 'Dashboard') {
        state.buttonData = state.menuItems;
      } else {
        const subject = state.menuItems.find(
          (item: any) => item.name === payload.name,
        );
        if (subject !== undefined) {
          state.buttonData = subject.topics;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getMenuItems.matchFulfilled,
      (state, { payload }) => {
        state.menuItems = payload;
        if (state.currentMenu === 'Dashboard') {
          state.buttonData = payload;
        } else {
          state.buttonData = payload.find(
            (ele: any) => ele.name === state.currentMenu,
          ).topics;
        }
      },
    );
  },
});

export const menuActions = menuSlice.actions;
export const { setCurrentMenu } = menuSlice.actions;

export default menuSlice.reducer;
