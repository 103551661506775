import React from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';

import ButtonLink from 'components/buttons/ButtonLink';
// import { useAppSelector } from 'hooks/useAppSelector';

const btnStyle = {
  display: 'flex',
  flex: [null, null, '0 0 48%'],
  height: ['110px', '140px'],
  w: ['255px', '310px'],
  maxW: ['265px', '320px'],
  m: [4, 6, 8],
  alignItems: 'center',
};

const txtStyle = {
  fontSize: ['1rem', '1.3rem'],
  m: '20px',
};

function getRandomGreeting(): string {
  const greetings = [
    'How would you like to learn today?',
    'Welcome to Valearnis!',
    "Let's learn together!",
    'Hope you learn something new!',
    'What interests you today?',
  ];

  return greetings[Math.floor(Math.random() * greetings.length)];
}

const Dashboard: React.FC = () => {
  // const { userName } = useAppSelector((state) => state.user); // Using username for now, but will need to change to first name

  // const greetings = [
  //   `Hey ${userName}, how would you like to learn today?`,
  //   `Welcome to Valearnis, ${userName}!`,
  //   "Let's learn together!",
  //   `Hey ${userName}, hope you learn something new!`,
  //   `What interests you today ${userName}?`,
  // ];

  const randomGreeting = getRandomGreeting();

  return (
    <>
      <Heading as={'h1'} fontSize={'3rem'} mb={'5rem'} mt={'1rem'}>
        {randomGreeting}
      </Heading>
      <Box
        display={'flex'}
        flexFlow={'row wrap'}
        justifyContent={'center'}
        height={'50vh'}
        textAlign={'left'}
      >
        <ButtonLink to='/ai-teacher' {...btnStyle}>
          <Image
            src={'/assets/icons/VirtualTeacher.svg'}
            alt='Virtual Teacher'
            maxW={'50px'}
          />
          <Text {...txtStyle}>Virtual Teacher</Text>
        </ButtonLink>
        <ButtonLink to='/curriculum' {...btnStyle}>
          <Image
            src={'/assets/icons/Lessons.svg'}
            alt='Lessons'
            maxH={'60px'}
          />
          <Text {...txtStyle}>Lessons</Text>
        </ButtonLink>
      </Box>
    </>
  );
};

export default Dashboard;
