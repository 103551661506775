import { Box, Text } from '@chakra-ui/react';
import { ChevronDown } from 'components/icons';
import ChevronUp from 'components/icons/ChevronUp';
import { Topic } from 'features/lessons/progressSlice';
import React from 'react';
import { useState } from 'react';

const attributeStyles = {
  m: '2px 5px 2px 0',
  fontSize: '12px',
};
interface Props {
  topics: Topic[];
}

const MoreDetails: React.FC<Props> = ({ topics }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Box>
      {!isVisible ? (
        <Box onClick={() => setIsVisible(!isVisible)}>
          <ChevronDown display={'inline-block'} mr={'5px'} />
          <Text {...attributeStyles} display={'inline-block'}>
            More details
          </Text>
        </Box>
      ) : (
        <Box>
          <ChevronUp onClick={() => setIsVisible(!isVisible)} mb={'10px'} />
          {topics.map((topic) => {
            return (
              <Text {...attributeStyles} key={topic.id}>{`${topic.name}: ${
                typeof topic.average === 'string'
                  ? 'No data'
                  : topic.average + '%'
              }`}</Text>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default MoreDetails;
