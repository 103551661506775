import React, { useEffect } from 'react';
import { Box, Heading } from '@chakra-ui/layout';
import DOMPurify from 'dompurify';

import SingleChoiceOptions from './multiChoiceText/SingleChoiceOptions';
import SingleChoiceImageOptions from './multiChoiceText/SingleChoiceImageOptions';
import PictureChoiceOptions from './multiChoicePicture/PictureChoiceOptions';
import MultiAnswerOptions from './multiChoiceText/MultiAnswerOptions';
import DropDownQuestion from './dropDown/DropDownQuestion';
import { TransformedOption } from 'features/questions/questionsSlice';

import { renderMathsSingle } from 'utils/katexUtils';
import MultiAnswerImageOptions from './multiChoiceText/MultiAnswerImageOptions';

interface Props {
  questionType: string;
  question: string;
  options: TransformedOption[];
}

const MultiChoiceQuestion: React.FC<Props> = ({
  questionType,
  question,
  options,
}) => {
  const ele = React.createRef<HTMLHeadingElement>();

  useEffect(() => {
    renderMathsSingle(ele);
  }, [question]);

  const setChoices = () => {
    if (questionType === 'SA_MCQ' || questionType === 'TF') {
      return <SingleChoiceOptions options={options} />;
    } else if (questionType === 'SA_MCQI') {
      return <SingleChoiceImageOptions options={options} />;
    } else if (questionType === 'MCP') {
      return <PictureChoiceOptions images={options} />;
    } else if (questionType === 'MCQ') {
      return <MultiAnswerOptions options={options} />;
    } else if (questionType === 'MCQI') {
      return <MultiAnswerImageOptions options={options} />;
    } else if (questionType === 'DROP') {
      return <DropDownQuestion options={options} />;
    } else {
      throw new Error('Unkown questionType');
    }
  };

  return (
    <Box>
      <Heading
        ref={ele}
        as={'h1'}
        fontWeight={'400'}
        fontSize={'20px'}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}
      />
      <Box
        w={'100%'}
        m={'1.5rem 0'}
        borderTop={'1px solid'}
        borderRadius={'10px'}
        bgColor={'white'}
      ></Box>
      {setChoices()}
    </Box>
  );
};

export default MultiChoiceQuestion;
