import React, { useCallback, useEffect } from 'react';
import { Box, Flex, Text, Spacer } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import Arrow from './Arrow';
import ScrollBall from './ScrollBall';
import { useActions } from 'hooks/useActions';
import SubmitQuizButton from 'features/lessons/SubmitQuizButton';

const NavStepper: React.FC = () => {
  const { sliderPos } = useAppSelector((state) => state.nav);

  // const [touchStart, setTouchStart] = useState(0);

  const { questions, currentQuestion } = useAppSelector(
    (state) => state.questions,
  );

  const {
    setCurrentQuestionIdSpecific,
    setBallPos,
    setMaxWidth,
    setSliderPos,
  } = useActions();

  const sliderLength = useCallback(
    (node) => {
      if (node !== null) {
        setMaxWidth(node.scrollWidth - 195);
      }
    },
    [questions],
  );

  useEffect(() => {
    setSliderPos(-35);
    setBallPos(-10);
  }, [questions]);

  return (
    <Flex
      flexDir={'row'}
      justify={'center'}
      alignItems={'center'}
      align={'center'}
      mt={'auto'}
    >
      <Spacer />
      <Flex flexDir={'column'} justify={'center'} align={'center'} mt={'auto'}>
        <Flex
          justifyContent={'center'}
          align={'center'}
          my={'10px'}
          fontSize={'0.75rem'}
          h={'50px'}
        >
          <Arrow direction={'left'} navToQuestion={currentQuestion} />
          <Box
            overflow={'hidden'}
            position={'relative'}
            textAlign={'center'}
            w={'210px'}
          >
            <Flex
              ref={sliderLength}
              transform={`translateX(-${sliderPos}px)`}
              align={'center'}
            >
              {questions.map((question: any, index: number) => {
                return (
                  <Flex
                    key={`${question.id}-${index}`}
                    bg={index === currentQuestion ? 'white' : ''}
                    color={index === currentQuestion ? 'brand.600' : ''}
                    p={'0'}
                    align={'center'}
                    m={'0 20px'}
                    w={'32px'}
                    justify={'center'}
                    borderRadius={'5px'}
                  >
                    <Text
                      fontSize={'20px'}
                      w={'32px'}
                      m={'0'}
                      p={'5px 0'}
                      userSelect={'none'}
                      cursor={'pointer'}
                      onClick={() => setCurrentQuestionIdSpecific(index)}
                    >
                      {question.navbarNum}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </Box>
          <Arrow direction={'right'} navToQuestion={currentQuestion + 2} />
        </Flex>
        <ScrollBall />
      </Flex>
      <Spacer />
      <Flex>
        <SubmitQuizButton />
      </Flex>
    </Flex>
  );
};

export default NavStepper;
