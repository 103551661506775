export default {
  brand: {
    500: '#253E9E',
    600: '#243D9D',
    700: '#22B7A3',
    800: '#1B49B6',
    900: '#253D9D',
  },
  header: {
    100: '#20232A',
  },
  main: {
    100: '#282C34',
  },
  button: {
    100: '#2871D1',
    200: '#243D9D',
    300: '#0E288C',
    blue: '#0E5BC8',
    'dark-blue': '#243D9D',
    gold: '#FFD85C',
    mustard: '#F9BF13',
    green: '#008073',
    purple: '#5F30D9',
    red: '#B32C12',
    transparent: '',
  },
};
