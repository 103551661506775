import { Input } from '@chakra-ui/react';
import { FibAnswer } from 'app/services/api';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import React from 'react';

interface Props {
  optionOrder: number;
}

// interface CurrentAnswer {
//   fill_index: number;
//   response: string;
// }

const InputSection: React.FC<Props> = ({ optionOrder }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();

  const thisAnswer = userAnswer?.find((answer: any) => {
    return answer.fill_index === optionOrder;
  }) as FibAnswer;

  const handleChange = (event: any) => {
    event.preventDefault();
    const newEntry = event.target.value;
    const answers = userAnswer?.map((answer: any) => {
      if (answer.fill_index === optionOrder) {
        return { fill_index: answer.fill_index, response: newEntry };
      } else {
        return answer;
      }
    });
    selectAnswer(answers);
  };
  return (
    <Input
      value={(thisAnswer !== undefined && thisAnswer.response) || ''}
      role={'inputField'}
      w={'100px'}
      m={'0 8px'}
      p={'0'}
      onChange={handleChange}
      borderWidth={'0'}
      borderRadius={'0'}
      borderBottomWidth={'2px'}
      height={'18px'}
    />
  );
};

export default InputSection;
