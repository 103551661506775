import React, { useEffect } from 'react';
import { Radio, RadioGroup, Flex, Box } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import { useActions } from 'hooks/useActions';
import { useCurrentAnswer } from 'hooks/useCurrentAnswer';
import { renderMaths } from 'utils/katexUtils';

interface Props {
  options: any;
}

const SingleChoiceOptions: React.FC<Props> = ({ options }) => {
  const { selectAnswer } = useActions();
  const userAnswer = useCurrentAnswer();

  useEffect(() => {
    renderMaths(document.getElementsByTagName('div'));
  }, [options]);

  return (
    <RadioGroup value={userAnswer ? String(userAnswer[0]) : ''}>
      {options.map((option: any, index: number) => {
        const checked = userAnswer
          ? Number(userAnswer[0]) === option.id
            ? true
            : false
          : false;
        return (
          <Box
            w={'90%'}
            key={index}
            mr={[null, null, '50px']}
            display={[null, null, null, 'inline-block']}
          >
            <Flex alignItems={'center'}>
              <Radio
                role={'radio'}
                value={option.id}
                isChecked={checked}
                onChange={(event) => selectAnswer([event.currentTarget.value])}
                size={'sm'}
                m={'10px 20px 0 0'}
                paddingLeft={'3px'}
                background={'white'}
                cursor={'pointer'}
                _checked={{
                  background: '#FFDA24',
                }}
              >
                <Box
                  cursor={'pointer'}
                  // Set 0 margins on `p` elements to prevent overflow
                  sx={{ p: { margin: 0 } }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(option.content),
                  }}
                />
              </Radio>
            </Flex>
          </Box>
        );
      })}
    </RadioGroup>
  );
};

export default SingleChoiceOptions;
