import { Box } from '@chakra-ui/layout';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PinShowGroup from './PinShowGroup';
import ProfileForm from './ProfileForm';
import {
  IStudentProfile,
  ICreateStudentRequest,
  useCreateStudentMutation,
} from 'app/services/api';
import { useToast } from '@chakra-ui/react';
import ProfileFormButtons from './ProfileFormButtons';
import { resetStudentStates } from 'features/userProfile/userSlice';

export default function ProfileCreateForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pin, setPin] = useState(['', '', '', '']);
  const [profileName, setProfileName] = useState('');
  const [year, setYear] = useState(0);
  const toast = useToast();

  const [createStudent] = useCreateStudentMutation();

  const handleSubmit = async () => {
    // submit profile
    const student: IStudentProfile = {
      name: profileName,
      username: profileName,
      year: year,
      pin: pin.join(''),
    };

    const request: ICreateStudentRequest = {
      data: [student],
    };

    try {
      const createStudentResponse = await createStudent(request).unwrap();
      if (
        createStudentResponse.status &&
        createStudentResponse.status === 200
      ) {
        toast({
          title: 'Create Profile',
          description: "We've created the profile for you.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        dispatch(resetStudentStates());
        navigate('/account/choose-user', { replace: true });
      } else {
        toast({
          title: 'Create Profile Error',
          description: createStudentResponse.msg,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        title: 'Create Profile Error',
        description: error.data.msg,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      width='300px'
      margin='auto'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      style={{ gap: '2rem' }}
      marginTop='2rem'
    >
      <ProfileForm
        mode={'create'}
        name={profileName}
        setName={setProfileName}
        setYear={setYear}
      />
      <PinShowGroup pin={pin} setPin={setPin} />
      <ProfileFormButtons
        mode={'create'}
        isStudent={true}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
