// Custom Yup methods

import * as Yup from 'yup';
import _ from 'lodash';

/**
 * Merge the definitions
 */
declare module 'yup' {
  interface ArraySchema<T> {
    uniqueProperty(propertyPath: string, message?: string): ArraySchema<T>;
  }
}

/**
 * Yup array method to ensure a given property's value is unique
 */
Yup.addMethod(Yup.array, 'uniqueProperty', function (propertyPath, message) {
  return this.test('unique', '', function (list) {
    const errors: Yup.ValidationError[] = [];

    if (list) {
      list.forEach((item, index) => {
        const propertyValue = _.get(item, propertyPath);

        if (
          propertyValue &&
          _.filter(list, [propertyPath, propertyValue]).length > 1
        ) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message,
            }),
          );
        }
      });
    }

    if (!_.isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});
