import { add } from 'date-fns';

/**
 * Creates a new date string in ISO format, adding the
 * given duration in seconds to the current date
 *
 * @param {number} seconds seconds to add
 *
 * @returns {string} dateString
 */
export const getDatePlusSeconds = (seconds: number): string => {
  const dateString = add(new Date(), {
    seconds: seconds,
  }).toISOString();

  return dateString;
};
