import React from 'react';
import {
  Box,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import QuestionIcon from '@chakra-ui/icon';
interface Props {
  content: string;
  align: any;
  defaultIsOpen: boolean;
  footer?: string;
}
const InstructionsTooltip: React.FC<Props> = ({
  defaultIsOpen,
  content,
  align,
  footer,
}) => {
  return (
    <Popover defaultIsOpen={defaultIsOpen} placement={align} trigger='hover'>
      <PopoverTrigger>
        <QuestionIcon fontSize={'2xl'} />
      </PopoverTrigger>
      <PopoverContent bg='white' color='white'>
        <PopoverArrow bg='white' color='white' />
        <PopoverCloseButton bg='#1C50B4' />
        <PopoverBody bg='white' color='white'>
          <Box>
            <Text textAlign={'left'} m={['6', '1', '1', '6']} color='#1C50B4'>
              {content}
            </Text>
            <Text textAlign={'left'} m={['6', '1', '1', '6']} color='#1C50B4'>
              {footer}
            </Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default InstructionsTooltip;
