import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LargeImage {
  visible: boolean;
  imgPath: string;
}

const initialState: LargeImage = {
  visible: false,
  imgPath: '',
};

const largeImage = createSlice({
  name: 'largeImage',
  initialState,
  reducers: {
    toggleLargeImage: (state) => {
      state.visible = !state.visible;
    },
    setImage: (state, { payload }: PayloadAction<string>) => {
      state.imgPath = payload;
    },
  },
});

export const largeImageActions = largeImage.actions;

export const { toggleLargeImage, setImage } = largeImage.actions;

export default largeImage.reducer;
