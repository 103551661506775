import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import ProfileSelect from '../../components/auth/ProfileSelect';
import { useNavigate } from 'react-router-dom';

export default function EditProfiles() {
  const navigate = useNavigate();
  const redirectToChooseUser = () => {
    navigate('/account/choose-user/', { replace: true });
  };

  return (
    <>
      <Box
        id='dash'
        pos={'fixed'}
        w={'100vw'}
        minH={'100vh'}
        bgColor={'brand.800'}
        bgGradient={'linear(brand.700, brand.800)'}
        bgPos={'bottom'}
        bgSize={'cover'}
        bgRepeat={'no-repeat'}
        zIndex={'-1'}
      ></Box>
      <Button
        position='fixed'
        top='0'
        right='0'
        m='16px'
        onClick={() => redirectToChooseUser()}
      >
        CANCEL
      </Button>
      <ProfileSelect mode={'edit'} />
    </>
  );
}
