import React from 'react';
import { FormLabel as ChakraFormLabel } from '@chakra-ui/react';

interface Props {
  htmlFor: string;
}

const FormLabel: React.FC<Props> = ({ htmlFor, children, ...rest }) => {
  return (
    <ChakraFormLabel
      htmlFor={htmlFor}
      mb={'0.5rem'}
      fontSize={'0.75rem'}
      letterSpacing={'.5px'}
      textTransform={'uppercase'}
      {...rest}
    >
      {children}
    </ChakraFormLabel>
  );
};

export default FormLabel;
