import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api, ArtAsset, Character, Skin } from 'app/services/api';

interface Attribute {
  attribute: string;
  value: string;
}

interface Urls {
  characters: string;
  backgrounds: string;
  thumbnail: string;
}

export interface UserProfile {
  name: string;
  userName: string;
  avatar: string;
  house: string;
  background: string;
  selectingAvatar: boolean;
  level: number;
  userClass: string;
  expToLvl: number;
  totalLevelExp: number;
  attributes: Attribute[];
  email: string;
  schoolYear: string;
  recentExpGain: number;
  urls: Urls;
  characters: Character[];
  backgrounds: ArtAsset[];
  isSettingTheme: boolean;
  tempAvatar: string;
  tempBg: string;
  characterChoice: Character;
  tempCharacterChoice: Character;
  skinChoice: Skin;
  tempSkinChoice: Skin;
  studentId: string;
  studentName: string;
  profilePin: string;
  pinAuthed: boolean;
}

const defaultSkin = {
  character: '2eee3d07-6c9f-429c-8db4-33f5e5736dca',
  filename: 'female-adventurer-01.png',
  id: 'cc7382d3-05a3-4866-9300-093c3dd99656',
  is_unlocked: true,
  unlock_condition: [''],
  thumbnail: '',
};

const initialState: UserProfile = {
  name: '',
  userName: '',
  avatar: 'https://cdn.valearnis.com/avatars/female-adventurer-01.png',
  house: 'undefined',
  background:
    'https://cdn.valearnis.com/avatars/backgrounds/character-background-01.png',
  selectingAvatar: false,
  level: 4,
  userClass: 'Warrior',
  expToLvl: 0,
  totalLevelExp: 0,
  attributes: [
    { attribute: 'Topics Learned', value: '6' },
    { attribute: 'Highest Score', value: '76' },
    { attribute: 'Best Topic', value: 'Geometry' },
    { attribute: 'Most Studied', value: 'History' },
  ],
  email: 'spirit@bomb.org',
  schoolYear: '9001',
  recentExpGain: 0,
  urls: {
    characters: 'https://cdn.valearnis.com/avatars/',
    thumbnail: 'https://cdn.valearnis.com/avatars/thumbnails/',
    backgrounds: 'https://cdn.valearnis.com/avatars/backgrounds/',
  },
  characters: [],
  backgrounds: [],
  isSettingTheme: false,
  tempAvatar: '',
  tempBg: 'character-background-01.png',
  characterChoice: {
    description:
      'More interested in climbing trees, befriending wild animals, and practicing archery rather than studying, writing, or reading books, Serith is most at home in the forests of Beralas, in the service of the magical Dryads. An orphan, raised to protect nature, Serith has dedicated her life to looking after the environment, giving little time to studying and academia. However, the sudden occurrence of mysterious black spores all over her forest has caused alarm, leading to the death and decay of much of her beloved homeland. Having exhausted all of their options to combat the plague, the Dryads have sent Serith to Valearnis to find a cure, hoping to restore the forests to their former glory.',
    id: '2eee3d07-6c9f-429c-8db4-33f5e5736dca',
    is_unlocked: true,
    name: 'Serith',
    skins: [defaultSkin],
    unlock_condition: [''],
  },
  tempCharacterChoice: {
    description:
      'More interested in climbing trees, befriending wild animals, and practicing archery rather than studying, writing, or reading books, Serith is most at home in the forests of Beralas, in the service of the magical Dryads. An orphan, raised to protect nature, Serith has dedicated her life to looking after the environment, giving little time to studying and academia. However, the sudden occurrence of mysterious black spores all over her forest has caused alarm, leading to the death and decay of much of her beloved homeland. Having exhausted all of their options to combat the plague, the Dryads have sent Serith to Valearnis to find a cure, hoping to restore the forests to their former glory.',
    id: '2eee3d07-6c9f-429c-8db4-33f5e5736dca',
    is_unlocked: true,
    name: 'Serith',
    skins: [defaultSkin],
    unlock_condition: [''],
  },
  skinChoice: defaultSkin,
  tempSkinChoice: defaultSkin,
  studentId: '',
  studentName: '',
  profilePin: '',
  pinAuthed: false,
};

const userSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    toggleSelectingAvatar: (state) => {
      state.selectingAvatar = !state.selectingAvatar;
    },
    setAvatar: (state) => {
      state.characterChoice = state.tempCharacterChoice;
      state.skinChoice = state.tempSkinChoice;
      state.background = state.tempBg;
      state.isSettingTheme = false;
    },
    toggleSettingTheme: (state) => {
      state.isSettingTheme = !state.isSettingTheme;
    },
    setUserName: (state, { payload }: PayloadAction<string>) => {
      state.userName = payload;
    },
    setClass: (state, { payload }: PayloadAction<string>) => {
      state.userClass = payload;
    },
    setTempAvatar: (state, { payload }: PayloadAction<string>) => {
      state.tempAvatar = payload;
    },
    setTempSkin: (state, { payload }: PayloadAction<Skin>) => {
      state.tempSkinChoice = payload;
    },
    setTempCharacterChoice: (state, { payload }: PayloadAction<Character>) => {
      state.tempCharacterChoice = payload;
    },
    setTempBg: (state, { payload }: PayloadAction<string>) => {
      state.tempBg = payload;
    },
    cancelSelection: (state) => {
      state.tempAvatar = '';
      state.tempBg = '';
    },
    setProfilePinAuthed: (state, { payload }: PayloadAction<boolean>) => {
      state.pinAuthed = payload;
    },
    setStudentId: (state, { payload }: PayloadAction<string>) => {
      state.studentId = payload;
    },
    setStudentName: (state, { payload }: PayloadAction<string>) => {
      state.studentName = payload;
    },
    setStudentYear: (state, { payload }: PayloadAction<string>) => {
      state.schoolYear = payload;
    },
    setProfilePin: (state, { payload }: PayloadAction<string>) => {
      state.profilePin = payload;
    },
    resetStudentStates: (state) => {
      state.studentId = '';
      state.studentName = '';
      state.profilePin = '';
      state.schoolYear = '';
      state.pinAuthed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        if (payload.status === 200 && payload.access_expiry) {
          state.userName = payload.username;
        }
      },
    ),
      builder.addMatcher(
        api.endpoints.getUserProfile.matchFulfilled,
        (state, { payload }) => {
          if (payload.status === 200) {
            state.house = payload.house;
          }
        },
      ),
      builder.addMatcher(
        api.endpoints.getUserProfile.matchFulfilled,
        (state, { payload }) => {
          const { level, character, background } = payload.payload;
          const expToLvl = payload.payload.exp_to_level;
          const totalLevelExp = 500 + 250 * (level - 1);
          let gainedExp = 0;
          if (state.level < level) {
            gainedExp += state.expToLvl + totalLevelExp - expToLvl;
          } else {
            gainedExp += state.expToLvl - expToLvl;
          }
          state.recentExpGain = gainedExp;
          state.level = level;
          state.totalLevelExp = totalLevelExp;
          state.expToLvl = expToLvl;
          if (character) {
            state.avatar = state.urls.characters + character;
          }
          if (background) {
            state.background = state.urls.backgrounds + background;
          }
        },
      ),
      builder.addMatcher(
        api.endpoints.getCharacters.matchFulfilled,
        (state, { payload }) => {
          state.characters = payload.characters;
          state.backgrounds = payload.backgrounds;
        },
      );
  },
});

export const userActions = userSlice.actions;
export const {
  toggleSelectingAvatar,
  setAvatar,
  setClass,
  setTempAvatar,
  setTempBg,
  setStudentId,
  setStudentName,
  setStudentYear,
  setProfilePin,
  resetStudentStates,
} = userSlice.actions;
export default userSlice.reducer;
