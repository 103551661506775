import { Flex, Text, Image } from '@chakra-ui/react';
import { useActions } from 'hooks/useActions';
import React, { useEffect } from 'react';

interface Props {
  message: string;
}

const Notification: React.FC<Props> = ({ message }) => {
  const { removeNotification } = useActions();
  useEffect(() => {
    setTimeout(() => {
      removeNotification();
    }, 5000);
  }, []);

  const closeNotification = () => {
    removeNotification();
  };

  return (
    <Flex
      zIndex={2}
      position={'absolute'}
      bottom={['3px', null, '10px']}
      w={['98%', null, '20%']}
      left={['0', null, 'auto']}
      right={['0', null, '10px']}
      m={['0 auto', null, '0']}
      color={'button.100'}
      bg={'white'}
      borderRadius={'5px'}
    >
      <Flex
        p={'10px 20px'}
        position={'relative'}
        w={'100%'}
        textAlign={'center'}
        justifyContent={'center'}
      >
        <Text>{message}</Text>
        <Image
          w={'10px'}
          h={'10px'}
          position={'absolute'}
          right={'5px'}
          top={'5px'}
          _hover={{ cursor: 'pointer' }}
          src='https://cdn.valearnis.com/icons/black-cross.svg'
          onClick={closeNotification}
        />
      </Flex>
    </Flex>
  );
};

export default Notification;
