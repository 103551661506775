import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ChevronDown = createIcon({
  displayName: 'ChevronDown',
  viewBox: '0 0 12 8',
  path: (
    <path
      fill='none'
      stroke='#fff'
      strokeWidth='1.5'
      strokeLinecap='round'
      d='M1.06 1.06l4.9 5.6 4.85-5.6'
    />
  ),
});

export default ChevronDown;
