import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useAppSelector } from 'hooks/useAppSelector';
import ZoomButton from 'features/lessons/templates/zoomedImage/ZoomButton';
import { useActions } from 'hooks/useActions';
import { PortraitWithBg } from './PortraitWithBg';

const ProfilePicture: React.FC = () => {
  const { avatar } = useAppSelector((state) => state.user);
  const { toggleSelectingAvatar } = useActions();
  return (
    <Box position={'relative'} w={'100%'}>
      <ZoomButton imgPath={avatar} />
      <PortraitWithBg w={'100%'} />
      <Button
        onClick={() => toggleSelectingAvatar()}
        bgColor={'brand.600'}
        width={'100%'}
        position={'absolute'}
        m={'0'}
        bottom={'0'}
        left={'0'}
        borderRadius={'0 0 10px 10px'}
      >
        Customise
      </Button>
    </Box>
  );
};

export default ProfilePicture;
