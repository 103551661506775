import React from 'react';
import { Flex } from '@chakra-ui/react';
import PortraitSide from './PortraitSide';
import SelectionSide from './SelectionSide';

import { useAppSelector } from 'hooks/useAppSelector';
import ThemeMenu from './ThemeMenu';

interface Props {
  isLoading: boolean;
}

const AvatarMenu: React.FC<Props> = ({ isLoading }) => {
  const { isSettingTheme } = useAppSelector((state) => state.user);

  return (
    <>
      {!isSettingTheme ? (
        <Flex
          w={'100%'}
          alignItems={'center'}
          h={['100%', '82%']}
          mt={['2.8rem', null, '0']}
          px={['2rem', null, '4rem', '6rem', '8rem']}
        >
          <Flex
            w={'100%'}
            flexDir={['column', null, 'row']}
            h={'100%'}
            alignItems={['center', null, 'initial']}
          >
            <SelectionSide isLoading={isLoading} />
            <PortraitSide isLoading={isLoading} />
          </Flex>
        </Flex>
      ) : (
        <ThemeMenu />
      )}
    </>
  );
};

export default AvatarMenu;
