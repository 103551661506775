import React from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Image,
  Stack,
} from '@chakra-ui/react';
import { setQuizProgress } from './FactionQuizSlice';
import { useDispatch } from 'react-redux';
import HouseFactions from './HouseFactions';

const StartQuiz: React.FC = () => {
  const dispatch = useDispatch();
  const commenceQuizHandler = () => {
    dispatch(setQuizProgress(true));
  };
  return (
    <Box
      position={'relative'}
      maxW={['100%', '80%', '80%', '80%']}
      minH={['60vh', null, '50vh']}
      display={'flex'}
      flexDir='column'
      alignItems={'center'}
      justifyContent={'center'}
      text-align={'center'}
      padding={'40px 10px'}
    >
      <Heading fontSize={['1.3rem', '1.6rem', '1.8rem', '2.2rem']} m='0.5rem'>
        Welcome to Valearnis House Factions!
      </Heading>
      <Box w='80%'>
        <Text fontSize={['0.9rem', '1rem', '1.2rem']} mb={'1rem'}>
          To get sorted in to your house faction you must complete the
          personality quiz!
        </Text>
      </Box>
      <Button onClick={commenceQuizHandler} mb={'2rem'}>
        Start
      </Button>
      <Stack direction='row' spacing={[2, 4, 7, 10]}>
        {HouseFactions.map(({ name, emblemImg }) => (
          <Flex key={name}>
            <Image
              display={['none', 'block', 'block']}
              width={['100px', '100px', '150px', '150px']}
              height={['100px', '100px', '150px', '150px']}
              loading={'lazy'}
              src={emblemImg}
            />
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default StartQuiz;
