import React from 'react';
import { Box } from '@chakra-ui/react';
import { css } from '@emotion/react';

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  marginTop: '180px',
});

const contentStyle = css({
  marginTop: '-350px',
  marginLeft: '10px',
});

interface Props {
  icon: string;
}

const Icon: React.FC<Props> = ({ icon }) => {
  return (
    <Box css={containerStyle}>
      <title id='title'>Icon</title>
      <Box css={contentStyle}>
        <img src={icon} width='42px' height='42px' />
      </Box>
    </Box>
  );
};

export default Icon;
