export default {
  baseStyle: {
    display: 'block',
    m: '1rem auto',
    p: '0.8125rem',
    fontWeight: '700',
    letterSpacing: '.5px',
    borderRadius: '10px',
  },
  sizes: {
    md: {
      minW: '6rem',
      p: 3,
      fontSize: '1rem',
    },
    lg: {
      minW: '10rem',
      fontSize: '1rem',
    },
  },
  variants: {
    link: {
      display: 'inline-block',
      verticalAlign: 'baseline',
      height: 'auto',
      minW: 'none',
      m: 0,
      px: 0,
      py: 0,
      color: '#fff',
      lineHeight: 'normal',
      _hover: {
        textDecoration: 'underline',
        _disabled: {
          textDecoration: 'none',
        },
      },
    },
    solid: {
      color: '#fff',
      bg: 'button.200',
      _hover: { bg: 'button.300' },
      _active: { bg: 'button.300' },
    },
    transparent: {
      border: '1px solid #fff',
      bg: 'transparent',
      _hover: { color: 'brand.800', bg: '#fff' },
      _active: { color: 'brand.800', bg: '#fff' },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'solid',
  },
};
