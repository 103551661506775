import { Box, Heading, Text } from '@chakra-ui/layout';
import DOMPurify from 'dompurify';
import React from 'react';
import SelectableWord from './SelectableWord';
import { Option } from 'app/services/responseTransform';

interface Props {
  instruction: string;
  answers: any;
  question: string;
}

const SelectWordQuestion: React.FC<Props> = ({ instruction, answers }) => {
  return (
    <Box>
      <Heading>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(instruction),
          }}
        />
      </Heading>

      <Box
        w={'100%'}
        m={'1.5rem 0'}
        borderTop={'1px solid'}
        borderRadius={'10px'}
        bgColor={'white'}
      ></Box>
      <Text>
        {answers.map((option: Option, index: number) => {
          return (
            <SelectableWord
              key={`sw-q${option.id}`}
              word={option.content}
              lastWord={index === answers.length}
              position={option.id}
            />
          );
        })}
      </Text>
    </Box>
  );
};

export default SelectWordQuestion;
