import { Flex, Box } from '@chakra-ui/layout';
import { useActions } from 'hooks/useActions';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import Draggable from 'react-draggable';

const ScrollBall: React.FC = () => {
  const { slideBallPos } = useAppSelector((state) => state.nav);
  const { setBallPos, setIsMoving, setSliderPos } = useActions();

  return (
    <Flex
      justify={'center'}
      right={'0'}
      left={'0'}
      bottom={'30px'}
      m={'10px 0'}
    >
      <Box
        position={'relative'}
        width={'230px'}
        height={'7px'}
        bg={'gray.100'}
        borderRadius={'10px'}
      >
        <Draggable
          axis={'x'}
          defaultPosition={{ x: -10, y: 0 }}
          bounds={{
            left: -10,
            right: 210,
          }}
          position={{ x: slideBallPos, y: 0 }}
          onDrag={(_, data) => {
            setSliderPos(data.x);
            setIsMoving(true);
            setBallPos(data.x);
          }}
          onStop={() => {
            setIsMoving(false);
          }}
        >
          <Flex
            justify={'center'}
            align={'center'}
            position={'absolute'}
            width={'30px'}
            height={'30px'}
            bottom={'-12px'}
            left={'0'}
            cursor={'pointer'}
          >
            <Box
              bg={'white'}
              w={'15px'}
              h={'15px'}
              borderRadius={'100px'}
            ></Box>
          </Flex>
        </Draggable>
      </Box>
    </Flex>
  );
};

export default ScrollBall;
