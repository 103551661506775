import React from 'react';
import { Flex } from '@chakra-ui/react';
import ButtonCurriculum from 'features/curriculum/ButtonCurriculum';
import { useEffect } from 'react';
import { useUrlParts } from 'hooks/useUrlParts';
import { useButtonData } from 'hooks/useButtonData';
import { useActions } from 'hooks/useActions';
// import { Outlet } from 'react-router-dom';
// import { useLazyGetSubjectQuery, useLazyGetTopicQuery } from 'app/services/api';

const Curriculum: React.FC = () => {
  const { resetSelections } = useActions();
  const buttonData = useButtonData();
  const urlLength = useUrlParts().length;

  useEffect(() => {
    if (urlLength === 1) {
      resetSelections();
    }
  }, []);

  return (
    <Flex
      flexFlow={'row wrap'}
      maxW={['15.375rem', null, '720px']}
      w={[null, null, '70vw']}
      m={'0 auto'}
      pt={['5vh', null, '10vh']}
    >
      {buttonData &&
        buttonData.map((item: any) => {
          const name = item.name.replace(/&amp;/i, '&');
          return (
            <ButtonCurriculum
              key={item.id}
              to={name}
              icon={name}
              label={name}
            />
          );
        })}
    </Flex>
  );
};

export default Curriculum;
