import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import LogRocket from 'logrocket';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Center, ChakraProvider, CircularProgress } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from 'app/store';
import AppRouter from 'app/AppRouter';
import theme from 'definitions/chakra/theme';

import './index.css';
// import VideoList from '/api/videos/';

// ReactDOM.render(<VideoList />, document.getElementById('root'));

const render = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://6b5bde52cd9e477fac3611cccbae6454@o1399136.ingest.sentry.io/6726326',
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });

    LogRocket.init('ushvkn/valearnis-app-prod');
  }

  ReactDOM.render(
    <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate
          loading={
            <Center>
              <CircularProgress isIndeterminate />
            </Center>
          }
          persistor={persistor}
        >
          <ChakraProvider theme={theme} resetCSS>
            <HelmetProvider>
              {process &&
                process.env.NODE_ENV === 'production' &&
                process.env.REACT_APP_GA_TRACKING_ID && (
                  <Helmet>
                    {/* Google tag (gtag.js) */}
                    <script
                      async
                      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`}
                    ></script>
                    <script>
                      {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', '${process.env.REACT_APP_GA_TRACKING_ID}');
                      `}
                    </script>
                  </Helmet>
                )}
              <AppRouter />
            </HelmetProvider>
          </ChakraProvider>
        </PersistGate>
      </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
