import React from 'react';
import { createIcon } from '@chakra-ui/react';

export const ChevronRight = createIcon({
  displayName: 'ChevronRight',
  viewBox: '0 0 7.51 11.26',
  path: (
    <path
      data-name='Next'
      fill='none'
      stroke='#fff'
      strokeWidth='1.5'
      strokeLinecap='round'
      d='M.76 10.5l5.6-4.9L.77.77'
    />
  ),
});

export default ChevronRight;
