import { Box } from '@chakra-ui/react';
import React from 'react';
interface Props {
  expToLvl: number;
  totalLevelExp: number;
}

const BigExperienceBar: React.FC<Props> = ({ expToLvl, totalLevelExp }) => {
  return (
    <Box
      m={'20px auto'}
      borderRadius={'15px'}
      borderColor={'#E58734'}
      borderWidth={'5px'}
      height={'23px'}
      bgColor={'#7c8bc4'}
      textAlign={'left'}
    >
      <Box
        role={'innerBar'}
        bgColor={'#F4BD4E'}
        width={`${((totalLevelExp - expToLvl) / totalLevelExp) * 100}%`}
        height={'101%'}
        borderRadius={'15px'}
      >
        <Box
          bgColor={'#fff'}
          width={'90%'}
          height={'5px'}
          borderRadius={'10px'}
          margin={'auto'}
        ></Box>
      </Box>
    </Box>
  );
};

export default BigExperienceBar;
